import React from 'react';
import { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import { InfoBox } from "../cms_core";
import { API_BASE_URL } from "../store/url/ProgressUrl";
import { BiSort } from "react-icons/bi";
import { CircularProgressbar } from 'react-circular-progressbar';
import { AiOutlineFileText } from "react-icons/ai";
import 'react-circular-progressbar/dist/styles.css';
import { useAppSelector } from '../store/hooks/redux';
import { useAuth } from "../hooks/useAuth";
import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
} from "chart.js";
import Sort from '../store/sorting/sortСlass';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
)

export default function ProgressPage() {
    const isAuth = !!localStorage.user;

    const { user_id, role } = useAuth();
    const { currentTest, currentProgram, selectedCity, selectedRegion, selectedFormat } = useAppSelector(state => state.mainHeaderSlice);

    const [loading, setLoading] = useState(true);
    const [participatingStores, setParticipatingStores] = useState([]);
    const [performingStores, setPerformingStores] = useState(0);
    const [completedStores, setCompletedStores] = useState(0);
    const [chartCompletedStores, setChartCompletedStores] = useState([0]);
    const [chartFailedStores, setChartFailedStores] = useState([0]);
    const [topics, setTopics] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc");
    const [notDoneCount, setNotDone] = useState(0);
    const [involvement, setInvolvement] = useState("0%");
    const [totalCount, setTotal] = useState(0);
    const rgbColor = 'rgb(13, 110, 253)';
    const [activeSortColumn, setActiveSortColumn] = useState('');
    const [infoByCity, setInfoByCity] = useState([]);
    const [ascendingOrder, setAscendingOrder] = useState(false);
    const [selectInfoByCity, setSelectInfoByCity] = useState(0);
    const [collapsed, setCollapsed] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleSort = new Sort()

    useEffect(() => {
            (async function () {
                await axios.post(`/index.php`, {
                    module: "progress",
                    action: "getProgressStatistic",
                    programId: currentProgram.id,
                    testId: currentTest.id,
                    userId: user_id,
                    selectedCity: selectedCity,
                    selectedRegion: selectedRegion.id,
                    selectedFormat: selectedFormat
                })
                    .then(function (response) {
                        if (response.data.length !== 0) {
                            setNotDone(response.data.notDone);
                            setInvolvement(response.data.involvement);
                            setTotal(response.data.total);
                            sortTable("code", response.data.spreadSheet, "asc");
                            setPerformingStores(response.data.inProcess);
                            setCompletedStores(response.data.completed);
                            setChartCompletedStores([response.data.completed]);
                            setChartFailedStores([response.data.notDone]);
                            const sortedInfoByCity = response.data.infoByCity.sort((a, b) => b.involvement - a.involvement);
                            setInfoByCity(sortedInfoByCity)
                            let topicsData = [];
                            if (response.data.totalForTopic !== 0) {
                                topicsData.push({
                                    topic: 'Знание программы',
                                    rightAnswers: response.data.rightAnswers,
                                    falseAnswers: response.data.totalForTopic - response.data.rightAnswers,
                                });
                            }
                            if (selectedCity || selectedRegion.name || selectedFormat) {
                                const selectedIndex = infoByCity.findIndex(info => {
                                    if (selectedCity && info.city === selectedCity) return true;
                                    if (selectedRegion.name && info.city === selectedRegion.name) return true;
                                    if (selectedFormat && info.city === selectedFormat) return true;
                                    return false;
                                });

                                if (selectedIndex !== -1) {
                                    setSelectInfoByCity(selectedIndex);
                                } else {
                                    setSelectInfoByCity(0);
                                }
                            } else {
                                setSelectInfoByCity(0);
                            }
                            setTopics(topicsData);
                            setLoading(false);
                        }
                    })
                    .catch(function (error) {
                        InfoBox("Error: " + error);
                        console.error(error);
                    });
            })();
    }, [currentTest, currentProgram, selectedCity, selectedRegion, selectedFormat]);
    useEffect(() => {
        (async function () {
            let programsData;
            await axios.post(`/index.php`, {
                module: "loyaltyprogram",
                action: "getAll"
            })
                .then(function (response) {

                    programsData = response.data.loyaltyPrograms
                })
                .catch(function (error) {
                    InfoBox("Error in getAllPrograms: " + error);
                    console.error(error);
                });
        })();
    }, [user_id]);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {
        return (
            <><svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg></>
        )
    }

    function sortTable(column, table = participatingStores, sortDir = sortDirection) {
        setParticipatingStores(handleSort.sortProgressPage(column, table, sortDir));
        setActiveSortColumn(column);
        setSortDirection(sortDir === "asc" ? "desc" : "asc");
    }

    function parseDate(dateString) {
        if (!dateString) return null; 
        const parts = dateString.split('.');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }


    const downloadTableCSV = () => {
        axios.post(`/index.php`, {
            module: "progress",
            action: "downloadProgressTable",
            testId: currentTest.id,
            userId: user_id
        })
            .then(function (response) {
                let file_name = response.data.path.split('/')
                const link = document.createElement('a')
                // link.setAttribute('href', 'https://storus.store:' + API_BASE_URL + response.data.path)
                link.setAttribute('href', `${API_BASE_URL.protocol}${API_BASE_URL.domain}:${API_BASE_URL.port}${response.data.path}`);
                link.setAttribute('download', file_name[file_name.length - 1])
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(function (error) {
                InfoBox("Error: " + error);
                console.error(error);
            });
    }
    const sortedInfoRegions = () => {
        const prevCity = infoByCity[selectInfoByCity].city
        const sortedInfo = infoByCity.sort((a, b) => {
            if (ascendingOrder) {
                return b.involvement - a.involvement;
            } else {
                return a.involvement - b.involvement;
            }
        });
        const newIndex = sortedInfo.findIndex(city => city.city === prevCity);
        setSelectInfoByCity(newIndex)
        setInfoByCity(sortedInfo);
        setAscendingOrder(!ascendingOrder);
    };

    const options = {
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {

                grid: {
                    display: false,
                    borderWidth: 0,
                },
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                clamp: false,
                font: {
                    size: 0
                },
                formatter: (value, context) => {
                    return value.toLocaleString();
                }
            },
            tooltip: {
                enabled: true
            }
        }
    };

    const data = {
        labels: [completedStores, notDoneCount, performingStores],
        datasets: [
            {
                label: [],
                backgroundColor: ['#1a73e8', 'rgba(255, 0, 0)', '#808080'],
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 0,
                showLine: false,
                data: [completedStores, notDoneCount, performingStores],
                borderRadius: 5,
            },
        ],
    };
    const toggleCollapse = async () => {
        await setCollapsed(!collapsed)
        if (!collapsed) {
            const container = document.getElementById('containerScroll');
            const currentScrollY = window.scrollY;
            const newScrollY = container.getBoundingClientRect().top + currentScrollY;

            window.scrollTo({
                top: newScrollY - 250,
                behavior: 'smooth'
            });
        } else {

            const container = document.getElementById('elemCollapse_' + selectInfoByCity);
            const currentScrollY = window.scrollY;
            const newScrollY = container.getBoundingClientRect().top + currentScrollY;
            window.scrollTo({
                top: newScrollY - 250,
                behavior: 'smooth'
            });
        }
    };

    // const MaxLine = ({ height }) => (
    //     <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height, border: '1px solid black' }} />
    // );

    return (
        <div className="container mx-auto h-full flex flex-col gap-2">
            <div className="flex justify-between flex-wrap items-center pt-3 pb-2 mb-3 mx-3 ">
                <h2 className='text-[32px] font-bold'>Прогресс</h2>
            </div>
            <div className="flex flex-col gap-1 w-full h-[30%]">
                <div className="flex flex-row w-full h-full text-[24px] text-[#1a73e8] pt-3 pb-2 mb-3 mx-3">
                    {/* <span className="w-full text-center lg:w-1/2 lg:text-start">Обзор</span> */}
                    <span style={{ width: windowWidth < 1024 ? "100%" : "50%", textAlign: windowWidth < 1024 ? "center" : "start" }}>Обзор</span>
                    {windowWidth < 1024 ? '' :
                        <span className="w-1/2">В виде графика</span>}

                </div>
                <div className="flex flex-col lg:flex-row w-full  gap-6 self-stretch">
                    <div className="flex flex-col w-full lg:w-1/4 rounded-xl bg-[#f8f8f8] ">
                        <div className="flex flex-row justify-between items-center text-center bg-[#e0edff] p-[15px] basis-1/5 rounded-xl font-bold">
                            <span className="w-[70%] text-[18px]">Участвующие магазины </span>
                            <span className="w-1/4 text-[26px] text-[#1a73e8] "> {totalCount}</span>
                        </div>
                        <div className="flex flex-col items-center text-center gap-1 grow p-3 justify-center">
                            <span className='w-1/2 flex-wrap text-center text-[18px] font-bold'>Уровень вовлеченности</span>

                            <div className='w-[162px] h-[162px] text-[25px] m-3'>
                                <CircularProgressbar
                                    // className='rotate-90'
                                    strokeWidth={12}
                                    styles={{
                                        textSize: '25px',
                                        text: { fill: 'black', fontWeight: 'bold' },
                                        path: { stroke: '#1a73e8' },
                                    }}
                                    value={involvement}
                                    text={`${involvement}%`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full lg:w-1/4 gap-2 font-bold">
                        <div className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] text-center rounded-xl">
                            <span className="w-[60%] flex-wrap  text-[18px] px-2">Выполнившие магазины </span>
                            <span className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{completedStores}</span>
                        </div>
                        <div className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] text-center rounded-xl">
                            <span className="w-[60%] flex-wrap  text-[18px] px-2">В процессе выполнения</span>
                            <span className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{performingStores}</span>
                        </div>
                        <div className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] text-center rounded-xl">
                            <span className="w-[60%] flex-wrap text-[18px] px-2">Не выполнившие магазины</span>
                            <span className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{notDoneCount}</span>
                        </div>
                    </div>
                    {windowWidth < 1024 ? <div className="w-full text-[24px] flex justify-center text-[#1a73e8] mb-3">В виде графика</div> :
                        ''}
                    <div className="flex w-full lg:w-1/2 bg-[#f8f8f8] flex-col items-center justify-center gap-2 md:gap-4 rounded-xl">
                        <div className='flex flex-row gap-3 lg:gap-1 xl:gap-4 mt-2 text-[12px] md:text-[14px] items-center'>
                            <div className='flex h-[20px] w-[20px] md:h-[30px] md:w-[30px] bg-[#1a73e8] rounded-xl'></div>
                            <div className='flex'>{windowWidth < 1024 ? 'Вып' : 'Выполнившие'}</div>
                            <div className='flex h-[20px] w-[20px] md:h-[30px] md:w-[30px] bg-[red] rounded-xl'></div>
                            <div className='flex'>{windowWidth < 1024 ? 'Н/вып' : 'Не выполнившие'}</div>
                            <div className='flex h-[20px] w-[20px] md:h-[30px] md:w-[30px] bg-[gray] rounded-xl'></div>
                            <div className='flex'>{windowWidth < 1024 ? 'В/проц' : 'В процессе выполнения'}</div>
                        </div>
                        {/*  <div className='flex flex-row w-[85%] items-center '>
                            <span className='flex text-[12px] font-bold'>{totalCount}</span>
                            <span className='flex border-b-[2px] border-dashed border-[#1a73e8] w-full'></span>
                        </div>*/}
                        <div className='flex w-[95%]  h-[75%] items-center justify-center'>
                            {/* <MaxLine height="75%" /> */}
                            <Bar data={data} options={options} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-1 w-full'>
                <div className='flex w-full h-full text-[24px] text-[#1a73e8] pt-2 pb-1 mx-3 justify-center lg:justify-start items-center cursor-pointer' onClick={() => sortedInfoRegions()}>Прогресс по регионам <BiSort color={ascendingOrder ?? rgbColor} /></div>
                <div className={`flex ${windowWidth > 1024 ? 'flex-row' : 'flex-col'} w-full gap-6`}>
                    <div id='containerScroll' className='flex flex-col gap-2 w-full lg:w-[70%] h-full self-stretch bg-[#f8f8f8] rounded-xl cursor-pointer'>
                        <button id='toggleButton' className='sticky top-5 bg-[#1a73e8] text-[white] hover:bg-blue-800 hover:font-bold overflow-y-auto rounded-xl' onClick={toggleCollapse}>
                            {!collapsed ? <span className='flex justify-center items-center gap-2 p-2'> <BsArrowsCollapse /> Свернуть</span> : <span className='flex justify-center items-center gap-2 p-2'><BsArrowsExpand /> Развернуть</span>}</button>
                        {/* {infoByCity.map((cites, index) => (
                            <div id={"elemCollapse_" + index} key={index} className={`flex-grow w-full flex flex-row px-4 py-2 rounded-xl ${!(!collapsed || selectInfoByCity === index) && 'hidden'} ${selectInfoByCity === index && 'bg-[#e0edff]'}`} onClick={() => setSelectInfoByCity(index)}>
                                <div className='text-[16px] font-bold w-[5%]'>{cites.city}</div>
                                <div className='flex flex-row gap-1 items-center w-[95%]'>
                                    <div style={{ width: `${cites.involvement == 0 ? 1 : cites.involvement}%` }} className={`h-2 bg-blue-500 rounded-full `}></div>
                                    <div className='text-[24px] font-bold'>{cites.involvement}%</div>
                                </div>
                            </div>
                        ))} */}
                        {infoByCity.map((cites, index) => (
                            <div
                                id={"elemCollapse_" + index}
                                key={index}
                                className={`flex flex-col lg:flex-row w-full px-4 py-2 rounded-xl ${!(!collapsed || selectInfoByCity === index) && 'hidden'} ${selectInfoByCity === index && 'bg-[#e0edff]'}`}
                                onClick={() => setSelectInfoByCity(index)}
                            >
                                <div className='text-[16px] font-bold lg:w-1/6'>{cites.city}</div>
                                <div className='flex items-center w-full lg:w-5/6'>
                                    <div className="w-full h-2 bg-gray-300 rounded-full overflow-hidden">
                                        <div style={{ width: `${cites.involvement}%` }} className={`h-full bg-blue-500`}></div>
                                    </div>
                                    <div className='text-[24px] font-bold ml-2'>{cites.involvement}%</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* {windowWidth < 1024 ? '' : */}
                    <div className={`sticky top-5 overflow-y-auto flex flex-col gap-2 ${windowWidth > 1024 ? 'w-[28.5%]' : 'w-full'} h-full bg-[#f8f8f8] rounded-xl font-bold`}>
                        <div className={`flex-grow basis-1/5 flex ${windowWidth > 1024 ? 'justify-start' : 'justify-center'} px-4 pt-4 `}>
                            <span className=' flex text-[24px] text-[#1a73e8]'>{windowWidth < 1024 && 'сводка   '} {infoByCity[selectInfoByCity]?.city}</span>
                        </div>
                        <div className='flex-grow basis-1/5 flex flex-row justify-between px-4 py-4 items-center'>
                            <div className='flex text-[18px] w-[75%] flex-wrap'>Участвующие магазины</div>
                            <div className='flex text-[26px] text-[#1a73e8] '>{infoByCity[selectInfoByCity]?.countStore}</div>
                        </div>
                        <div className='flex-grow basis-1/5 flex flex-row justify-between px-4 py-4 items-center'>
                            <div className='flex text-[18px] w-[75%] flex-wrap'>Выполнившие магазины</div>
                            <div className='flex text-[26px] text-[#1a73e8] '>{infoByCity[selectInfoByCity]?.compliteStore}</div>
                        </div>
                        <div className='flex-grow basis-1/5 flex flex-row justify-between px-4 py-4 items-center'>
                            <div className='flex text-[18px] w-[75%] flex-wrap '>Не выполнившие магазины</div>
                            <div className='flex text-[26px] text-[#1a73e8] '>{infoByCity[selectInfoByCity]?.notDoneStore}</div>
                        </div>
                        <div className='flex-grow basis-1/5 flex flex-row justify-between px-4 py-4 items-center'>
                            <div className='flex text-[18px] w-[75%] flex-wrap'>В процессе выполнения</div>
                            <div className='flex text-[26px] text-[#1a73e8] '>{infoByCity[selectInfoByCity]?.inProcessStore}</div>
                        </div>
                    </div>
                    {/* } */}
                </div>
            </div>
            <div className="border p-3 bg-white rounded shadow ">
                <div className="flex justify-between items-center mb-3">

                    <span className=' text-[#1a73e8] text-base md:text-lg xl:text-[24px]'> Участвующие магазины</span>
                    {role === 'admin' &&
                        <div className="p-2 bg-[#1a73e8] rounded-xl shadow flex flex-row justify-between items-center gap-3 cursor-pointer" onClick={() => downloadTableCSV()}>
                            <AiOutlineFileText color='white' />
                            <div className='text-sm md:text-base xl:text-[16px]  text-white'>Выгрузить статистику</div>
                        </div>
                    }
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border-collapse">
                        <thead className="text-black font-bold text-xs sm:text-base xl:text-lg">
                            <tr>
                                <th scope="col" className="px-2 py-3 tracking-wider text-center cursor-pointer" onClick={() => sortTable('code')}>
                                    <div className="flex items-center justify-center">
                                        Код магазина
                                        <BiSort color={activeSortColumn === 'code' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('name')}>
                                    <div className="flex items-center justify-center">
                                        Название магазина
                                        <BiSort color={activeSortColumn === 'name' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('city')}>
                                    <div className="flex items-center justify-center">
                                        Город
                                        <BiSort color={activeSortColumn === 'city' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('answersCount')}>
                                    <div className="flex items-center justify-center">
                                        Вопросы и ответы
                                        <BiSort color={activeSortColumn === 'answersCount' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('percent')}>
                                    <div className="flex items-center justify-center">
                                        Прогресс
                                        <BiSort color={activeSortColumn === 'percent' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('status')}>
                                    <div className="flex items-center justify-center">
                                        Статус
                                        <BiSort color={activeSortColumn === 'status' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="px-2 py-3 text-center tracking-wider cursor-pointer" onClick={() => sortTable('finishTime')}>
                                    <div className="flex items-center justify-center">
                                        Время завершения
                                        <BiSort color={activeSortColumn === 'finishTime' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white text-center">
                            {participatingStores.map((store, index) => (
                                <tr key={index} className="bg-[#f8f8f8] border-t-[10px] border-[white] text-xs sm:text-base xl:text-lg">
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 rounded-l-3xl">
                                        {store.code}
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900">{store.name}</td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900">{store.city}</td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900">{store.answersCount}</td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900">{store.percent}</td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900">{store.status}</td>
                                    <td className="px-2 py-4 whitespace-nowrap font-medium text-gray-900 rounded-r-3xl">
                                        {store.finishTime ? store.finishTime : 'Н / З'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}