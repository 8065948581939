import React from 'react'
import  {setModalVisible} from '../../store/slices/liveReportSlice';
import { useAppDispatch } from '../../store/hooks/redux';
import { RxCrossCircled } from "react-icons/rx";

const CloseTab = () => {
    const dispatch = useAppDispatch();
    return (
        <div className="absolute top-4 right-4">
            <RxCrossCircled className="w-8 h-8 cursor-pointer" color="#1a73e8" onClick={() => dispatch(setModalVisible())} />
        </div>
    )
}

export default CloseTab
