import React, { memo, useEffect } from 'react'
import Select from 'react-select';

const DisableSelect = memo(({ selectedArray, setSelectedArray, filteredArray, setFilteredArray }) => {

    useEffect(() => {
        handleShopInputChange('');
    }, [selectedArray]);
    const handleShopInputChange = (inputValue) => {
        if (selectedArray.length != 0) {

            const searchRegex = new RegExp(inputValue.toLowerCase().toString(), 'i');

            const filteredArray = selectedArray.filter(shop =>
                searchRegex.test(shop.name.toLowerCase()) || searchRegex.test(shop.code.toLowerCase())
            );
            const sortedShops = filteredArray.sort((a, b) => {
                const aIsActive = a.isActive == 0;
                const bIsActive = b.isActive == 0;
                return bIsActive - aIsActive;
            });

            setFilteredArray(sortedShops.map((shop, index) => ({
                label: (
                    <div
                        key={shop.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            gap: '2%',
                            order: index < 2 ? -1 : (index === sortedShops.length - 1 ? 1 : 0),
                        }}
                        onChange={(event) => event.stopPropagation()}
                        onClick={(e) => { e.stopPropagation(); toggleCheckbox(shop.id); }}
                    >
                        <span style={{ width: '20%' }}> {shop.code}</span>
                        <span style={{ width: '65%' }}> {shop.name}</span>
                        <input
                            style={{ width: '5%', marginLeft: 'auto' }}
                            type="checkbox"
                            checked={shop.isActive == 0}
                            onChange={(event) => event.stopPropagation()}
                        />
                    </div>
                ),
                value: shop.name,
            })));
        } else {

            setFilteredArray([
                {
                    label: (
                        <div key="empty" style={{ textAlign: 'center', color: 'gray' }}>
                            Нет доступных элементов
                        </div>
                    ),
                    value: '',
                },
            ]);
        }
    };
    const toggleCheckbox = (shopId) => {
        setSelectedArray(prevShops =>
            prevShops.map(shop =>
                shop.id == shopId ? { ...shop, isActive: shop.isActive == 0 ? '1' : '0' } : shop
            )
        );
    };
    return (
        <Select
            id="disableShops"
            options={filteredArray}
            onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                // const selectedShopsNames = selectedOptions.map(option => option.value);
            }}
            onClick={(e) => e.stopPropagation()}
            onInputChange={(inputValue, event) => handleShopInputChange(inputValue, event)}
            styles={{
                option: (base) => ({
                    ...base,
                    padding: '8px 16px',
                }),
                menu: (base) => ({
                    ...base,
                    position: 'relative',
                }),
            }}
        />
    )
})

export default DisableSelect