import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loyaltyPrograms : [],
    tests : null,
    currentTest : {},
    currentProgram :{},
    cdfDetails:{},
    selectedCity : '',
    selectedRegion: { "id": '', "name": '' },
    selectedFormat: '',
};

const usersSlice = createSlice({
  name: 'mainHeaderSlice',
  initialState,
  reducers: {
        setDataWhenLoading(state, action){
            state.loyaltyPrograms = action.payload.loyaltyProgramsData.program;
            state.tests = action.payload.testsData;
            state.currentTest= action.payload.testsData[action.payload.testsData.length-1];
            state.currentProgram = action.payload.loyaltyProgramsData.program[action.payload.loyaltyProgramsData.id];
        },
        setCdfDetails(state, action){
            state.cdfDetails = action.payload;
        },
        setCurrentTest(state, action){
            state.currentTest = action.payload;
        },
        resettingSelectedParameters(state){
            state.selectedCity = '';
            state.selectedRegion = { "id": '', "name": '' };
            state.selectedFormat = '';
        },
        setProgramChange(state, action){
            state.currentProgram = action.payload.programs
            state.tests = action.payload.tests;
            state.currentTest = action.payload.tests[action.payload.tests.length-1];
        },
        setSelectedCity(state, action){
            state.selectedCity = action.payload
        },
        setSelectedRegion(state, action){
            state.selectedRegion = {id: action.payload.id, name: action.payload.name}
        },
        setSelectedFormat(state, action){
            state.selectedFormat = action.payload
        },
        setChangePublisher(state, action){
            const index = state.tests.findIndex(item => item.id == action.payload.id);
            state.tests[index].published = action.payload.published;
            state.currentTest.published = action.payload.published;
        }
  },
});

export const {setDataWhenLoading, setCdfDetails, setCurrentTest, resettingSelectedParameters, setProgramChange, setSelectedCity, setSelectedRegion, setSelectedFormat, setChangePublisher} = usersSlice.actions;
export default usersSlice.reducer;
