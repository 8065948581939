import React from 'react'
import { setChangingСriteria } from '../../../store/slices/liveReportSlice';
import { useAppDispatch } from '../../../store/hooks/redux';

const CriteriaWidget = ({ role, published, criteria, currentTest, store_id, topic }) => {
    const dispatch = useAppDispatch();
    return (
        <>
            <p className='flex font-bold text-[18px]'>Оценка</p>
            <div className={`flex flex-row flex-wrap gap-3 justify-between ${criteria.length>5 && 'overflow-y-scroll'} pr-3`}>
                {criteria.length !== 0 ? criteria.map((item, index) => {
                    return <div key={index} className='flex w-[45%] flex-row justify-between items-center'>
                        <div className='flex'>{item.criteria_title}</div>
                        <div className='flex p-2 bg-[#1a73e8] text-white rounded cursor-pointer hover:bg-blue-700'
                            onClick={() => {
                                if (role !== 'admin' || published == 1) {
                                    return;
                                }
                                dispatch(setChangingСriteria({ id: item.id, rating: item.rating == 1 ? 0 : 1, currentTest: currentTest, store_id: store_id , topic: topic }));
                            }}>{item.rating}</div>
                    </div>
                }) : <div className='flex justify-start items-center text-[18pt]'>Критерии еще не назначенны!</div>}
            </div>
        </>
    )
}

export default CriteriaWidget
