import React from 'react'
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

const TabController = ({currentPhotosArray,currentPhoto, setCurrentPhoto, clickToSwichPhotos }) => {


    return (
        <>
            <div className="flex flex-row gap-2 h-full items-end w-[40%] justify-start ">
                <span className='flex w-auto justify-start'>
                    <BsArrowLeftCircle
                        className={`w-[30px] h-[30px] ${currentPhoto > 0 ? 'pointer-events-auto opacity-100 cursor-pointer' : 'pointer-events-none opacity-50 cursor-none'}`}
                        onClick={() => setCurrentPhoto(prev => clickToSwichPhotos(prev - 1) !== null ? prev - 1 : prev)}
                        color="#1a73e8"
                    /></span>
                <span className="text-[gray]  w-full overflow-hidden flex justify-start" style={{ userSelect: 'none' }}>
                    {currentPhoto == 0 ? '' : currentPhotosArray[currentPhoto - 1].storeName}
                </span>
            </div>
            <div className="flex  w-[20%] justify-center h-full items-end">
                <p className="text-white text-[14px] px-2 py-2 rounded-[25px] bg-black bg-opacity-25 ">{(currentPhoto + 1) + ' / ' + currentPhotosArray.length}</p>
            </div>
            <div className="flex flex-row gap-2 h-full items-end w-[40%]  justify-end ">
                <span className="text-[gray] overflow-hidden w-full flex justify-end" style={{ userSelect: 'none' }}>
                    {currentPhoto + 1 !== (currentPhotosArray.length + 1) && currentPhotosArray[currentPhoto + 1] ?
                        currentPhotosArray[currentPhoto + 1].storeName : ''}
                </span>
                <span className='w-auto flex justify-end'>
                    <BsArrowRightCircle
                        className={`w-[30px] h-[30px]  ${currentPhotosArray.length - 1 !== currentPhoto ? 'pointer-events-auto opacity-100 cursor-pointer' : 'pointer-events-none opacity-50 cursor-none'}`}
                        onClick={() => setCurrentPhoto(prev => clickToSwichPhotos(prev + 1) || prev)}
                        color="#1a73e8"
                    />
                </span>
            </div>
        </>
    )
}

export default TabController
