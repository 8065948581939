import React, { useState, useEffect, useRef, memo } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks/redux';
import { reportDatas, setCurrentTopic, setCurrentTypeSort, setOrderBy, setPaginationOfTopics, setPublished, setSearchValue, setSelectedDay, setMagazineCount } from '../store/slices/liveReportSlice';
import axios from "axios";
import { InfoBox } from "../cms_core";
import QuestionCart from '../components/LiveReportComponent/QuestionCart';
import { useAuth } from '../hooks/useAuth';
import { DaysWeek } from '../store/locations/locations'
import Select from 'react-select';
import { BiSort } from "react-icons/bi";
import { setChangePublisher } from '../store/slices/mainHeaderSlice';
import { Bar } from "react-chartjs-2";
const barOptions = {
  scales: {
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
    x: {

      grid: {
        display: false,
        borderWidth: 0,
      },
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      clamp: false,
      font: {
        size: 0
      },
      formatter: (value, context) => {
        return typeof value === 'number' ? value.toLocaleString() : '-';
      }
    },
    tooltip: {
      enabled: true
    }
  }
};


const LifeReportPage = memo(() => {
  const { currentTest, selectedCity, selectedRegion, selectedFormat } = useAppSelector(state => state.mainHeaderSlice);
  const [loading, setLoading] = useState(true)
  const { user_id, role } = useAuth();
  const dispatch = useAppDispatch();
  // const { searchValue, reportData, currentTopic, chooseSortType, currentTypeSort, selectedDay, displayInSelection, orderBy, paginationOfTopics, modalVisible } = useAppSelector(state => state.liveReportSlice);
  const searchValue = useAppSelector(state => state.liveReportSlice.searchValue);
  const reportData = useAppSelector(state => state.liveReportSlice.reportData);
  const published = useAppSelector(state => state.liveReportSlice.published);
  const currentTopic = useAppSelector(state => state.liveReportSlice.currentTopic);
  const chooseSortType = useAppSelector(state => state.liveReportSlice.chooseSortType);
  const currentTypeSort = useAppSelector(state => state.liveReportSlice.currentTypeSort);
  const selectedDay = useAppSelector(state => state.liveReportSlice.selectedDay);
  const displayInSelection = useAppSelector(state => state.liveReportSlice.displayInSelection);
  const orderBy = useAppSelector(state => state.liveReportSlice.orderBy);
  const paginationOfTopics = useAppSelector(state => state.liveReportSlice.paginationOfTopics);
  const storeCount = useAppSelector(state => state.liveReportSlice.storeCount);
  const correctAnswers = useAppSelector(state => state.liveReportSlice.correctAnswers);
  const incorrectAnswers = useAppSelector(state => state.liveReportSlice.incorrectAnswers);
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [emptySearch, setEmptySearch] = useState(0)
  const [emptyTopic, setEmptyTopic] = useState(false)
  const timerRef = useRef(null);

  useEffect(() => {
    if (paginationOfTopics.length === 0)
      gettingTopics();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedCity, selectedRegion, selectedFormat, currentTest.id, searchValue, currentTopic, selectedDay, currentTypeSort, orderBy]);


  const gettingTopics = () => {
    setLoading(true)
    axios.post(`/index.php`, {
      module: "livereport",
      action: "getLiveReportTopics",
    })
      .then(function (response) {
        dispatch(setPaginationOfTopics(response.data))
      })
      .catch(function (error) {
        InfoBox("Error: " + error);
        console.error(error);
      });

  }

  const fetchData = () => {
    setLoading(true)
    axios.post(`/index.php`, {
      module: "livereport",
      action: "getLiveReport",
      testId: currentTest.id,
      userId: user_id,
      selectedCity: selectedCity,
      selectedRegion: selectedRegion.id,
      selectedFormat: selectedFormat,
      searchValue: searchValue,
      currentTopic: currentTopic,
      currentTypeSort: currentTypeSort,
      selectedDay: selectedDay,
      orderBy: orderBy
    })
      .then(function (response) {

        dispatch(reportDatas(response.data.dataPage));
        dispatch(setPublished(currentTest.published || response.data.publisher));
        response.data.infoChart && dispatch(setMagazineCount(response.data.infoChart))
        const newEmptySearch = response.data.dataPage.reduce((accumulator, item) => {
          if (item.total !== 0) {
            return accumulator + 1;
          }
          return accumulator;
        }, 0);
        setEmptySearch(newEmptySearch)
        if (response.data.length === 0) {
          setEmptyTopic(true)
        } else {
          setEmptyTopic(false)
        }
        setLoadingSearch(false)
        setLoading(false);
      })
      .catch(function (error) {
        InfoBox("Error: " + error);
        console.error(error);
      });
  }

  const updatePublisher = () => {
    axios.post(`/index.php`, {
      module: "livereport",
      action: "upDatePublished",
      id: currentTest.id
    })
      .then(function () {
        const updatedPublishedValue = published == 1 ? 0 : 1;
        dispatch(setPublished(updatedPublishedValue));
        dispatch(setChangePublisher({ id: currentTest.id, published: updatedPublishedValue }));
        if (updatedPublishedValue == 1) {
          InfoBox('Опубликовано');
        } else {
          InfoBox('Режим редактирования');
        }
      })
      .catch(function (error) {
        InfoBox("Error: " + error);
        console.error(error);
      });
  }

  const handleChangeDays = selectedOption => {
    dispatch(setSelectedDay(selectedOption))
  };

  const handleChange = (event) => {
    setLoadingSearch(true)
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    // old timers- del new state
    timerRef.current = setTimeout(() => {
      dispatch(setSearchValue(event));
    }, 1500);
  };

  const barData = {
    labels: [correctAnswers, incorrectAnswers],
    datasets: [
      {
        label: [],
        backgroundColor: ['#1a73e8', 'rgba(255, 0, 0)', '#808080'],
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0,
        showLine: false,
        data: [correctAnswers, incorrectAnswers],
        borderRadius: 5,
      },
    ],
  };


  return loading ? (
    <><svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg></>
  )
    : (
      <div className="container">
        <div className='flex flex-col gap-6  pt-3 pb-2 m-3'>
          <div className="flex flex-row justify-between items-center">
            <span className='flex text-[2rem] font-bold'>Живой отчет</span>
            {role === 'admin' && <>
              <button className={`flex  ${published == 1 ? 'bg-[blue]' : 'bg-[#52bb00]'} text-[white] rounded-xl p-[.625rem]`} onClick={() => updatePublisher()}>{published == 1 ? 'Редактировать' : 'Опубликовать'}</button>

            </>}
          </div>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col sm:flex-row justify-start items-start  sm:gap-5 '>
              <div className='flex flex-col w-full sm:w-1/2 lg:w-auto'>
                <span className='flex text-[.75rem] text-[#a1a1a1]'>Сортировка</span>
                <div className='flex flex-row gap-1'>
                  <select className="form-select"
                    onChange={(e) => dispatch(setCurrentTypeSort(e.target.value))}>
                    {chooseSortType.map((type, key) => {
                      return <option key={key} value={key} selected={currentTypeSort === type.value}>{type.lable}</option>
                    })}
                  </select>
                  <span className='cursor-pointer flex items-center' onClick={() => dispatch(setOrderBy(orderBy === 'asc' ? 'desp' : 'asc'))}> <BiSort color={orderBy !== 'asc' ? 'blue' : 'black'} /></span>
                </div>
              </div>
              <div className='flex flex-col w-full sm:w-1/2 lg:w-auto'>
                <span className='flex  text-[.75rem] text-[#a1a1a1]'>День недели</span>
                <Select
                  value={displayInSelection}
                  isMulti
                  name="daysWeek"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChangeDays}
                  options={DaysWeek}
                  placeholder="Выберите день недели"
                />
              </div>
            </div>
            <div className='flex flex-col sm:flex-row justify-between items-end'>
              <div className='flex gap-4 justify-around  flex-wrap miniXL:border-b-[.0625rem] border-[#e7e7e7] w-fit'>
                {paginationOfTopics.map((item, key) => {
                  return <div key={key} onClick={() => dispatch(setCurrentTopic(item))} className={`flex pb-2 w-auto ${item === currentTopic ? 'text-[#188ffd] border-b-[.0938rem] border-[#188ffd]' : 'text-black hover:border-b-[.0938rem] hover:border-[#188ffd] hover:text-[#188ffd] cursor-pointer'}`}>
                    {item}
                  </div>
                })}
              </div>
              <div className="flex w-full sm:w-auto" >
                <input
                  id="searchInput"
                  type='text'
                  placeholder='Поиск'
                  className='maxWrap:w-full lg:w-auto'
                  defaultValue={searchValue || ''}
                  onChange={(event) => handleChange(event.target.value)}
                  autoFocus={searchValue !== ''}
                  style={{
                    padding: '6px 12px',
                    fontSize: '16px',
                    lineHeight: '1.5',
                    color: '#495057',
                    backgroundColor: '#fff',
                    backgroundClip: 'padding-box',
                    border: '.0625rem solid #ced4da',
                    borderRadius: '4px',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                  }} />
                {loadingSearch && <div className="preloader">Загрузка...</div>}
              </div>
            </div>
          </div>
          {reportData.length !== 0 && currentTopic === 'Знание программы' ?
            <div className='flex flex-col lg:flex-row pt-3 pb-2 m-4 gap-6'>
              <ul className="flex flex-col w-full lg:w-1/2 gap-3 font-bold ">
                <li className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] rounded-xl p-3">
                  <span className="w-[60%] flex-wrap  text-[18px] px-2">Участвующие мазазины</span>
                  <span
                    className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{storeCount}</span>
                </li>
                <li className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] rounded-xl p-3">
                  <span className="w-[60%] flex-wrap  text-[18px] px-2">Верные ответы</span>
                  <span
                    className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{correctAnswers}</span>
                </li>
                <li className="flex flex-row justify-between items-center basis-2/6 bg-[#f8f8f8] rounded-xl p-3">
                  <span className="w-[60%] flex-wrap text-[18px] px-2"> Не верные ответы</span>
                  <span className="w-1/4 flex  text-[26px] text-[#1a73e8] font-bold justify-center">{incorrectAnswers}</span>
                </li>
              </ul>
              <div className="flex w-full lg:w-1/2 bg-[#f8f8f8] flex-col items-center justify-center gap-2 md:gap-4 rounded-xl">
                <div className='flex flex-row gap-3 lg:gap-1 xl:gap-4 mt-2 text-[12px] md:text-[14px] items-center'>
                  <div className='flex h-[20px] w-[20px] md:h-[30px] md:w-[30px] bg-[#1a73e8] rounded-xl'></div>
                  <div className='flex'>Верно</div>
                  <div className='flex h-[20px] w-[20px] md:h-[30px] md:w-[30px] bg-[red] rounded-xl'></div>
                  <div className='flex'>Не верно</div>
                </div>
                <div className='flex w-[95%]  h-[75%] items-center justify-center'>
                  <Bar data={barData} options={barOptions} />
                </div>
              </div>
            </div>
            : ''}
          {(emptySearch === 0 || emptyTopic) && <div style={{ textAlign: 'center', margin: '1.25rem' }}>
            <h4 style={{ backgroundColor: '#f0f0f0', padding: '.625rem', borderRadius: '.5rem', display: 'inline-block' }}>
              По вашему запросу данных нет!
            </h4>
          </div>}
        </div>
        {reportData.length !== 0 && reportData.map((data, index) => {
          return <QuestionCart key={index} numQuests={index} selectedCity={selectedCity} searchValue={searchValue} selectedFormat={selectedFormat} selectedRegion={selectedRegion.id} role={role} />
        })
        }
      </div>

    );
})

export default LifeReportPage;
