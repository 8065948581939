import { useSelector } from "react-redux";

export function useAuth() {
    const { email, token, user_id, role, name } = useSelector((state) => state.user);

    return {
        isAuth: !!email,
        email,
        token,
        user_id,
        role,
        name,
    };
}
