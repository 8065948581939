import React from 'react'

const Page_404 = () => {
    console.error('404');
  return (
    <div className='w-full h-full flex items-center justify-center bg-[#191919] absolute top-0 left-0'>
        <span className="text-white text-[40pt] animate-pulse">ERROR 404</span>
    </div>
  )
}

export default Page_404