import React, { useState, useEffect, memo } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks/redux';
import { setModalVisible } from '../store/slices/liveReportSlice';
import ManagerRatingModal from './managerRatingModal/managerRatingModal';
import LiveReportModal from './liveReportModal/LiveReportModal';

const MainModals = memo(() => {
    const dispatch = useAppDispatch();

    const  calledModalIcon  = useAppSelector(state => state.liveReportSlice.calledModalIcon);
    const  modalVisible  = useAppSelector(state => state.liveReportSlice.modalVisible);
    const [fullScreenOutput, setFullScreenOutput] = useState({
        output : false,
        src : '', 
    })
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (modalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalVisible]);

    useEffect(() => {
        function handleKeyDown(event) {
            if (event.keyCode === 27 && fullScreenOutput.output) { // Escape
                closeFullScrean()
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    const handleWheel = (event) => {
        let scaleFactor = 0;
        if (scale > 2) {
            scaleFactor = 0.2
        } else {
            scaleFactor = 0.1
        }

        const { deltaY, clientX, clientY } = event;

        const container = event.currentTarget;
        const image = container.querySelector('img');
        const boundingRect = image.getBoundingClientRect();

        const isInsidePhoto = (
            clientX >= boundingRect.left &&
            clientX <= boundingRect.right &&
            clientY >= boundingRect.top &&
            clientY <= boundingRect.bottom
        );

        if (isInsidePhoto) {
            const newScale = deltaY < 0 ? scale + scaleFactor : scale - scaleFactor;

            const offsetX = (clientX - boundingRect.left) * (1 - newScale / scale);
            const offsetY = (clientY - boundingRect.top) * (1 - newScale / scale);

            if (newScale >= 0.5) {
                setScale(newScale);
                image.style.transform = `scale(${newScale})`;
                image.style.transformOrigin = `${(clientX - boundingRect.left) / boundingRect.width * 100}% ${(clientY - boundingRect.top) / boundingRect.height * 100}%`;
            }

            container.scrollLeft += offsetX;
            container.scrollTop += offsetY;

            event.preventDefault();
        }
    };

    const fullScreanPhoto = () => {
        return <div
            id="flScrn"
            className="fixed top-0 left-0 w-[100%] h-[100%] flex  items-center justify-center"
            onClick={(e) => { closeFullScrean(); e.stopPropagation() }}
            onWheel={handleWheel}
        >
            <img
                src={fullScreenOutput.src}
                alt="amg"
                className=" flex justify-center max-w-[85%] max-h-[85%] cursor-zoom-in "
            />
        </div>
    }



    const closeFullScrean = (event) => {
        // const container = event.currentTarget;
        // const image = container.querySelector('img');
        // const boundingRect = image.getBoundingClientRect();

        // Проверяем, находится ли курсор в пределах фотографии
        // const isInsidePhoto = (
        //     event.clientX >= boundingRect.left &&
        //     event.clientX <= boundingRect.right &&
        //     event.clientY >= boundingRect.top &&
        //     event.clientY <= boundingRect.bottom
        // );

        // Если курсор в пределах фотографии, не закрываем фото
        //  if (isInsidePhoto) {
        //      event.preventDefault();
        // event.stopPropagation(); // Предотвращаем всплытие события
        //   }else{
        setFullScreenOutput({
            output : false,
            src : '', 
        })
        setScale(1)
        //   }

    }


    return (
        <div className="fixed z-[9999] inset-0 flex items-center justify-center overflow-hidden" onClick={(e) => { dispatch(setModalVisible()); e.stopPropagation() }}>

            <div className="fixed inset-0 transition-opacity backdrop-blur-[2px]" aria-hidden="true">
                <div className="absolute inset-0 bg-opacity-[0.1] bg-[#1d375b] "></div>
            </div>



            {fullScreenOutput.output ? (
              
                 fullScreanPhoto()
 
            ) : (
                <div className="inline-block h-[90%] w-[85%] bg-white rounded-lg overflow-hidden shadow-xl transform  " onClick={(e) => e.stopPropagation()}>
                    {calledModalIcon === 'managerRating' ?
                        <ManagerRatingModal setFullScreenOutput={setFullScreenOutput} /> :
                        <LiveReportModal setFullScreenOutput={setFullScreenOutput} />
                    }
                </div>
            )}
        </div>
    )
}
)
export default MainModals

