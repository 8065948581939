import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/redux';
import { setSaveChangeModalDatas } from '../../../store/slices/liveReportSlice';
import { InfoBox } from '../../../cms_core';
import axios from "axios";

const ButtonSendData = () => {
    const dispatch = useAppDispatch();
    const currentTest = useAppSelector(state => state.mainHeaderSlice.currentTest);
    const { changingСriteria, changeDrawing, changeCommentStore,  changeTracking } = useAppSelector(state => state.liveReportSlice);

    async function fetchDisplayData() {
        try {
            await axios.post(`/index.php`, {
                module: "livereport",
                action: "sendModalData",
                changeCriteria: changingСriteria,
                changeComment: changeCommentStore,
                changePhotoTags: changeDrawing,
                changeTracking: changeTracking,
                test_id: currentTest.id
            });
            dispatch(setSaveChangeModalDatas());
            InfoBox("Данные сохраненны!");
        } catch (error) {
            console.error("Error fetching tests and tasks:", error);
            // InfoBox("Error fetching tests and tasks: " + error);
        }
    }

    return (
        <button
            type='button'
            className=' bg-[#1A73E8] text-white p-2 rounded-xl cursor-pointer hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300'
            onClick={() => fetchDisplayData()}>Save</button>
    )
}

export default ButtonSendData
