import { useEffect, useState } from "react";
import axios from "axios";
import { InfoBox } from "../../cms_core";
import DisableSelect from "./ui/DisableSelect";
import { GetDisabledIds } from "./model/GetDisabledIds";

export function ProgramCreation({ userId, isEditing }) {

    const [programs, setPrograms] = useState([]);
    const [errors, setErrors] = useState({});
    const [divisions, setDivisions] = useState([]);
    const [formats, setFormats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [programName, setProgramName] = useState('');
    const [divisionsIndexCounter, setDivisionsIndexCounter] = useState(1);
    const [formatsIndexCounter, setFormatsIndexCounter] = useState(1);
    const [currentProgram, setCurrentProgram] = useState({});
    const [selectedImage, setSelectedImage] = useState();
    const [valibleImage, setValibleImage] = useState(false);
    const [nameError, setNameError] = useState(false)
    const [criteria, setCriteria] = useState([])
    const topic_criteria = ['Дисплеи', 'Рекламные носители'];
    const [filtredTest, setFilteredTest] = useState([]);
    const [selectedTest, setSelectedTets] = useState([]);

    useEffect(() => {
        (async function () {
            if (isEditing) {
                loadData().then(r => {
                    if (r) {
                        fillProgramForm(r[0]);
                    }
                });
            } else {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!loading) {
            document.getElementById('programForm').reset();
            if (isEditing) {
                loadData().then(r => {
                    if (r) {
                        fillProgramForm(r[0]);
                    }
                });
            } else {
                setSelectedImage('');
                setSelectedTets([])
                setPrograms([]);
                setProgramName('');
                setFormatsIndexCounter(1);
                setDivisionsIndexCounter(1);
                setDivisions([]);
                setCriteria([]);
                setNameError(false)
                setFormats([]);
                setCurrentProgram({});
            }
        }
    }, [isEditing, loading]);

    async function loadData() {
        let programsData;
        await axios.post(`/index.php`, {
            module: "loyaltyprogram",
            action: "getAllAdminPanel"
        })
            .then(function (response) {
                setPrograms(response.data.loyaltyPrograms);
                setSelectedImage(response.data.loyaltyPrograms[0].logo)
                setCriteria(response.data.loyaltyPrograms[0].criteria)
                setSelectedTets(response.data.loyaltyPrograms[0].selectedTests)
                setValibleImage(false)
                setNameError(false)
                programsData = response.data.loyaltyPrograms;
            })
            .catch(function (error) {
                InfoBox("Error in getAllPrograms: " + error);
                console.error(error);
            });

        await axios.post(`/index.php`, {
            module: "store",
            action: "getDivisionsAndFormats",
            programId: programsData[0].id
        })
            .then(function (response) {
                setCurrentProgram(programsData[0]);
                setDivisions(response.data.divisions);
                setFormats(response.data.formats);
                setDivisionsIndexCounter(response.data.divisions.length + 1);
                setFormatsIndexCounter(response.data.formats.length + 1);
                setLoading(false);
            })
            .catch(function (error) {
                InfoBox("Error in getDivisionsAndFormats: " + error);
                console.error(error);
            });

        return programsData;
    }

    function onSelectedProgramChange(event) {
        const selectedProgram = programs[event.target.value];
        setDivisions([]);
        setFormats([]);
        setCriteria([]);
            (async function () {
                await axios.post(`/index.php`, {
                    module: "store",
                    action: "getDivisionsAndFormats",
                    programId: selectedProgram.id
                })
                    .then(function (response) {
                        setDivisions(response.data.divisions);
                        setFormats(response.data.formats);
                        setSelectedImage(selectedProgram.logo)
                        setValibleImage(false)
                        setCurrentProgram(selectedProgram);
                        setCriteria(selectedProgram.criteria);
                        setDivisionsIndexCounter(response.data.divisions.length + 1);
                        setFormatsIndexCounter(response.data.formats.length + 1);
                        setSelectedTets(selectedProgram.selectedTests);
                    })
                    .catch(function (error) {
                        InfoBox("Error in getDivisionsAndFormats: " + error);
                        console.error(error);
                    });
                fillProgramForm(selectedProgram);
            })();
    }

    function fillProgramForm(selectedProgram) {
        setProgramName(selectedProgram.name);
        if (document.getElementById('programTitle')) {
            document.getElementById('programTitle').value = selectedProgram.name;
        }
    }

    function onSubmitClick() {
        const newDivisions = [...divisions];
        const newFormats = [...formats];
        const newCriteria = [...criteria];
        if (newDivisions.length === 0 || newFormats.length === 0 || criteria.length === 0) {
            InfoBox("У программы лояльности должен быть как минимум один дивизион, формат и критерия!");
        } else if (nameError) {
            InfoBox("Введено запотентованное название!");
        }
        else if (!programName.trim()) {
            InfoBox("Поле название программы - обязательное! ");
        }
        else {
            for (let i = 1; i <= newDivisions.length; i++) {
                let name = document.getElementById('division' + i).value;
                if (name !== newDivisions[i - 1].name) {
                    newDivisions[i - 1].name = name;
                }
            }
            for (let i = 1; i <= newFormats.length; i++) {
                let name = document.getElementById('format' + i).value;
                if (name !== newFormats[i - 1].name) {
                    newFormats[i - 1].name = name;
                }
            }
            for (let i = 1; i <= newCriteria.length; i++) {
                let title = document.getElementById('criteria' + i).value;
                if (title !== newCriteria[i - 1].title) {
                    newCriteria[i - 1].title = title;
                }
                const topic_criteria = document.getElementById('topic_criteria' + i).value;
                if (topic_criteria !== newCriteria[i - 1].topic_criteria) {
                    newCriteria[i - 1].topic_criteria = topic_criteria;
                }
            }
            setDivisions(newDivisions);
            setFormats(newFormats);
            setCriteria(newCriteria)
            let programPhoto = '';
            if (selectedImage && valibleImage) {
                programPhoto = selectedImage.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
            }
        
            if (!isEditing) {
                axios.post(`/index.php`, {
                    module: "loyaltyprogram",
                    action: "create",
                    divisions: newDivisions,
                    formats: newFormats,
                    programName: programName,
                    programPhoto: programPhoto,
                    criteria: newCriteria,
                })
                    .then(function (response) {
                        InfoBox("Программа лояльности успешно создана");
                        document.getElementById('programForm').reset();
                        window.location.reload(true)
                    })
                    .catch(function (error) {
                        InfoBox("Program creating error: " + error);
                        console.error(error);
                    });
            } else {
                const disableTests = GetDisabledIds(selectedTest);
                axios.post(`/index.php`, {
                    module: "loyaltyprogram",
                    action: "update",
                    divisions: newDivisions,
                    formats: newFormats,
                    programName: programName,
                    programId: currentProgram.id,
                    programPhoto: programPhoto,
                    criteria: newCriteria,
                    disableTests :disableTests
                })
                    .then(function (response) {
                        InfoBox("Программа лояльности успешно обновлена");
                        document.getElementById('programForm').reset();
                        window.location.reload(true)
                    })
                    .catch(function (error) {
                        InfoBox("Program updating error: " + error);
                        console.error(error);
                    });
            }
        }
    }

    function onCreateDivision() {
        if (document.getElementById('newDivision').value.length > 0) {
            const newDivisions = [...divisions];
            newDivisions.push({
                name: document.getElementById('newDivision').value
            });
            document.getElementById('newDivision').value = '';
            setDivisionsIndexCounter(divisionsIndexCounter + 1);
            setDivisions(newDivisions);
        } else {
            InfoBox("Введите название дивизиона");
        }
    }

    function onDeleteDivision() {
        if (divisionsIndexCounter !== 1) {
            const updatedDivisions = divisions.filter((_, index) => index !== (divisionsIndexCounter - 2));
            setDivisionsIndexCounter(divisionsIndexCounter - 1);
            setDivisions(updatedDivisions);
        } else {
            InfoBox("У программы лояльности должен быть как минимум один дивизион");
        }
    }


    function onCreateCriteria() {
        if (document.getElementById('newCriteria').value.trim().length > 0) {
            const newCriteria = [...criteria];
            newCriteria.push({
                title: document.getElementById('newCriteria').value.trim(),
                topic_criteria: document.getElementById('NewTopic_criteria').value
            });
            document.getElementById('newCriteria').value = '';
            setCriteria(newCriteria);
        } else {
            InfoBox("Введите критерию оценивания");
        }
    }
    function onDeleteCriteria() {
        if (criteria.length != 0) {
            const updatedCriteria = criteria.filter((_, index) => index !== (criteria.length - 1));
            setCriteria(updatedCriteria)
        } else {
            InfoBox("У программы лояльности должна быть минимум 1 критерия");
        }
    }

    function onCreateFormat() {
        if (document.getElementById('newFormat').value.length > 0) {
            const newFormats = [...formats];
            newFormats.push({
                name: document.getElementById('newFormat').value
            });
            document.getElementById('newFormat').value = '';
            setFormatsIndexCounter(formatsIndexCounter + 1);
            setFormats(newFormats);
        } else {
            InfoBox("Введите название формата");
        }
    }

    function onDeleteFormat() {
        if (formatsIndexCounter !== 1) {
            const updatedFormats = formats.filter((_, index) => index !== (formatsIndexCounter - 2));
            setFormatsIndexCounter(formatsIndexCounter - 1);
            setFormats(updatedFormats);
        } else {
            InfoBox("У программы лояльности должен быть как минимум один формат");
        }
    }
    //TODO: ДОДЕЛАТЬ ВАЛИДАЦИЮ НОРМАЛЬНУЮ НА ЗАЩИТУ 
    const validNames = (e) => {
        const lowercasedValue = e.trim().toLowerCase();
        if (lowercasedValue == 'unknown' ||
            lowercasedValue == 'image' ||
            lowercasedValue == 'photos' ||
            lowercasedValue == 'icon' ||
            lowercasedValue == 'static') {
            setNameError(true)
        } else {
            setProgramName(e)
            setNameError(false)
        }
    }



    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                // resolve(fileReader.result.replace(/data:image\/png;base64,/, ''));
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handlePhotoCheck = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            convertBase64(file)
                .then((base64String) => {
                    setSelectedImage(base64String);
                    setValibleImage(true)
                })
                .catch((error) => {
                    console.error('Ошибка при конвертации в base64:', error);
                });
        } else {
            InfoBox('Выбранный файл не является изображением.');
        }
    };


    const buttonStyle = {
        backgroundColor: "#007bff",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer"
    };

    const formStyle = {
        padding: "10px",
        marginBottom: "10px"
    };

    const labelStyle = {
        display: "block",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const inputStyle = {
        display: "flex",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const cancelButtonStyle = {
        backgroundColor: "white",
        color: "black",
        marginRight: "10px"
    };

    return (
        loading ? (
            <>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </>
        ) : (
            <div className="container">
                <div className="row border p-3 m-3 bg-white rounded shadow" >
                    <div className="col ">
                        {isEditing ? <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <select className="form-select" id="programs-select" onChange={onSelectedProgramChange}>
                                {programs.map((program, index) => {
                                    return (
                                        <option key={program.id} value={index}>{program.name}</option>
                                    );
                                })}
                            </select>
                        </div> : <></>}
                        <form style={formStyle} id="programForm">
                            <div className="form-group">
                                <label style={labelStyle} htmlFor="title">Название:</label>
                                <input
                                    style={{ ...inputStyle, border: nameError ? '1px solid red' : inputStyle.border }}
                                    type="text"
                                    id="programTitle"
                                    name="Title"
                                    className="form-control"
                                    onChange={(e) => validNames(e.target.value)} />
                                {nameError ? <span style={{ color: 'red' }}>Запотентованное название!</span> : ''}
                            </div>
                            <div className="separator">
                                <div className="separator">
                                    <div id="divisionsDiv">
                                        {(divisions.length > 0) ? divisions.map((program, index) => {
                                            return (
                                                <div className="form-group">
                                                    <label style={labelStyle} htmlFor="division">{"Дивизион №" + (index + 1) + ":"}</label>
                                                    <input style={inputStyle} type="text" id={"division" + (index + 1)} name="Division"
                                                        className="form-control" defaultValue={program.name} />
                                                </div>
                                            );
                                        }) : <></>}
                                        <div className="form-group">
                                            <label style={labelStyle} htmlFor="division">{"Дивизион №" + divisionsIndexCounter + ":"}</label>
                                            <input style={inputStyle} type="text" id={"newDivision"} name="Division" className="form-control" />
                                        </div>
                                    </div>
                                    <button style={cancelButtonStyle} type="button" onClick={onDeleteDivision}>
                                        Удалить дивизион
                                    </button>
                                    <button style={cancelButtonStyle} type="button" onClick={onCreateDivision}>
                                        Сохранить дивизион
                                    </button>
                                </div>
                                <div className="separator">
                                    <div id="formatsDiv">
                                        {(formats.length > 0) ? formats.map((program, index) => {
                                            return (
                                                <div className="form-group">
                                                    <label style={labelStyle} htmlFor="format">{"Формат №" + (index + 1) + ":"}</label>
                                                    <input style={inputStyle} type="text" id={"format" + (index + 1)} name="Format"
                                                        className="form-control" defaultValue={program.name} />
                                                </div>
                                            );
                                        }) : <></>}
                                        <div className="form-group">
                                            <label style={labelStyle} htmlFor="format">{"Формат №" + formatsIndexCounter + ":"}</label>
                                            <input style={inputStyle} type="text" id={"newFormat"} name="Format" className="form-control"
                                                defaultValue='' />
                                        </div>
                                    </div>
                                    <button style={cancelButtonStyle} type="button" onClick={onDeleteFormat}>
                                        Удалить формат
                                    </button>
                                    <button style={cancelButtonStyle} type="button" onClick={onCreateFormat}>
                                        Сохранить формат
                                    </button>
                                </div>
                                <div className="seperator">
                                    <div>
                                        {criteria.length > 0 && criteria.map((item, key) => {
                                            return <div className="form-group" key={key}>
                                                <label style={labelStyle} htmlFor="criteria">{"Критерия №" + (key + 1) + ":"}</label>
                                                <div className="flex flex-row gap-1 items-center justify-center w-full">
                                                    <input type="text" id={"criteria" + (key + 1)} name="Сriteria"
                                                        className="form-control" defaultValue={item.title} />
                                                    <select className="form-select" id={"topic_criteria" + (key + 1)} >
                                                        {topic_criteria.map((topic, index) => {
                                                            return (
                                                                <option key={index} selected={topic === item.topic_criteria} value={topic}>{topic}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        })
                                        }
                                        <div className="form-group">
                                            <label style={labelStyle} htmlFor="criteria">{"Новая критерия №" + (criteria.length + 1) + ":"}</label>
                                            <div className="flex flex-row gap-1 items-center justify-center w-full">
                                                <input type="text" id={"newCriteria"} name="Criteria" className="form-control"
                                                    defaultValue='' />
                                                <select className="form-select" id={"NewTopic_criteria"} >
                                                    {topic_criteria.map((topic, index) => {
                                                        return (
                                                            <option key={index} selected value={topic}>{topic}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <button style={cancelButtonStyle} type="button" onClick={onDeleteCriteria}>
                                            Удалить критерию
                                        </button>
                                        <button style={cancelButtonStyle} type="button" onClick={onCreateCriteria}>
                                            Сохранить критерию
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button style={{
                                ...buttonStyle,
                                cursor: nameError ? 'not-allowed' : 'pointer',
                                backgroundColor: nameError ? 'gray' : buttonStyle.backgroundColor,
                                color: nameError ? 'darkgray' : buttonStyle.color,
                                opacity: nameError ? 0.6 : 1,
                            }} type="button" onClick={onSubmitClick} disabled={nameError}>Сохранить</button>
                        </form>
                        {Object.keys(errors).map(fieldName => (
                            <div key={fieldName} className="error-message">
                                <span style={{ color: "red" }}>
                                    {errors[fieldName]}
                                </span>
                            </div>
                        ))}
                    </div>

                    <div className="col-md-6">
                        {isEditing &&
                            <div>
                                <label className="pt-20 text-color-[]"> DisableTests</label>
                                <DisableSelect selectedArray={selectedTest} setSelectedArray={setSelectedTets} filteredArray={filtredTest} setFilteredArray={setFilteredTest} />
                            </div>
                        }
                        <h1> Фото программы </h1>
                        <label style={labelStyle} for="photoInput"> {isEditing ? 'Измените фото:' : 'Выберите фото:'}</label>
                        <input style={inputStyle} type="file" id="photoInput" name="photo" accept=".jpg, .jpeg, .png" onChange={(e) => handlePhotoCheck(e)} />
                        <img src={selectedImage} alt="Изображение" style={{ maxWidth: '500px', maxHeight: '250px' }} />
                    </div>
                </div>
            </div>
        )
    );
}