import React, { useEffect } from 'react';
import axios from "axios";
import { InfoBox } from "../../cms_core";
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { resettingSelectedParameters, setCdfDetails, setCurrentTest, setProgramChange, setSelectedCity, setSelectedFormat, setSelectedRegion } from "../../store/slices/mainHeaderSlice";
import { reportDatas, setPublished, setSearchValue } from '../../store/slices/liveReportSlice';
import { useAuth } from '../../hooks/useAuth';

const MainHeader = () => {
    const dispatch = useAppDispatch();
    const { loyaltyPrograms, tests, currentTest, currentProgram, cdfDetails, selectedCity, selectedRegion, selectedFormat } = useAppSelector(state => state.mainHeaderSlice);
    const location = useLocation();
    const { role, user_id } = useAuth();

    useEffect(() => {
        if (currentTest.id) {
            (async function () {
                await axios.post(`/index.php`, {
                    module: "progress",
                    action: "getInfoDivisionFormat",
                    testId: currentTest.id,
                    userId: user_id
                })
                    .then(function (response) {
                        dispatch(setCdfDetails(response.data))
                    })
                    .catch(function (error) {
                        InfoBox("Error: " + error);
                        console.error(error);
                    });
            })();
        }
    }, [currentProgram.id]);

    const onSelectedProgramChange = async (event, index) => {
        const selectedProgram = loyaltyPrograms[event.target.value];
        axios.post(`/index.php`, {
            module: "test",
            action: "list",
            loyalty_programs_id: selectedProgram.id,
            role: role,
            selectedProgram: index
        })
            .then(function (response) {
                dispatch(reportDatas([]))
                dispatch(setSearchValue(''))
                dispatch(setPublished(response.data[response.data.length - 1].published))
                dispatch(setProgramChange({ tests: response.data, programs: selectedProgram }))
                dispatch(resettingSelectedParameters())
            })
            .catch(function (error) {
                InfoBox("Error in test: " + error);
                console.error(error);
            });
    }
    const logOut = async () => {
        axios.post(`/index.php`, {
            module: "usermgt",
            action: "logOut"
        })
            .then(function () {
                localStorage.removeItem('user')
                window.location.reload(true);
            })
            .catch(function (error) {
                InfoBox("Error in : " + error);
                console.error(error);
            });
    }

    function onSelectedTestChange(event) {
        const selectedTest = tests[event.target.value];
        dispatch(setCurrentTest(selectedTest))
        dispatch(reportDatas([]))
        dispatch(setPublished(selectedTest.published))

    }
    const StoreHeader = () => {
        return <header className="p-3 mb-3 border-bottom">
            <div className='flex flex-row w-full justify-around '>
                <a href="/" style={{ marginRight: '20px' }}>
                    <img
                        alt="amg"
                        src={require('../../assets/icon.png')}
                        height={40}
                        width={40}
                        className={'d-inline-block align-top'}
                    />
                </a>
                <ul className="nav nav-pills col-12 col-lg-auto me-lg-auto mb-2 justify-content-center">
                    <li className="px-2 mx-2">
                        <Link
                            to="/"
                            className={location.pathname.startsWith("/ratingStore") || location.pathname === "/" ? "nav-link active" : "nav-link"}>Мои задания
                        </Link>
                    </li>
                    <li className="px-2 mx-2">
                        <Link
                            to="/rating"
                            className={location.pathname === "/rating" ? "nav-link active" : "nav-link"}
                        >Рейтинг
                        </Link>
                    </li>
                    <li className="px-2 mx-2">
                        <div
                            type="button"
                            className={"nav-link"}
                            onClick={() => logOut()}>Выйти
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    }
    const AdminHeader = () => {
        return <header className="p-3 mb-3 border-bottom">
            <div className="row">
                <div className="d-flex flex-wrap align-self-center justify-content-center justify-content-lg-center">
                    <a href="/" className="d-flex align-self-center mb-2 mb-lg-0">
                        <img
                            alt="amg"
                            src={require('../../assets/icon.png')}
                            height={40}
                            width={40}
                            className={'d-inline-block align-top'}
                        />
                    </a>

                    <ul className="nav nav-pills col-12 col-lg-auto me-lg-auto mb-2 justify-content-center">
                        <li className="px-2 mx-2">
                            <div
                                type="button"
                                className={"nav-link"}
                                onClick={() => logOut()}>Выйти
                            </div>
                        </li>
                        <li className="px-2 mx-2">
                            <Link
                                to="/"
                                className={location.pathname === "/" ? "nav-link active" : "nav-link"}>Прогресс
                            </Link>
                        </li>
                        <li className="px-2 mx-2">
                            <Link
                                to="/liveReport"
                                className={location.pathname === "/liveReport" || location.pathname === "/liveReport/MoreDetails" ? "nav-link active" : "nav-link"}
                            >Живой отчет
                            </Link>
                        </li>
                        <li className="px-2 mx-2">
                            <Link
                                to="/managerRating"
                                className={location.pathname.startsWith("/ratingStore") || location.pathname === "/managerRating" ? "nav-link active" : "nav-link"}
                            >Оценка менеджера
                            </Link>
                        </li>
                        {role === 'superuser' && <li className="px-2 mx-2">
                        <Link
                            to="/rating"
                            className={location.pathname === "/rating" ? "nav-link active" : "nav-link"}
                        >Рейтинг
                        </Link>
                    </li>}
                        {/* {
                role === 'admin' ? <li className="px-2 mx-2">
                    <Link
                        to="/adminPanel"
                        className={currentPath === "/adminPanel" ? "nav-link active" : "nav-link"}
                        onClick={() => setCurrentPath("/adminPanel")}>Панель администратора
                    </Link>
                </li> : <></>
            } */}

                    </ul>
                    {((!location.pathname.startsWith("/ratingStore")) && (location.pathname !== "/rating")) &&
                        <div className="row">

                            <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                                <select className="form-select" onChange={e => onSelectedCityChange(e.target.value)} >
                                    <option value="" selected={selectedCity == ''}>Все города</option>
                                    {cdfDetails.city && cdfDetails.city?.map((city, index) => (
                                        <option key={index} selected={city.city === selectedCity} value={city.city}>{city.city}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                                <select className="form-select" onChange={e => onSelectedRegionChange({ id: e.target.value, name: e.target.options[e.target.selectedIndex].text })} >
                                    <option value="" selected={selectedRegion.name == ''} >Все регионы</option>
                                    {cdfDetails.region && cdfDetails.region?.map((region, index) => (
                                        <option key={index} selected={region.id == selectedRegion.id} value={region.id}>{region.name}</option>
                                    ))}
                                </select>

                            </div>


                            <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                                <select className="form-select" onChange={e => onSelectedFormatChange(e.target.value)} >
                                    <option value="" selected={selectedFormat == ''} >Все форматы</option>
                                    {cdfDetails.region && cdfDetails.format?.map((format, index) => (
                                        <option key={index} selected={format.id == selectedFormat} value={format.id}>{format.name}</option>
                                    ))}
                                </select>

                            </div>

                            <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                                <select className="form-select" id="test" onChange={onSelectedTestChange}>
                                    {tests?.map((test, index) => {
                                        return <option key={test.id} selected={test.id == currentTest.id} value={index}>{test.title}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                                <select className="form-select" id="program" onChange={e => onSelectedProgramChange(e, e.target.value)}>
                                    {loyaltyPrograms?.map((program, index) => {
                                        return <option key={program.id} selected={program.id == currentProgram.id} value={index}>{program.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </header>
    }
    const AdminPanelHeader = () => {
        return <header className="p-3 mb-3 border-bottom" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <a href="/" style={{ marginRight: '20px' }}>
                    <img
                        alt="amg"
                        src={require('../../assets/icon.png')}
                        height={40}
                        width={40}
                        className={'d-inline-block align-top'}
                    />
                </a>
                <div style={{ flex: '1', textAlign: 'center', width: '100%' }}>
                    <h1 style={{ margin: '0', width: '100%', textAlign: 'center' }}>Super new AdminPanel!</h1>
                </div>
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => logOut()}
                >
                    Выйти
                </button>
            </div>
        </header>
    }

    const onSelectedCityChange = (value) => {
        dispatch(setSelectedCity(value));
    };
    const onSelectedRegionChange = (value) => {
        dispatch(setSelectedRegion(value));
    };
    const onSelectedFormatChange = (value) => {
        dispatch(setSelectedFormat(value))
    };

    return (
        <>
            {location.pathname != '/adminPanel' ?
                role === 'store' ?
                    StoreHeader() :
                    AdminHeader()
                : AdminPanelHeader()}
        </>

    )
}

export default MainHeader