import { useEffect, useState } from "react"
import axios from "axios"
import { InfoBox } from "../cms_core";
import { useAuth } from "../hooks/useAuth";
import { useAppSelector } from "../store/hooks/redux";

// export default function RaitingPage({ selectedTest, userId, selectedCity, program, selectedRegion, selectedFormat }) {
export default function RaitingPage() {

    const { user_id, role } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [ratingData, setRatingData] = useState([]);
    const [lastPublished, setLastPublished] = useState('')

    useEffect(() => {
        (async function () {
            await axios.post(`/index.php`, {
                module: 'progress',
                action: 'ratingByStore',
                id: user_id,
                role : role,
            })
                .then((response) => {
                    setRatingData(response.data)
                    setLastPublished(response.data[0].lastPublished)
                    setIsLoading(false)
                })
                .catch((error) => {
                    InfoBox("Error: " + error)
                    console.error(error)
                })
        })()
    }, [])

    if (isLoading) {
        return (
            <>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </>
        )
    }
    return (
        <div className="container">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h6 className="font-bold text-3xl">Рейтинг</h6>

                <h2>Обновлено: {lastPublished}</h2>
            </div>
            <div>
                <div className="flex items-center !w-full">
                    <table className="border-separate border-spacing-y-2 border-0 !w-full">
                        <thead>
                            <tr className="p-2">
                                <th scope="col" className="text-left p-4">Позиция</th>
                                <th scope="col" className="text-left p-4">Итоговая оценка</th>
                                <th scope="col" className="text-left p-4">Магазин</th>
                                <th scope="col" className="text-left p-4">Город</th>
                                <th scope="col" className="text-left p-4">Задания</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ratingData.length > 0 && ratingData.map((itemRating, index) => {
                               return <tr key={index} id={itemRating.id} className={`${itemRating.id !== user_id ? 'bg-[#F8F8F8]' : 'bg-[#1a73e8]'} `}>
                                    <td className="text-left p-4 rounded-l-xl">{index+1}</td>
                                    <td className="text-left p-4 ">{itemRating.grade}</td>
                                    <td className="text-left p-4">{itemRating.name}</td>
                                    <td className="text-left p-4">{itemRating.city}</td>
                                    <td className="text-left p-4 rounded-r-xl">{itemRating.sumManager +'/'+itemRating.countTests}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}