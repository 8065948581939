import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { setUser } from "../store/slices/userSlice";
import { InfoBox } from "../cms_core";
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";

export default function LoginPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        (async function () {
            let usersData;

            await getDoc(doc(db, "stores", "stores"))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        usersData = snapshot.data();
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            if (usersData) {
                setUsers(usersData);
                setLoading(false);
            }
        })();
    }, []);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.keyCode === 13 && document.activeElement.tagName !== 'BUTTON') {
                document.getElementById("button-Login").click();
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    async function onEnterClick() {


        if ((email.trim() !== '') && (password.trim() !== '')) {

            const username = email.split('@')[0];
            let userRole = '';

            for (let user in users) {
                if (user === username) {
                    if (users[user].role) {
                        if (users[user].role !== 'tester') {
                            userRole = users[user].role;
                            break;
                        }
                    } else {
                        return;
                    }
                }
            }

            axios.post(`/index.php`, {
                module: "usermgt",
                action: "login",
                email: email,
                password: password
            })
                .then(function (response) {
                    let user = response.data;
                    console.log(user);
                    if (user.success) {
                        dispatch(setUser({
                            email: user.email,
                            user_id: user.id,
                            role: user.role,
                            division: user.divisionId,
                            name: user.store_name ? user.store_name : null,
                        }));
                        navigate('/');
                    } else {
                        InfoBox(user.message);
                    }
                })
                .catch(function (error) {
                    InfoBox("Error in usermgt: " + error);
                    console.error(error);
                });
        } else {
            InfoBox("Error in : fill in the field");
        }

    }

    return loading ? (
        <></>
    ) : (
        <div className="container col-xl-10 col-xxl-8 px-4 py-5">
            <img
                src={require('../assets/icon.png')}
                height={75}
                width={75}
                className={'d-block mx-auto mb-4'}
            />
            <div className="row align-items-center g-lg-5 py-5">
                <div className="col-md-10 mx-auto col-lg-5">
                    <form className="p-4 p-md-5 border rounded-3 bg-light">
                        <div className="form-outline mb-4 justify-content-sm-center">
                            <input
                                type="email"
                                className="form-control form-control-lg"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Логин"
                            />
                        </div>
                        <div className="form-outline mb-4 justify-content-sm-center">
                            <input
                                type="password"
                                className="form-control form-control-lg"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Пароль"
                            />
                        </div>
                        <button id="button-Login" type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" onClick={onEnterClick}>Войти</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
