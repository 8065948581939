import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { setModalVisible } from "../../store/slices/liveReportSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function PictureGallery({ photosArray }) {
    const dispatch = useAppDispatch();
    const { qustionNumber } = useAppSelector(state => state.liveReportSlice);
    const [focusedIndex, setFocusedIndex] = useState(null); 

    return (
        <div className="flex flex-wrap justify-center">
            {photosArray.rows.map((image, key) => {
                return (
                    <div 
                        key={key} 
                        onMouseEnter={() => setFocusedIndex(key)} 
                        onMouseLeave={() => setFocusedIndex(null)} 
                        className='relative inline-block'
                    >
                        {image.name && focusedIndex === key && 
                            <div className="absolute top-[1%] left-[1%] bg-[#1A73E8] text-white flex flex-col content-center px-2 py-2 rounded-tl-lg rounded-br-lg">
                                <span className="flex justify-start text-[14px]">{image.city}</span>
                                <span className="flex justify-start text-[12px]"> {image.name}</span>
                            </div>
                        }
                        <LazyLoadImage
                            onClick={() => dispatch(setModalVisible({ photoNumber: key, numQuests: qustionNumber }))}
                            alt={"amg - " + image.name}
                            src={image.answer}
                            className="inline-block align-top w-[382px] h-[382px] mx-1 my-1 cursor-pointer rounded-lg"
                        />
                    </div>
                );
            })}
        </div>
    );
}