import React, { memo, useState } from 'react';
import axios from "axios";
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { InfoBox } from "../../cms_core";
import { API_BASE_URL } from "../../store/url/ProgressUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BsDownload } from 'react-icons/bs';
import { setModalVisible, setQustionNumber } from '../../store/slices/liveReportSlice';
import { Link } from "react-router-dom";
import AnswersSingAndMultipl from './AnswersChart';

const QuestionCart = memo(({ numQuests, selectedCity, searchValue, selectedFormat, selectedRegion, role }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useAppDispatch();
  // const { reportData, currentTopic, currentTypeSort, selectedDay, orderBy} = useAppSelector(state => state.liveReportSlice);
  const reportData = useAppSelector(state => state.liveReportSlice.reportData);
  const currentTopic = useAppSelector(state => state.liveReportSlice.currentTopic);
  const currentTypeSort = useAppSelector(state => state.liveReportSlice.currentTypeSort);
  const selectedDay = useAppSelector(state => state.liveReportSlice.selectedDay);
  const orderBy = useAppSelector(state => state.liveReportSlice.orderBy);
  var ChartDatasSet = [
    {
      label: '',
      data: [0],
    }
  ];

  const handleToggleFullScreen = () => {
    setFullScreen(prev => !prev);
  };


  const renderAnswers = () => {
    const { type, counts } = reportData[numQuests];

    switch (type) {
      case 'photo':
        return renderPhotos();
      case 'open':
        return <OpenAnswer counts={counts} />;
      default:
        return (
          <>
            <AnswersSingAndMultipl counts={counts} initialChartDatasets={ChartDatasSet} />
          </>
        );
    }
  };



  const renderPhotos = () => {
    return (
      <>
        <div className={fullScreen ? "overflow-hidden flex flex-row flex-wrap p-0 m-0 justify-center" : "overflow-x-scroll flex flex-row"}>
          {reportData[numQuests].photos.map((photo, number) => (
            <PhotoAnswer key={number} source={photo.image} storeName={photo.storeName} sity={photo.storeCity} photoNumber={number} />
          ))}
        </div>
      </>
    );
  };



  function PhotoAnswer({ source, storeName, sity, photoNumber }) { //photoSlider
    const [onFocus, setFocus] = useState(false);

    return (
      <div className={`relative my-1  h-full mx-1 `} onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)}>
        {storeName && onFocus && <div className="absolute top-0 left-0 bg-[#1A73E8]  text-white flex flex-col content-center px-2 py-2 rounded-tl-lg rounded-br-lg">
          <span className="flex justify-start text-[14px]">{sity}</span>
          <span className="flex justify-start text-[12px]"> {storeName}</span>
        </div>}
        <LazyLoadImage alt="amg-PhotoAnswer" onClick={() => dispatch(setModalVisible({ photoNumber: photoNumber, numQuests: numQuests }))} src={source} className=" w-[382px] h-[382px] cursor-pointer max-w-[none] rounded-lg" />
      </div>
    )
  }


  function OpenAnswer() { // question type Open
    let openAnswers = [];
    Object.keys(reportData[numQuests].counts).map((answer, index) => (openAnswers.push(answer)));
    return (
      <div className='flex flex-wrap w-full h-full gap-2'>
        {openAnswers.map((answer, index) => (
          <span key={index} className='flex rounded-xl bg-[#E0E0E0] p-2 min-w-[40px] justify-center'>{answer}</span>
        ))}
      </div>
    )
  }

  const downloadPhoto = (task_id) => {
    axios.post(`/index.php`, {
      module: "livereport",
      action: "downloadPhotos",
      taskId: task_id
    })
      .then(function (response) {
        let file_name = response.data.path.split('/')
        const link = document.createElement('a')
        link.setAttribute('href', 'https://storus.store:732' + response.data.path.replace('/app', ''))
        link.setAttribute('download', file_name[file_name.length - 1])
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const downloadXLSX = (task_id) => {
    axios.post(`/index.php`, {
      module: "livereport",
      action: "downloadXLSX",
      taskId: task_id,
      selectedCity: selectedCity,
      searchValue: searchValue,
      selectedFormat: selectedFormat,
      selectedRegion: selectedRegion,
      currentTypeSort: currentTypeSort,
      selectedDay: selectedDay,
      orderBy: orderBy
    })
      .then(function (response) {
        let file_name = response.data.path.split('/')
        const link = document.createElement('a')
        link.setAttribute('href', `${API_BASE_URL.protocol}${API_BASE_URL.domain}:${API_BASE_URL.port}${response.data.path}`);
        link.setAttribute('download', file_name[file_name.length - 1])
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(function (error) {
        InfoBox("Error: " + error);
        console.error(error);
      });
  }

  function ConsecutivePhoto({ source, storeName }) {  //question type Open Consecutive
    const [onFocus, setFocus] = useState(false);

    return (
      <div className="relative w-full mx-1 my-1" onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)}>
        {storeName && onFocus && <span className="absolute top-0 left-0 bg-black bg-opacity-50 text-white px-2 py-1">{storeName}</span>}
        <LazyLoadImage onClick={() => console.log('заглуха')} alt="amg-ConsecutivePhoto" src={source} className="w-full h-64 object-cover cursor-pointer" />
      </div>
    )
  }


  return (
    <div className="flex-grow border p-3 m-3 bg-[#f8f8f8] rounded shadow ">
      <div className="flex flex-col gap-2 lg:gap-0 lg:flex-row justify-between  items-center mb-3">
        <span className='flex flex-wrap text-[14px] sm:text-[18px] font-bold text-[#1a73e8]'>{reportData[numQuests].question} ({reportData[numQuests].topic})</span>
        <div className={`flex maxWrap:flex-wrap  flex-row text-[12px] sm:text-[16px]  justify-evenly sm:justify-end items-center  maxWrap:gap-2 lg:gap-3 2xl:gap-10`}>
          {reportData[numQuests].type === 'photo' ?
            <>
              <button className={`p-2 border-0 rounded  w-auto mx-2 ${fullScreen && 'bg-[#1a73e8] text-[white]'} `} onClick={handleToggleFullScreen}>
                {fullScreen ? "Свернуть" : "Развернуть"}
              </button>
              {role === 'admin' &&
                <div className="p-2  border-0 rounded  w-auto mx-2 flex flex-row gap-1 items-center justify-self-center cursor-pointer" onClick={() => downloadPhoto(reportData[numQuests].task_id)}>
                  <span className="flex justify-center"> Скачать</span>
                  <button className="flex justify-center" > <BsDownload /></button>
                </div>
              }
            </> : currentTopic === 'Знание программы' && role === 'admin' ?
              <div className="p-2  border-0 rounded  w-auto mx-2 flex flex-row gap-1 items-center justify-self-center cursor-pointer" onClick={() => downloadXLSX(reportData[numQuests].task_id)}>
                <span className="flex justify-center"> Выгрузить XLSX</span>
                <button className="flex justify-center" > <BsDownload /></button>
              </div> : <></>}
          <Link to="/liveReport/MoreDetails">
            <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" onClick={() => dispatch(setQustionNumber(numQuests))}>Подробнее</button>
          </Link>
        </div>


      </div>
      {renderAnswers()}
    </div>
  );
});

export default QuestionCart;
