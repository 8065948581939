import React from 'react'

const InfoWidget = ({storeName, storeCode, storeCity, finishTime}) => {
    return (
        <div className="flex h-[17%] flex-col w-full text-[16px]">
            <div className=" flex flex-row w-full justify-between">
                <h5 >Название магазина</h5>
                <p className="font-bold">{storeName}</p>
            </div>
            <div className=" flex flex-row w-full justify-between">
                <h5 >Код магазина</h5>
                <p className="font-bold">{storeCode}</p>
            </div >
            <div className=" flex flex-row w-full justify-between">
                <h5 >Город</h5>
                <p className="font-bold">{storeCity}</p>
            </div>
            <div className=" flex flex-row w-full justify-between">
                <h5 >Дата</h5>
                <p className="font-bold">{finishTime}</p>
            </div>
        </div>
    )
}

export default InfoWidget
