import $ from 'jquery';
var infoWindowStopTimer = null;
$().ready(function () {
    // pinger to prevent session destroy
    setTimeout(function () {
        $.getJSON("/?ping");
    }, 60000);  // ping every minute
});

export function InfoBox(message) {
    if (infoWindowStopTimer === null) {
        _InfoBoxStart(message);
    } else {
        clearTimeout(infoWindowStopTimer);
        $("#infowindow").append("<p>" + message + "</p>");
    }
    infoWindowStopTimer = setTimeout(_InfoBoxEnd, 5000);
}

function _InfoBoxStart(message) {
    const $infoWindow = $("<div>", {
        id: "infowindow",
        class: "alert alert-info",
        style: "position: fixed; top: 0; left: 50%; transform: translateX(-50%); padding: 15px; text-align: center; opacity: 0; transition: opacity 0.2s, width 0.2s",
    }).append(
        $("<span>", {
            class: "close-button",
            style: "position: absolute; top: 2px; right: 2px; cursor: pointer; font-size: 9px; line-height: 1; padding: 3px;",
            html: "&#10006;",
            click: _InfoBoxEnd,
        }),
        $("<div>", { style: "position: relative;" }).append(
            $("<p>").text(message)
        )
    );

    $("body").append($infoWindow);
    const width = $infoWindow.width();
    $infoWindow.css({ width: width, maxWidth: "90%" });
    setTimeout(() => $infoWindow.css("opacity", 1), 10);
}

function _InfoBoxEnd() {
    const $infoWindow = $("#infowindow");
    $infoWindow.css("opacity", 0);
    setTimeout(() => {
        $infoWindow.remove();
        infoWindowStopTimer = null;
    }, 200);
}