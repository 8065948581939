export const locationTasks = [
    { value: 'ru', label: 'Русский', color: '#00B8D9', isDisabled: true, isFixed: true},
    { value: 'kz', label: 'Қазақша', color: '#0052CC', isDisabled: false },
  ];


export const DaysWeek = [
  { value: 'Понедельник', label: 'Понедельник', color: '#0052CC', isDisabled: false },
  { value: 'Вторник', label: 'Вторник', color: '#0052CC', isDisabled: false },
  { value: 'Среда', label: 'Среда', color: '#0052CC', isDisabled: false },
  { value: 'Четверг', label: 'Четверг', color: '#0052CC', isDisabled: false },
  { value: 'Пятница', label: 'Пятница', color: '#0052CC', isDisabled: false },
  { value: 'Суббота', label: 'Суббота', color: '#0052CC', isDisabled: false },
  { value: 'Воскресенье', label: 'Воскресенье', color: '#0052CC', isDisabled: false },
];
