import React from 'react'
import { BsArrowsAngleExpand } from "react-icons/bs";
import Editor from '../../../assets/SelectionMarks/DrawingLabels';
import { LazyLoadImage } from "react-lazy-load-image-component";

const PhotoСontainer = ({task_id, store_id, image,storeCode, storeCity, setFullScreenOutput, photoTags, published}) => {
    return (
        <>
            <div id='ModalPhotoContainer' className="h-[94%] w-full relative" >
                <Editor task_id={task_id} store_id={store_id} modalPhotos={image} photoTags={photoTags} published={published} />
                <LazyLoadImage
                    alt="amg"
                    src={image}
                    className=" absolute inset-0 w-full h-full cursor-zoom-in rounded-t-lg"
                />
                <div
                    className="absolute top-0 left-0 p-2 z-[100] cursor-pointer rounded-2xl bg-white bg-opacity-75 transform rotate-90"
                    onClick={() => setFullScreenOutput({
                        output: true,
                        src: image
                    }
                    )}>
                    <BsArrowsAngleExpand className="text-black font-bold" />
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex flex-row w-full text-white bg-[#1a73e8] h-[6%] justify-between items-center px-2 rounded-b-lg text-[18px]">
                <span>{storeCode}</span>
                <span>{storeCity}</span>
            </div>
        </>
    )
}

export default PhotoСontainer
