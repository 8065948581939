import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { InfoBox } from "../../cms_core";
import InputMask from 'react-input-mask';
import { BiSort } from "react-icons/bi";
import { BiSolidShow } from "react-icons/bi";
export function StoreCreation({ userId, isEditing }) {

    const Timezones = Intl.supportedValuesOf("timeZone").map((t) => {return t.toString()});

    const [errors, setErrors] = useState({});
    const [divisions, setDivisions] = useState([]);
    const [formats, setFormats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [phone, setPhone] = useState('');
    const [sortOrder, setSortOrder] = useState([])
    const [sortOrderType, setSortOrderType] = useState('asc');
    const [currentStore, setCurrentStore] = useState({});
    const [programs, setPrograms] = useState([]);
    const [password, setPassword] = useState(false);
    const [isTester, setIsTester] = useState(false);
    const [maxHeight, setMaxHeight] = useState('710px');
    const [activeElem, setActiveElem] = useState(0)
    const rgbColor = 'rgb(13, 110, 253)';
    const [activeSortColumn, setActiveSortColumn] = useState('');
    const [timezone, setTimezone] = useState('');

    useEffect(() => {
        const updateMaxHeight = () => {
            const screenHeight = window.innerHeight;
            const newMaxHeight = `${screenHeight * 0.83}px`;
            setMaxHeight(newMaxHeight);
        };

        window.addEventListener('resize', updateMaxHeight);
        updateMaxHeight();

        return () => {
            window.removeEventListener('resize', updateMaxHeight);
        };
    }, []);

    useEffect(() => {
        (async function () {
            let programsData;
            await axios.post(`/index.php`, {
                module: "loyaltyprogram",
                action: "getAll"
            })
                .then(function (response) {
                    setPrograms(response.data.loyaltyPrograms);
                    programsData = response.data.loyaltyPrograms;
                    setLoading(false);
                })
                .catch(function (error) {
                    InfoBox("Error in getAllPrograms: " + error);
                    console.error(error);
                });

            // await axios.post(/index.php, {
            //     module: "store",
            //     action: "getDivisionsAndFormats",
            //     programId: programsData[1].id
            // })
            //     .then(function (response) {

            //         setLoading(false);
            //         getProgramDivisions()
            //     })
            //     .catch(function (error) {
            //         InfoBox("Error in getDivisionsAndFormats: " + error);
            //         console.error(error);
            //     });
        })();
    }, []);

    useEffect(() => {
        if (!loading) {
            ////////// настроить geocode-maps для react-select под !isEditing
            if (isEditing) {
                (async function () {
                    let storesData;
                    await axios.post(`/index.php`, {
                        module: "store",
                        action: "getStores"
                    })
                        .then(function (response) {
                            setStores(response.data);
                            setSortOrder(response.data)
                            storesData = response.data;
                            onVariantSelect('all');
                            setErrors({});
                        })
                        .catch(function (error) {
                            InfoBox("Error in getStores: " + error);
                            console.error(error);
                        });

                    // await axios.post(/index.php, {
                    //     module: "store",
                    //     action: "getDivisionsAndFormats",
                    //     programId: storesData[0].program
                    // })
                    //     .then(function (response) {
                    //         // setDivisions(response.data.divisions);
                    //         // setFormats(response.data.formats);
                    //     })
                    //     .catch(function (error) {
                    //         InfoBox("Error in getDivisionsAndFormats: " + error);
                    //         console.error(error);
                    //     });
                    // const programIndex = programs.findIndex((program) => program.id === storesData[0].program);
                    // document.getElementById('store-programs').value = programIndex.toString();
                    storesData.map(item => {
                        fillStoreForm(item, item.id);
                    })
                })();
            } else {
                setSortOrder([])
                setStores([]);
                setErrors({});
                setActiveElem(0)
                onVariantSelect(10)
                setIsTester(false);
                setTimezone('');
            }
        }
    }, [isEditing, loading]);

    function onSubmitClick(id, index) {
        let fields = ["address", "area", "city", "code", "division", "format", "timezone", "name", "phone", "email", "password"];
        if (validateStoreFields(fields, id, index)) {

            if (!isEditing) {

                let newStore = {
                    address: document.getElementById(`address_${id}`).value,
                    isTest: isTester ? "1" : "0",
                    area: document.getElementById(`area_${id}`).value,
                    city: document.getElementById(`city_${id}`).value,
                    code: document.getElementById(`code_${id}`).value,
                    timezone: document.getElementById(`timezone_${id}`).value || null,
                    division_id: document.getElementById(`division_${id}`).value,
                    format_id: document.getElementById(`format_${id}`).value,
                    name: document.getElementById(`name_${id}`).value,
                    phone: document.getElementById(`phone_${id}`).value,
                    email: document.getElementById(`email_${id}`).value,
                    password: document.getElementById(`password_${id}`).value
                }

                

                axios.post(`/index.php`, {
                    module: "store",
                    action: "create",
                    storeInfo: newStore
                })
                    .then(function (response) {
                        if (response.data.erorr) {
                            InfoBox("Такой Email уже зарегистрирован!");
                        } else {
                            InfoBox("Магазин успешно создан");
                            window.location.reload(true)
                        }
                        // document.getElementById('storeForm').reset();
                        ;

                    })
                    .catch(function (error) {
                        InfoBox("Ошибка создания магазина: " + error);
                        console.error(error);
                    });
            } else {
                axios.post(`/index.php`, {
                    module: "store",
                    action: "update",
                    id: id,
                    address: document.getElementById(`address_${id}`).value,
                    isTest: document.getElementById(`isTesterCheckbox_${id}`).checked ? '1' : '0',
                    area: document.getElementById(`area_${id}`).value,
                    city: document.getElementById(`city_${id}`).value,
                    code: document.getElementById(`code_${id}`).value,
                    timezone: document.getElementById(`timezone_${id}`).value || null,
                    division_id: document.getElementById(`division_${id}`).value,
                    format_id: document.getElementById(`format_id_${id}`).value,
                    name: document.getElementById(`name_${id}`).value,
                    phone: document.getElementById(`phone_${id}`).value,
                    email: document.getElementById(`email_${id}`).value == stores[index].s_email ? null : document.getElementById(`email_${id}`).value,
                    password: document.getElementById(`password_${id}`).value
                })
                    .then(function (response) {
                        if (response.data.erorr) {
                            InfoBox("Такой Email уже используется");
                        } else {
                            InfoBox("Магазин успешно обновлен");
                        }
                        // document.getElementById('storeForm').reset();
                        //window.location.reload(true);

                    })
                    .catch(function (error) {
                        InfoBox("Ошибка обновления магазина: " + error);
                        console.error(error);
                    });
            }
        }
    }
    function fillStoreForm(selectedStore, id) {
        if (!loading) {
            document.getElementById(`address_${id}`).value = selectedStore.address;
            document.getElementById(`area_${id}`).value = selectedStore.area;
            document.getElementById(`city_${id}`).value = selectedStore.city;
            document.getElementById(`code_${id}`).value = selectedStore.code;
            document.getElementById(`timezone_${id}`).value = selectedStore.timezone;
            // document.getElementById(isTesterCheckbox_${id}).checked = selectedStore.is_tester == 1 ? true : false;
            document.getElementById(`name_${id}`).value = selectedStore.name;
            document.getElementById(`phone_${id}`).value = selectedStore.phone;
            document.getElementById(`email_${id}`).value = selectedStore.s_email;
        }
    }

    function validateStoreFields(fields, id) {
        const newErrors = {};
        let isValid = true;

        for (const fieldName of fields) {
            const inputValue = document.querySelector(`#${fieldName}_${id}`).value;
            if (!inputValue || inputValue.trim() == "") {
                switch (fieldName) {
                    case "address":
                        newErrors[fieldName] = "Введите адрес";
                        isValid = false;
                        break;
                    case "area":
                        newErrors[fieldName] = "Введите площадь";
                        isValid = false;
                        break;
                    case "city":
                        newErrors[fieldName] = "Введите город";
                        isValid = false;
                        break;
                    case "code":
                        newErrors[fieldName] = "Введите код";
                        isValid = false;
                        break;
                    case "name":
                        newErrors[fieldName] = "Введите название";
                        isValid = false;
                        break;
                    case "phone":
                        newErrors[fieldName] = "Введите телефон полностью";
                        isValid = false;
                        break;
                    case "email":
                        newErrors[fieldName] = "Введите логин от аккаунта";
                        isValid = false;
                        break;
                    case "password":
                        if (!isEditing) {
                            newErrors["password"] = "Введите пароль";
                            isValid = false;
                        }
                }

            } else if (fieldName === "phone" && inputValue.replace(/\D/g, "").length < 11) {
                newErrors["phone"] = "Введите телефон полностью";
                isValid = false;
            } else if (fieldName === "password" && inputValue.length < 8) {
                
                if (!isEditing) {
                    newErrors["password"] = "Пароль должен содержать не менее 8 символов";
                    isValid = false;
                } else {
               
                    if (inputValue != '') {
                       
                        newErrors["password"] = "Пароль должен содержать не менее 8 символов";
                        isValid = false;
                    }
                }
                
            }
        }

        //let allErrors = "";
        for (const error in newErrors) {
            InfoBox(newErrors[error])
        }
        if (!isEditing)
            setErrors(newErrors);
        return isValid;
    }

    const onVariantSelect = (event) => {
        let program = event;
        axios.post(`/index.php`, {
            module: "store",
            action: "getDivisionsAndFormats",
            programId: program
        })
            .then(function (response) {
                setDivisions(response.data.divisions);
                setFormats(response.data.formats);
            })
            .catch(function (error) {
                InfoBox("Error in getDivisionsAndFormats: " + error);
                console.error(error);
            });
    };

    const onTimezoneSelect = (value) => {
        setTimezone(value);
    }


    // const getProgramDivisions = (program = 'all') => {
    //     let ans = []
    //     axios.post(/index.php, {
    //         module: "store",
    //         action: "getDivisionsAndFormats",
    //         programId: program
    //     })
    //         .then(function (response) {
    //             setDivisions(response.data.divisions)
    //             setFormats(response.data.formats);
    //         })
    //         .catch(function (error) {
    //             InfoBox("Error in getDivisionsAndFormats: " + error);
    //             console.error(error);
    //         });
    // }
    const handlePhoneChange = (value) => {
        // let inputPhone = value.replace(/\D/g, '');
        // inputPhone = inputPhone.length == 0 ? +7 : '';
        // if(inputPhone> 12){
        //     inputPhone = inputPhone.slice(0, -1);  
        // }
        // setPhone(inputPhone)
    };


    const buttonStyle = {
        backgroundColor: "#007bff",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer"
    };

    const formStyle = {
        display: 'flex !important',
        padding: "10px",
        marginBottom: "10px"
    };

    const labelStyle = {
        display: "block",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };
    const passwordInputContainer = {
        position: "relative"
    }

    const togglePasswordBtn = {
        position: "absolute",
        top: "50%",
        right: "5px",
        transform: "translateY(-50%)",
        background: "none",
        border: "none",
        cursor: "pointer"
    }

    const inputStyle = {
        display: "flex",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const handleSort = (field) => {
        let sortedArray = [];

        if (field == 'timezone') {
            sortedArray = [...stores].sort((a, b) => {
                const valueA = a[field]
                const valueB = b[field]

                return simpleSortAB(valueA, valueB)
            })
        } else {
            sortedArray = [...stores].sort((a, b) => {
                const valueA = a[field] ? a[field].toUpperCase() : a[field];
                const valueB = b[field] ? b[field].toUpperCase() : b[field];

                if (sortOrderType == 'asc') {
                    if (valueA < valueB) {
                        return -1
                    }
                    if (valueA > valueB) {
                        return 1
                    }
                    return 0;
                } else {
                    if (valueA < valueB) {
                        return 1
                    }
                
                    if (valueA > valueB) {
                        return -1
                    }
                
                    return 0;
                }

            })
        }
        setStores(sortedArray);

        let activeField = field;
        
        switch (field) {
            case 'program_name':
                activeField = 'program'
                break;
            case 'format_name':
                activeField = 'format_id'
                break;
            case 'division_name':
                activeField = 'division_id'
                break;
            default:
                break;
        }
        
        setActiveSortColumn(activeField);
        setSortOrderType(sortOrderType === 'asc' ? 'desc' : 'asc');
    }

    const simpleSortAB = (valueA, valueB) => {

        if (valueA && valueB) {
            if (sortOrderType === 'asc') {
                return valueA.localeCompare(valueB); 
            } else {
                return valueB.localeCompare(valueA);
            }
        } else if (!valueA) {
            if (sortOrderType === 'asc') {
                return -1;
            } else {
                return 1;
            }
        } else {
            if (sortOrderType === 'asc') {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const handleClick = (id) => {
        if (activeElem == 0) {
            setActiveElem(id);
        }
        else if (id != activeElem && activeElem != 0) {
            setActiveElem(id);
        }
    };

    const handleInputChange = (event) => {
        // Получаем значение инпута
        let inputValue = event.target.value;
    
        // Получаем позицию курсора
        let caretPos = event.target.selectionStart;
    
        // Проверяем, нажата ли клавиша пробела и позиция курсора не в начале строки
        if (event.key === ' ' && caretPos > 0) {
            // Предотвращаем стандартное поведение браузера для клавиши пробела
            event.preventDefault();
    
            // Если перед курсором не пробел и не начало строки
            if (inputValue[caretPos - 1] !== ' ' && caretPos !== 1) {
                // Вставляем пробел в строку на позицию курсора
                inputValue = inputValue.slice(0, caretPos) + ' ' + inputValue.slice(caretPos);
    
                // Устанавливаем новое значение в инпут
                event.target.value = inputValue;
    
                // Сдвигаем позицию курсора на 1 вправо
                event.target.setSelectionRange(caretPos + 1, caretPos + 1);
            }
        }
    };
    const inputRef = useRef(null);
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (inputRef.current && !inputRef.current.contains(event.target)) {
    //             setActiveElem(null);
    //         }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);




    return loading ? (
        <>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </>
    ) : (
        <>
            <div className="col border p-3 m-3 bg-white rounded shadow  " style={{ marginLeft: '100px' }}>
                {isEditing ?
                    <div className="table-responsive" style={{ overflow: "scroll", maxHeight, overflowY: 'auto' }}>
                        <form>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('name')}>
                                            Наименование магазина
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'name' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('program_name')}>
                                            Программа лояльности
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'program' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('is_tester')}>
                                            Тестовый магазин
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'is_tester' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('address')}>
                                            Адресс
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'address' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('area')}>
                                            Площадь
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'area' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('city')}>
                                            Город
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'city' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('timezone')} >
                                            Часовой пояс
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'timezone' ? <BiSort color={rgbColor}/> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('code')}>
                                            Код
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'code' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('division_name')}>
                                            Дивизион
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'division_id' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }} onClick={() => handleSort('format_name')}>
                                            Формат
                                            <span style={{ cursor: 'pointer' }}>{activeSortColumn == 'format_id' ? <BiSort color={rgbColor} /> : <BiSort />}</span>
                                        </th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Телефон</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Логин</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Пароль</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        stores.map((item, index) => {

                                            return <tr key={item.id} id={`store_${item.id}`} onClick={() => handleClick(item.id)} style={{ backgroundColor: activeElem == item.id ? 'yellow' : 'white' }} >
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className="form-group">
                                                        <input style={inputStyle} type="text" id={`name_${item.id}`} defaultValue={item.name} name="Name" className="form-control" placeholder={item.name} />
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }} key={item.id}>
                                                    <select
                                                        style={{
                                                            ...inputStyle,
                                                            width: 'fit-content',
                                                            maxWidth: '76px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        id={`format_${item.id}`}
                                                        name="Format"
                                                        className="form-control"
                                                    >
                                                        {programs.map((program, index) => {
                                                            if (program.id == item.program) {
                                                                return <option key={index} selected value={program.name}>{program.name}</option>
                                                            }
                                                            else {
                                                                return <option key={index} value={program.name}>{program.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                </td>
                                                <td style={{ textAlign: 'center' }} key={item.id}>
                                                    <input
                                                        key={item.id}
                                                        type="checkbox"
                                                        id={`isTesterCheckbox_${item.id}`}
                                                        defaultChecked={item.is_tester == 1}
                                                        onChange={(e) => !e.target.checked}
                                                    />

                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}><input style={inputStyle} type="address" id={`address_${item.id}`} name="Address" className="form-control" placeholder={item.address} defaultValue={item.address}
                                                    ref={inputRef} /></td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>  <input style={inputStyle} type="text" id={`area_${item.id}`} name=" " className="form-control" placeholder={item.area} defaultValue={item.area}
                                                    ref={inputRef} /></td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    <input style={inputStyle} type="text" id={`city_${item.id}`} name="City" className="form-control" placeholder={item.city} defaultValue={item.city}
                                                        ref={inputRef} />
                                                </td>
                                                <td style={{ textAlign: 'center' }} key={item.id}>
                                                    <select
                                                        style={{
                                                            ...inputStyle,
                                                            width: 'fit-content',
                                                            maxWidth: '125px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        id={`timezone_${item.id}`}
                                                        name="timezone"
                                                        className="form-control"
                                                    >
                                                        <option selected></option>
                                                        {Timezones.map((t, index) => {
                                                            return <option selected={t === item?.timezone} key={index}>{t}</option>
                                                        })}
                                                    </select>
                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    <input style={inputStyle} type="text" id={`code_${item.id}`} name="Code" className="form-control" placeholder={item.code} defaultValue={item.code}
                                                        ref={inputRef} />
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <select
                                                        style={{
                                                            ...inputStyle,
                                                            width: 'fit-content',
                                                            maxWidth: '100px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        id={`division_${item.id}`}
                                                        name="Division"
                                                        className="form-control"
                                                        ref={inputRef}
                                                    >
                                                        {divisions.map((division, index) => (
                                                            division.loyalty_programs_id == item.program ? (
                                                                (division.id == item.division_id ? (
                                                                    <option key={division.id} selected value={division.id}>
                                                                        {division.name}
                                                                    </option>
                                                                ) : (
                                                                    <option key={division.id} value={division.id}>
                                                                        {division.name}
                                                                    </option>
                                                                ))
                                                            ) : ''
                                                        ))}
                                                    </select>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>


                                                    <select
                                                        style={{
                                                            ...inputStyle,
                                                            width: 'fit-content',
                                                            maxWidth: '100px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        id={`format_id_${item.id}`}
                                                        name="Format"
                                                        className="form-control"
                                                        ref={inputRef}
                                                    >
                                                        {formats.map((format, index) => (
                                                            format.loyalty_programs_id == item.program ? (
                                                                format.id == item.format_id ? (
                                                                    <option key={format.id} selected value={format.id}>
                                                                        {format.name}
                                                                    </option>
                                                                ) : (
                                                                    <option key={format.id} value={format.id}>
                                                                        {format.name}
                                                                    </option>
                                                                )
                                                            ) : ''))}
                                                    </select>
                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    {/* <input style={inputStyle} type="phone" id={`phone_${item.id}`} name="Phone" className="form-control" placeholder={item.phone} defaultValue={item.phone} onClick={() => handleInputChange(item.id)} ref={inputRef} /> */}
                                                    <InputMask style={inputStyle} id={`phone_${item.id}`} name="Phone" placeholder="+7(___) ___-__-__" mask="+7(999) 999-99-99" className="form-control" defaultValue={item.phone} ref={inputRef} />
                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    <input style={inputStyle} type="email" id={`email_${item.id}`} name="Email" className="form-control" placeholder={item.s_email} defaultValue={item.s_email} 
                                                        ref={inputRef} />

                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    <input style={inputStyle} type="password" id={`password_${item.id}`} name="Password"
                                                        className="form-control" 
                                                        ref={inputRef} />
                                                </td>
                                                <td key={item.id} style={{ textAlign: 'center' }}>
                                                    <button style={buttonStyle} type="button" onClick={() => onSubmitClick(item.id, index)}>Сохранить</button>
                                                </td>
                                            </tr>
                                        }
                                        )
                                    }

                                </tbody>
                            </table>
                        </form>
                        {Object.keys(errors).map(fieldName => (
                            <div key={fieldName} className="error-message">
                                <span style={{ color: "red" }}>
                                    {errors[fieldName]}
                                </span>
                            </div>
                        ))}
                    </div >
                    :
                    <form style={formStyle} id="storeForm">
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="name">Программа лояльности:</label>
                            <select style={inputStyle} id="store-programs_new" name="Store-Programs" className="form-control" onChange={(e) => onVariantSelect(e.target.value)}>
                                {programs.map((program, index) => (
                                    <option key={"store-programs" + program.id} value={program.id}>{program.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="isTester">Тестовый магазин: </label>
                            <input
                                type="checkbox"
                                id="isTesterCheckbox_new"
                                checked={isTester}
                                onChange={() => setIsTester(!isTester)}
                            />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="address">Адрес:</label>
                            <input style={inputStyle} type="address" id="address_new" name="Address" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="area">Площадь:</label>
                            <input style={inputStyle} type="text" id="area_new" name="Area" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="city">Город:</label>
                            <input style={inputStyle} type="text" id="city_new" name="City" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="timezone">Часовой пояс:</label>
                            <select style={inputStyle} id="timezone_new" name="Timezone" className="form-control" onChange={(e) => onTimezoneSelect(e.target.value)}>
                                <option selected>{null}</option>
                                {Timezones.map((timeZone) => (
                                    <option key={timeZone} value={timeZone}>{timeZone}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="code">Код:</label>
                            <input style={inputStyle} type="text" id="code_new" name="Code" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="division">Дивизион:</label>
                            <select style={inputStyle} id="division_new" name="Division" className="form-control">
                                {divisions.map((program, index) => (
                                    <option key={program.id} value={program.id}>{program.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="format">Формат:</label>
                            <select style={inputStyle} id="format_new" name="Format" className="form-control">
                                {formats.map((program, index) => (
                                    <option key={program.id} value={program.id}>{program.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="name">Название:</label>
                            <input style={inputStyle} type="text" id="name_new" name="Name" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="phone">Телефон:</label>
                            <InputMask style={inputStyle} 
                            id="phone_new" 
                            name="Phone" 
                            placeholder="+7(___) ___-__-__" 
                            mask="+7(999) 999-99-99" 
                            className="form-control" 
                           // onKeyDown={(e) => { if (e.key === ' ') e.preventDefault(); }} 
                            />
                        </div>
                        <h8><strong>Данные от аккаунта:</strong></h8>
                        <h3> </h3>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="email">Логин:</label>
                            <input style={inputStyle} type="email" id="email_new" name="Email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="password">Пароль:</label>
                            <div style={passwordInputContainer} >
                                <input style={inputStyle} type={password ? 'text' : 'password'} id="password_new" name="Password"
                                    className="form-control" />
                                <BiSolidShow style={togglePasswordBtn} color={!password ? rgbColor : ''} onClick={() => setPassword(!password)} />
                            </div>
                        </div>
                        <button style={buttonStyle} type="button" onClick={() => onSubmitClick('new')}>Сохранить</button>
                        {Object.keys(errors).map(fieldName => (
                            <div key={fieldName} className="error-message">
                                <span style={{ color: "red" }}>
                                    {errors[fieldName]}
                                </span>
                            </div>
                        ))}
                    </form>
                }
            </div>
        </>
    );
}
