import React, { useState, useEffect, memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { setSwitchingCriteria } from '../../store/slices/liveReportSlice';
import { useAuth } from '../../hooks/useAuth';
import CloseTab from '../ui/CloseTab';
import PhotoСontainer from './components/PhotoСontainer';
import InfoWidget from './components/InfoWidget';
import CriteriaWidget from './components/CriteriaWidget';
import Comments from './components/Comments';
import ButtonSendData from './components/ButtonSendData';
import TabController from './components/TabController';


const LiveReportModal = ({ setFullScreenOutput }) => {
    const dispatch = useAppDispatch();
    const { role } = useAuth();

    const currentTest = useAppSelector(state => state.mainHeaderSlice.currentTest);
    const { modalVisible, currentModalPhoto, modalPhotos, criteria, published, task_id, photoTags } = useAppSelector(state => state.liveReportSlice);
    const [currentPhoto, setCurrentPhoto] = useState(currentModalPhoto);
    const currentPhotosArray = modalPhotos;

    useEffect(() => {
        function handleKeyDown(event) {
            if (modalVisible === true) {
                const textArea = document.getElementById('commentStore');
                if ((event.keyCode === 37) && (document.activeElement !== textArea)) { // Arrow Left
                    setCurrentPhoto(prev => clickToSwichPhotos(prev - 1) !== null ? prev - 1 : prev);
                } else if ((event.keyCode === 39) && (document.activeElement !== textArea)) { // Arrow Right
                    setCurrentPhoto(prev => clickToSwichPhotos(prev + 1) || prev) // не забыть оптимизировать эту логику
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const clickToSwichPhotos = (current) => {
        if (currentPhotosArray[current]) {
            dispatch(setSwitchingCriteria(current))
            return current;
        }
        return null
    }


    return (
        <div className=" flex justify-center  h-[100%] w-[100%] px-4 py-4 gap-4">
            <CloseTab />
            <div className='flex flex-col w-[43%] h-full'>
                <div className="flex w-full h-[93%]  rounded flex-col content-center relative">
                    <PhotoСontainer
                        task_id={task_id}
                        store_id={currentPhotosArray[currentPhoto].id}
                        photoTags={photoTags}
                        published={published}
                        image={currentPhotosArray[currentPhoto].image}
                        storeCode={currentPhotosArray[currentPhoto].storeCode}
                        storeCity={currentPhotosArray[currentPhoto].storeCity}
                        setFullScreenOutput={setFullScreenOutput} />
                </div>
                <div className='flex  h-[7%] flex-row  w-full items-end'>
                    <TabController
                        currentPhotosArray={currentPhotosArray}
                        currentPhoto={currentPhoto}
                        setCurrentPhoto={setCurrentPhoto}
                        clickToSwichPhotos={clickToSwichPhotos}
                    />
                </div>
            </div>
            <div className="flex w-1/2 flex-col h-full items-start">
                <h4 className="font-bold h-[8%] text-[26px] text-blue-500">Инфо</h4>
                <h5 className="font-bold h-[5%] text-[18px]">Вопрос</h5>
                <p className="h-[6%] text-[16px] overflow-hidden leading-tight overflow-ellipsis">{currentPhotosArray[currentPhoto].text}</p>
                {currentPhotosArray[currentPhoto].storeName && (
                    <InfoWidget
                        storeName={currentPhotosArray[currentPhoto].storeName}
                        storeCode={currentPhotosArray[currentPhoto].storeCode}
                        storeCity={currentPhotosArray[currentPhoto].storeCity}
                        finishTime={currentPhotosArray[currentPhoto].finishTime}
                    />
                )}
                <div className=' flex h-[34%] flex-col gap-3 w-full'>
                    <CriteriaWidget
                        role={role}
                        published={published}
                        criteria={criteria}
                        currentTest={currentTest.id}
                        store_id={currentPhotosArray[currentPhoto].id}
                    />
                </div>

                <div className='flex  h-[30%] flex-col w-full gap-2'>
                    <Comments
                        role={role}
                        published={published}
                        task_id={task_id}
                        store_id={currentPhotosArray[currentPhoto].id}
                        comment={currentPhotosArray[currentPhoto].commentStore} />
                    <span className='flex justify-end'>
                        {(published == 0 && role == 'admin') && <ButtonSendData />}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default LiveReportModal
