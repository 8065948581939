import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux';
import { useAuth } from '../../hooks/useAuth';

import {
  ShapeEditor,
  ImageLayer,
  DrawLayer,
  wrapShape,
} from 'react-shape-editor';
import { setChangesDrawing } from '../../store/slices/liveReportSlice';

function arrayReplace(arr, index, item) {
  return [
    ...arr.slice(0, index),
    ...(Array.isArray(item) ? item : [item]),
    ...arr.slice(index + 1),
  ];
}

const RectShape = wrapShape(({ width, height }) => (
  <rect width={width} height={height} fill="rgba(0,0,255,0)" stroke="red" strokeWidth="2" />
));

const Editor = ({ task_id = 0, store_id = 0, modalPhotos, photoTags, published = 1 }) => {
  const { role } = useAuth();
  const modalVisible = useAppSelector(state => state.liveReportSlice.modalVisible);
  const dispatch = useAppDispatch();
  const [items, setItems] = useState(photoTags || []);
  const [vectorWidth, setVectorWidth] = useState(0);
  const [vectorHeight, setVectorHeight] = useState(0);
  useEffect(() => {
    setItems(photoTags || [])
  }, [photoTags])

  useEffect(() => {
    const container = modalVisible ? document.getElementById('ModalPhotoContainer') : document.getElementById('ModalPhotoContainer_manager');
    if (!container) return;
    const handleResize = () => {
      setVectorWidth(container.clientWidth);
      setVectorHeight(container.clientHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let idIterator = 1;

  const handleDelete = (index) => {
    if (published == 1) return;
    setItems(prevItems => {
      const newItems = arrayReplace(prevItems, index, []);
      const updatedItems = newItems.map((item, idx) => {
        return {
          ...item,
          id: `id_${idx + 1}`,
        };
      });
      dispatch(setChangesDrawing({
        changeDrawing: updatedItems,
        task_id: task_id,
        store_id: store_id
      }));
      return updatedItems;
    });
  };

  function conversionTOPercentage(newRect) {
    const container = modalVisible ? document.getElementById('ModalPhotoContainer') : document.getElementById('ModalPhotoContainer_manager');
    const containerStartX = container.offsetLeft;
    const containerEndX = containerStartX + container.offsetWidth;
    const xOffsetPercentage = ((newRect.x - containerStartX) / (containerEndX - containerStartX)) * 100;

    const containerStartY = container.offsetTop;
    const containerEndY = containerStartY + container.offsetHeight;
    const yOffsetPercentage = ((newRect.y - containerStartY) / (containerEndY - containerStartY)) * 100;

    const widthOffsetPercentage = (newRect.width / container.offsetWidth) * 100;
    const heightOffsetPercentage = (newRect.height / container.offsetHeight) * 100;

    return { x: xOffsetPercentage, y: yOffsetPercentage, width: widthOffsetPercentage, height: heightOffsetPercentage }
  }

  function parsePercent(item, position) {
    const container = modalVisible ? document.getElementById('ModalPhotoContainer') : document.getElementById('ModalPhotoContainer_manager');
    if (container) {
      const containerRect = container.getBoundingClientRect();
      switch (position) {
        case 'x':
          return (parseFloat(item) / 100) * containerRect.width;
        case 'y':
          return (parseFloat(item) / 100) * containerRect.height;
        case 'width':
          return (parseFloat(item) / 100) * containerRect.width;
        case 'height':
          return (parseFloat(item) / 100) * containerRect.height;
        default:
          return null;
      }
    } else {
      console.error('Контейнер не найден');
      return null;
    }
  }

  return (
    <div className='w-full h-full absolute z-50'>
      <ShapeEditor
        vectorWidth={vectorWidth}
        vectorHeight={vectorHeight}
        style={{ width: '100%', height: '100%' }}
      >

        <ImageLayer
          src={modalPhotos}
          onLoad={({ naturalWidth, naturalHeight }) => {
            const containerWidth_1 = modalVisible ? document.getElementById('ModalPhotoContainer').clientWidth : document.getElementById('ModalPhotoContainer_manager').clientWidth;
            const containerHeight_1 = modalVisible ? document.getElementById('ModalPhotoContainer').clientHeight : document.getElementById('ModalPhotoContainer_manager').clientHeight;
            setVectorWidth(containerWidth_1);
            setVectorHeight(containerHeight_1);
          }}
          style={{ width: '100%', height: '100%', objectFit: "contain", display: "none" }}
        />

        {role !== 'admin' ? <DrawLayer /> :
          <DrawLayer
            onAddShape={({ x, y, width, height }) => {
              if (published == 1) return;
              setItems(prevItems => {
                const newRect = { x, y, width, height }
                const percentage = conversionTOPercentage(newRect);
                const newItem = { id: `id_${prevItems.length + 1}`, task_id: task_id, store_id: store_id, x: percentage.x, y: percentage.y, width: percentage.width, height: percentage.height };
                idIterator += 1;
                const updatedItems = [...prevItems, newItem];
                dispatch(setChangesDrawing({
                  changeDrawing: updatedItems,
                  task_id: task_id,
                  store_id: store_id
                }));
                return updatedItems;
              });
            }}
          />
        }
        {role !== 'admin' ?

          items.map((item, index) => (
            <RectShape
              key={item.id}
              shapeId={item.id}
              height={parsePercent(item.height, 'height')}
              width={parsePercent(item.width, 'width')}
              x={parsePercent(item.x, 'x')}
              y={parsePercent(item.y, 'y')}

            />
          ))
          :
          items.map((item, index) => (

            <RectShape
              key={item.id}
              shapeId={item.id}
              height={parsePercent(item.height, 'height')}
              width={parsePercent(item.width, 'width')}
              x={parsePercent(item.x, 'x')}
              y={parsePercent(item.y, 'y')}

              onChange={newRect => {
                if (published == 1) return;
                setItems(prevItems => {
                  const updatedItems = prevItems.map((item, i) => {
                    if (i === index) {
                      const percentage = conversionTOPercentage(newRect);
                      return { ...item, ...newRect, x: percentage.x, y: percentage.y, width: percentage.width, height: percentage.height };
                    }
                    return item;
                  });
                  dispatch(setChangesDrawing({
                    changeDrawing: updatedItems,
                    task_id: task_id,
                    store_id: store_id
                  }));
                  return updatedItems;
                });
              }}

              onDelete={() => handleDelete(index)}
            />
          ))
        }
      </ShapeEditor>
    </div>
  );
};

export default Editor;
