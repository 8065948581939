import { useEffect, useState } from "react"
import axios from "axios"
import { InfoBox } from "../cms_core";
import { Link, redirect } from "react-router-dom"
import { useAppSelector } from "../store/hooks/redux";
import { useAuth } from "../hooks/useAuth";
import { API_BASE_URL } from "../store/url/ProgressUrl";
import Sort from "../store/sorting/sortСlass";
import { BiSort } from "react-icons/bi";

export default function ManagerAssessment() {


    const { user_id, role } = useAuth();
    const { currentTest, currentProgram, selectedCity, selectedRegion, selectedFormat } = useAppSelector(state => state.mainHeaderSlice);
    const [isLoading, setIsLoading] = useState(true);
    const [managerInfo, setManagerInfo] = useState([]);
    const [topics, setTopics] = useState();
    const [filter, setFilter] = useState('');
    const [filtredData, setFiltredData] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc");
    const [activeSortColumn, setActiveSortColumn] = useState('code')
    const [isPublished, setIsPublished] = useState(1)
    const rgbColor = 'rgb(13, 110, 253)';
    const handleSorts = new Sort();

    useEffect(() => {
        (async function () {

            await axios.post(`/index.php`, {
                module: 'progress',
                action: 'getManagerInfo',
                testId: currentTest.id,
                userId: user_id,
                selectedCity: selectedCity,
                selectedRegion: selectedRegion.id,
                selectedFormat: selectedFormat
            })
                .then((response) => {
                    setManagerInfo(response.data.infoByStore);
                    setFiltredData(response.data.infoByStore);
                    setIsPublished(response.data.published);
                    setIsLoading(false)
                    if (response.data.topicTitle) {
                        setTopics(response.data.topicTitle)
                    }
                })
                .catch((error) => {
                    InfoBox("Error: " + error)
                    console.error(error)
                })
        })()
    }, [currentTest, selectedCity, currentProgram, selectedFormat, selectedRegion])

    useEffect(() => {
        (() => {
            filterData()
        })()
    }, [filter])


    const downloadDocument = async () => {
        try {
            const response = await axios.post(`/index.php`, {
                module: 'progress',
                action: 'downloadManagerExel',
                testId: currentTest.id,
                userId: user_id,
                selectedCity: selectedCity,
                selectedRegion: selectedRegion.id,
                selectedFormat: selectedFormat, // add search props
            });

            if (response.data && response.data.path) {
                const url = `${API_BASE_URL.protocol}${API_BASE_URL.domain}:${API_BASE_URL.port}${response.data.path}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'managerReport.xlsx');

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Server response does not contain a valid file path');
            }
        } catch (error) {
            InfoBox("Error: " + error);
            console.error(error);
        }
    };

    // useEffect(() => {

    // }, [filter])

    if (isLoading) {
        return (
            <>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </>
        )
    }

    function filterData() {
        let filtredArray = [];

        if (filter === '') {
            setFiltredData(managerInfo)
            return;
        }

        filtredArray = [...managerInfo].filter((item) => {
            return item.name.toLowerCase().includes(filter.toLowerCase())
        })

        setFiltredData(filtredArray)
    }

    ///////////////////////////// HANDLERS ////////////////////////////////

    function handleOnChangeFilter(value) {
        setFilter(value.toLowerCase())
    }

    //////////////////////////// ICONS /////////////////////////////////////

    function MdiFileDocumentOutline(props) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zm0 2h7v5h5v11H6zm2 8v2h8v-2zm0 4v2h5v-2z"></path></svg>
        )
    }


    function CodiconSearch(props) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}><path fill="rgb(59 130 246)" d="M15.25 0a8.25 8.25 0 0 0-6.18 13.72L1 22.88l1.12 1l8.05-9.12A8.251 8.251 0 1 0 15.25.01zm0 15a6.75 6.75 0 1 1 0-13.5a6.75 6.75 0 0 1 0 13.5"></path></svg>
        )
    }
    const handleSort = (field) => {
        setFiltredData(handleSorts.sortManagerInfo(field, filtredData, sortDirection));
        setSortDirection(prev => prev === 'asc' ? 'desp' : 'asc');
        setActiveSortColumn(field.field)
    }


    return (
        <div className="container">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h6 className="font-bold text-3xl">Оценка менеджера</h6>

                <div className="flex w-[40%] justify-between">
                    <div className="flex flex-auto flex-row w-[20%] justify-between items-center p-1 mr-2 !border-[1px] !border-gray-300 !rounded-md">
                        <input
                            className="border-0 pr-2 focus:outline-none"
                            type="text"
                            placeholder="Поиск"
                            onChange={(event) => handleOnChangeFilter(event.target.value)}
                        />
                        <CodiconSearch />
                    </div>
                    {(role === 'admin' && filtredData.length !== 0) &&
                        <button className="p-3 bg-blue-500 text-white rounded" onClick={() => downloadDocument()}><div className="flex flex-row"><MdiFileDocumentOutline /> Выгрузить статистику</div></button>
                    }
                </div>
            </div>
            {filtredData.length !== 0 ?
                <div className="flex items-center !w-full overflow-x-auto">
                    <table className="border-separate border-spacing-y-2 border-0 !w-full">
                        <thead>
                            <tr className="p-2">
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'code' })}>
                                    <div className="flex items-center justify-center">
                                        Код
                                        <BiSort color={activeSortColumn === 'code' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'name' })}>
                                    <div className="flex items-center justify-center">
                                        Магазин
                                        <BiSort color={activeSortColumn === 'name' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'city' })}>
                                    <div className="flex items-center justify-center">
                                        Город
                                        <BiSort color={activeSortColumn === 'city' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'finishTime' })}>
                                    Дата и время завершения<BiSort color={activeSortColumn === 'finishTime' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                </th>
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'efficiencyScore' })}>
                                    <div className="flex items-center justify-center">
                                        Оценка времени
                                        <BiSort color={activeSortColumn === 'efficiencyScore' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                {topics?.map((item) => {
                                    return <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ id: item.id, field: item.topic })}>
                                        <div className="flex items-center justify-center ">
                                            <span className="w-[90%]  flex justify-end">{item.topic}</span>
                                            <span className="w-[10%] flex ">
                                                <BiSort color={activeSortColumn === item.topic ? rgbColor : ''} style={{ fontSize: "14pt" }} />
                                            </span>
                                        </div>
                                    </th>
                                })}
                                <th scope="col" className="text-left p-4 tracking-wider cursor-pointer" onClick={() => handleSort({ field: 'finalGrade' })}>
                                    <div className="flex items-center justify-center">
                                        Итого
                                        <BiSort color={activeSortColumn === 'finalGrade' ? rgbColor : ''} style={{ fontSize: "auto" }} />
                                    </div>
                                </th>
                                <th scope="col" className="text-left p-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtredData.map((item, index) =>
                                <tr className="bg-[#F8F8F8]">
                                    <td className="text-left p-4 rounded-l-xl">{item.code}</td>
                                    <td className="text-left p-4">{item.name}</td>
                                    <td className="text-left p-4">{item.city}</td>
                                    <td className="text-left p-4">{item.finishTime}</td>
                                    <td className="text-left p-4">{item.efficiencyScore}</td>
                                    {topics?.map((topic, i) => {
                                        let hasReturn = false
                                        let returnData = ''
                                        if (item.topicInfo !== undefined) {
                                            item?.topicInfo.map((title) => {
                                                if (title?.topic_title === topic.topic && title !== undefined && title.points !== undefined) {
                                                    hasReturn = true
                                                    returnData = title.points
                                                }
                                            })
                                        }
                                        if (hasReturn === false) {
                                            return <th className="text-left p-4"></th>
                                        }
                                        return (<th className="text-left p-4 font-normal">{returnData}</th>)
                                    })}
                                    <td className="text-left p-4">{item.finalGrade}</td>
                                    <td className="text-left p-4  rounded-r-xl">
                                        {(role === 'admin') || (isPublished == 1) ?
                                            <Link to={`/ratingStore/${currentTest.id}/${item.store_id}`} className="border-0 text-blue-500 font-bold">
                                                Подробнее
                                            </Link>
                                            : <span className="border-0 text-gray-500 font-bold cursor-not-allowed">
                                                Подробнее
                                            </span>}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                : <div className='flex justify-center w-full '> <span className=' bg-[#f0f0f0] text-center text-[18pt] p-2 rounded-lg'>Ни один магазин еще не завершил тест  </span></div>}
        </div>
    )
}