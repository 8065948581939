import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../store/hooks/redux";
import { useAuth } from '../hooks/useAuth';
import { InfoBox } from "../cms_core";
import Editor from "../assets/SelectionMarks/DrawingLabels";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { reportDatas, setCurrentModalPhoto, setModalVisible, setPublished } from '../store/slices/liveReportSlice';
import { setCurrentTest } from "../store/slices/mainHeaderSlice";

export default function RatingStorePage() {
	const ChosenTypes = {
		chosen_right: 'CHOSEN_RIGHT',
		not_chosen: 'NOT_CHOSEN',
		chosen_wrong: 'CHOSEN_WRONG',
		without_evaluation: 'WITHOUT_EVALUATION'
	}

	const SingleOrMultiple = {
		single: 'SINGLE',
		multiple: 'MULTIPLE'
	}

	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const location = useLocation()
	const { role } = useAuth();
	const { id, test } = useParams();
	const currentTest = useAppSelector(state => state.mainHeaderSlice.currentTest)
	const published = useAppSelector(state => state.liveReportSlice.published);
	const modalVisible = useAppSelector(state => state.liveReportSlice.modalVisible);
	const [isLoading, setIsLoading] = useState(true);
	const [isAbleToEdit, setIsAbleToEdit] = useState(false);
	const [storeData, setStoreData] = useState();


	useEffect(() => {
		if (modalVisible === true) return
		getManagerMoreDeteils();
	}, [modalVisible])

	async function getManagerMoreDeteils() {
		await axios.post(`/index.php`, {
			module: "progress",
			action: "managerInfoMoreDetails",
			testId: test,
			storeId: id,
			role: role,
		})
			.then((response) => {
				setStoreData(() => response.data)
				dispatch(setPublished(response.data.published))
				//TO DO: подумать откуда нарыть паблишер старый
				dispatch(setCurrentTest({ id: test }));
				setIsLoading(() => false)
			})
			.catch((error) => {
				InfoBox('Error: ' + error)
				console.error(error);
			})
	}

	if (isLoading) {
		return (
			<>
				<svg className="spinner" viewBox="0 0 50 50">
					<circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
				</svg>
			</>
		)
	}

	//////////////////////////// HANDLERS //////////////////////////////////

	async function handleOnClickPublicate() {
		axios.post('/index.php', {
			module: "progress",
			action: "checkAndSendPublished",
			testId: test,
			storeId: id
		})
			.then((response) => {
				if (response.data.success === 200) {
					getManagerMoreDeteils();
					dispatch(setPublished(published == 1 ? 0 : 1));
				} else {
					InfoBox("403 forbidden")
				}
			})
			.catch((error) => {
				InfoBox(error)
			})
	}

	function handleOnClickEdit(item, topic) {
		dispatch(setModalVisible({
			call: 'managerRating',
			task_id: item.task_id,
			store_id: id,
			question: item.question,
			city: storeData.storeInfo.city,
			code: storeData.storeInfo.code,
			name: storeData.storeInfo.name,
			photo: item.answers,
			criteriaInfo: item.managerData.criteriaStoreInfo,
			photoTags: item.managerData.storePhotoTags,
			comment: item.managerData.commentStore,
			finishTime: item.finishTime,
			topic: topic
			// numQuests: 0 
		}))
	}

	//////////////////////////// COMPONENTS ////////////////////////////////


	const Variants = ({ item, type }) => {
		// const [hasWrong, setHasWrong] = useState(false)
		const no_evaluation = (item.right_answer[0] && item.right_answer[0] !== '') && true;
		const hasWrong = no_evaluation && item.answers.every(element => item.right_answer.some(answer => answer === element));
		return (
			<div className="bg-[#F8F8F8] rounded-lg size-full">
				<h4 className="text-[#1a73e8] text-xl p-2">{item?.question}</h4>
				<div className="flex flex-row rounded-lg bg-[#F8F8F8] w-full h-auto min-h-[70%] p-2 gap-2">
					<div className="size-full flex flex-col">
						<label className="font-bold">Ответ</label>
						<div className="flex flex-auto h-full flex-col gap-2">
							{item.answers_variants.map((item_variant, index) => { return <ShowAnswers key={index} item_variant={item_variant} right_answer={item.right_answer} answer={item.answers} no_evaluation={no_evaluation} /> })}
						</div>
					</div>
					<div className="min-w-[20%] flex flex-col justify-start text-center" >
						<label className="font-bold">Оценка</label>
						{!no_evaluation ?
							<label className="">Без оценивания</label> :
							!hasWrong ?
								<label className="text-[#eb6262]">Не верно</label> :
								<label className="text-[#a7e9a7]">Верно</label>}
					</div>
				</div>
			</div>


		)
	}
	const ShowAnswers = ({ right_answer, item_variant, answer, no_evaluation }) => {
		const correct = answer.some(ans => ans === item_variant);
		let type = ChosenTypes.not_chosen
		if ((right_answer && right_answer[0] !== '') && correct) {
			if (right_answer.some(ans => ans === item_variant)) {
				type = ChosenTypes.chosen_right
			}
			else {
				type = ChosenTypes.chosen_wrong
			}
		} else {
			if (correct) {
				type = ChosenTypes.without_evaluation
			} else {
				type = ChosenTypes.not_chosen
			}
		}

		switch (type) {
			case ChosenTypes.chosen_right:
				return <label className="p-2 border-[1px] border-[#a7e9a7] rounded mt-1 mb-1">{item_variant}</label>
			case ChosenTypes.chosen_wrong:
				return <label className="p-2 border-[1px] border-red-500 rounded mt-1 mb-1">{item_variant}</label>
			case ChosenTypes.without_evaluation:
				return <label className="p-2 border-[1px] border-[#1a73e8] rounded mt-1 mb-1">{item_variant}</label>
			default:
				return <label className="p-2 mt-1 mb-1">{item_variant}</label>
		}
	}


	const Mark = ({ title, width }) => {
		return (
			<div className="bg-[#F8F8F8] rounded-lg p-2 min-w-[20%]">
				<div className="flex flex-auto flex-col p-2">
					<label className="p-2 font-bold">{title}</label>
					<div className="flex flex-row items-center">
						<div className="flex bg-gray-200 rounded-xl items-start !h-[10px] !w-[70%]">
							<div
								// className="bg-blue-500 w-[90%] h-full rounded-xl"
								style={{ backgroundColor: "rgb(59 130 246)", width: `${width}%`, height: "100%", borderRadius: "12px" }}
							/>
						</div>
						<label className="p-2 text-xl font-extrabold">{width}%</label>
					</div>
				</div>
			</div>
		)
	}

	/////////////////////// TYPES ///////////////////////////////////


	const PhotoType = ({ item, index, topic }) => {
		return (
			<div key={index} className="flex flex-row bg-[#F8F8F8] rounded-lg h-full w-full p-4 gap-2">
				<div id='ModalPhotoContainer_manager' className="h-[526px] w-[390px] relative " onClick={() => role === 'store' && handleOnClickEdit(item, topic)} >
					{!modalVisible && <Editor modalPhotos={item.answers} photoTags={item?.managerData?.storePhotoTags} />}
					<LazyLoadImage
						alt="amg"
						src={item.answers}
						className=" absolute inset-0 w-full h-full cursor-zoom-in rounded-lg"
					/>
				</div>
				<div className="w-[50%] h-full">
					<PhotoEvaluatedData criteriaStoreInfo={item.managerData?.criteriaStoreInfo} commentStore={item.managerData?.commentStore} item={item} topic={topic} />
				</div>
			</div>
		)
	}


	const PhotoEvaluatedData = ({ criteriaStoreInfo, commentStore, item, topic }) => {
		return <div className="w-full h-full flex flex-col gap-2">
			<h3 className="text-[#1a73e8] text-lg font-semibold h-[10%]">Оценка</h3>
			<div className={`h-auto flex flex-col w-full ${criteriaStoreInfo.length > 4 ? 'overflow-y-scroll' : ''}`}>
				{criteriaStoreInfo.length === 0 ? <span>Критерии не назначенны</span> :
					criteriaStoreInfo.map((criteria) => {
						return (
							<div key={criteria.id} className="flex flex-row justify-between p-2 w-full max-h-[45px]">
								<label className="flex overflow-ellipsis">{criteria.criteria_title}</label>
								<div className="min-h-[32px] min-w-[32px] rounded text-center text-xl bg-[#1a73e8] text-white">{criteria.rating}</div>
							</div>
						);
					})}
			</div>

			<div className="flex flex-col h-[35%] justify-end">
				<div className="flex flex-col gap-1 h-[75%]">
					<div className="text-[#1a73e8] text-lg font-semibold">Комментарий :</div>
					<label className="">{commentStore || 'Комментарий не назначен администратором!'}</label>
				</div>
				{(role === 'admin' && published == 0) && (
					<div className="flex items-end justify-end h-[25%]">
						<button className="rounded bg-[#1a73e8] text-white text-xl p-2" onClick={() => handleOnClickEdit(item, topic)}>Редактировать</button>
					</div>
				)}
			</div>
		</div>
	}

	const MultipleAndSingleType = ({ item, type }) => {
		return (
			<Variants item={item} type={type} />
		)
	}

	const OpenType = ({ item }) => {
		return <div className="flex flex-col gap-2 p-2  bg-[#F8F8F8] rounded-lg h-full w-full ">
			<h3 className="flex font-bold text-[22px] text-[#1a73e8] justify-center">{item.question}</h3>
			<label className="flex justify-start font-bold">Ответ : </label>
			<span className="rounded-lg border-[1px] border-[#1a73e8] p-2 text-[13pt]">{item.answers}</span>
		</div>
	}

	const MiltipleNoRightAnswerType = ({ item }) => {
		return (
			<div className="bg-[#F8F8F8] rounded-lg">
				<h4 className="text-[#1a73e8] text-xl p-2">{item?.question}</h4>
				<div className="flex flex-auto flex-wrap p-2 gap-2">
					{item.answers_variants.map((answer_variant) => {
						const isSelected = Array(...item.answers)?.includes(answer_variant)

						return isSelected
							? <label className="p-2 border-[1px] border-[#1a73e8] rounded min-w-[23%]">{answer_variant}</label>
							: <label className="p-2 border-[1px] border-gray-300 rounded min-w-[23%]">{answer_variant}</label>
					})}
				</div>
			</div>
		)
	}

	const ConsecutiveNoPhotoType = ({ item }) => {
		return (
			<div className="bg-[#F8F8F8] rounded-lg h-full w-full p-2">
				<h4 className="text-[#1a73e8] text-xl ">{item?.question}</h4>
				<div className="flex flex-auto flex-col justify-center">
					<div className="flex flex-row justify-start pb-2">
						<label className="font-bold">Ответ</label>
					</div>
					{item?.answers === 'Нет' || item?.answers === 'Жоқ' ?
						<div className="flex flex-auto flex-col justify-around pb-2 h-[80%]">
							<label className="p-2 mt-1 mb-1">Да</label>
							<label className="border-[1px] border-[#1a73e8] rounded p-2 mt-1 mb-1">Нет</label>
						</div>
						:
						<div className="flex flex-auto flex-col pb-2 h-[80%]">
							<label className="border-[1px] border-[#1a73e8] rounded p-2 mt-1 mb-1">Да</label>
							<label className="p-2 mt-1 mb-1">Нет</label>
						</div>
					}
				</div>
			</div>
		)
	}

	const ConsecutivePhotoType = ({ photoItem, questionItem, topic }) => {
		return (
			<div className="flex flex-row bg-[#F8F8F8] rounded-lg h-full w-full p-4 gap-2">
				<div id='ModalPhotoContainer_manager' className={`h-[526px] w-[390px] relative ${role === 'store' && 'cursor-pointer'}`} onClick={() => role === 'store' && handleOnClickEdit(photoItem, topic)} >
					{!modalVisible && <Editor modalPhotos={photoItem.answers} photoTags={photoItem?.managerData?.storePhotoTags} />}
					<LazyLoadImage
						alt="amg"
						src={photoItem.answers}
						className=" absolute inset-0 w-full h-full rounded-lg"
					/>
				</div>
				<div className="w-[50%] h-full flex flex-col" >
					<div className="h-auto">
						<ConsecutiveNoPhotoType item={questionItem} />
					</div>
					<div className="h-auto min-h-[65%] w-full">
						<PhotoEvaluatedData criteriaStoreInfo={photoItem.managerData?.criteriaStoreInfo} commentStore={photoItem.managerData?.commentStore} item={photoItem} topic={topic} />
					</div>
					{/* {(role === 'admin' && published == 0) && <div className="flex items-end justify-end">
						<button className="  rounded bg-blue-500 text-white text-xl p-2 " onClick={() => handleOnClickEdit(photoItem, topic)}>Редактировать</button>
					</div>} */}
				</div>
			</div>
		)
	}

	////////////////////////////// TYPES SWITCH ////////////////////////////

	////////////////////////////// BLOCKS //////////////////////////////////

	const adMedia = (data, title) => {
		return (
			<>
				<h5 className="text-[#1a73e8] text-2xl m-2 font-semibold">{title}</h5>
				<div className="grid grid-cols-2 gap-4 mb-2">
					{data.map((item, index, arr) => {
						switch (item?.type) {
							case 'photo':
								if ((index > 0 && (data[index - 1].type === 'consecutive')) && ((arr[index - 1].answers !== 'Нет') && (arr[index - 1].answers !== 'Жоқ'))) {
									return <ConsecutivePhotoType photoItem={item} questionItem={arr[index - 1]} topic={'Рекламные носители'} />
								} else {
									return <PhotoType item={item} index={index} topic={'Рекламные носители'} />
								}
							case 'multiple':
								if (item.right_answer) {
									return <MultipleAndSingleType item={item} type={SingleOrMultiple.multiple} />
								} else {
									return <MiltipleNoRightAnswerType item={item} />
								}
							case 'consecutive':
								return (item.answers === 'Нет') || (item.answers === 'Жоқ') ? <ConsecutiveNoPhotoType item={item} /> : <></>
							case 'single':
								return <MultipleAndSingleType item={item} type={SingleOrMultiple.single} />
							default:
								return <></>
						}
					})}
				</div>
			</>
		)
	}

	const displays = (data) => {
		return (
			<>
				<h5 className="text-[#1a73e8] text-2xl m-2 font-semibold">Дисплеи</h5>
				<div className="grid grid-cols-2 gap-4 mb-2">
					{data.map((item) => {
						switch (item?.type) {
							case 'photo':
								return <PhotoType item={item} topic={'Дисплеи'} />
							default:
								return <></>
						}
					})}
				</div>
			</>
		)
	}

	const assortment = (data) => {
		return (
			<div>
				<h5 className="text-[#1a73e8] text-2xl m-2 font-semibold">Ассортимент</h5>
				<div className="grid grid-cols-2 gap-4">
					{data.map((item) => {
						switch (item?.type) {
							case 'photo':
								return <PhotoType item={item} topic={'Ассортимент'} />
							default:
								return <></>
						}
					})}
				</div>
			</div>
		)
	}

	const knowledgeOfTheProgram = (data) => {
		return (
			<div>
				<h5 className="text-[#1a73e8] text-2xl m-2 font-semibold">Знание программы</h5>
				<div className="grid grid-cols-2 gap-4">
					{data.map((item) => {
						switch (item?.type) {
							case 'single':
								return <MultipleAndSingleType item={item} type={SingleOrMultiple.single} />
							case 'multiple':
								return <MultipleAndSingleType item={item} type={SingleOrMultiple.multiple} />
							default:
								return <></>
						}
					})}
				</div>
			</div>
		)
	}
	const openQuestion = (data) => {
		return <>
			<h5 className="text-[#1a73e8] text-2xl m-2 font-semibold">Открытые вопросы</h5>
			<div className="grid grid-cols-2 gap-4">
				{data.map((item, index) => {
					return <OpenType key={index} item={item} />
				})}

			</div>

		</>


	}

	const marks = (data) => {
		return (
			<div>
				<h5 className="text-[#1a73e8] text-2xl p-2 font-semibold">Оценки</h5>
				<div className="flex flex-auto flex-wrap p-2 gap-2">
					{data?.gradeTopic.map((item) => {
						return <Mark title={item.topic_title} width={item.points} />
					})}
					<Mark title='Оценка времени' width={data.efficiency_score} />
					<Mark title='Итоговая оценка' width={data.grade} />
				</div>
			</div>
		)
	}

	/////////////////////////// RENDER ///////////////////////////////

	return (
		<div className="container" >
			<div className="flex flex-row justify-between mb-2">
				<h6 className="text-3xl font-bold">{storeData?.storeInfo.name + ', ' + storeData?.storeInfo.city}</h6>
				{role === 'admin' && <>
					<button className={`flex  ${published == 1 ? 'bg-[blue]' : 'bg-[#52bb00]'} text-[white] rounded-xl p-[.625rem]`} onClick={() => handleOnClickPublicate()}>{published == 1 ? 'Редактировать' : 'Опубликовать'}</button>
				</>}
			</div>
			{storeData.topicInfo?.['Рекламные носители'] && adMedia(Object(storeData.topicInfo['Рекламные носители']), 'Рекламные носители')}
			{storeData.topicInfo?.['Дисплеи'] && adMedia(Object(storeData.topicInfo['Дисплеи']), 'Дисплеи')}
			{storeData.topicInfo?.['Ассортимент'] && adMedia(Object(storeData.topicInfo['Ассортимент']), 'Ассортимент')}
			{storeData.topicInfo?.['Знание программы'] && knowledgeOfTheProgram(Object(storeData.topicInfo['Знание программы']))}
			{storeData.topicInfo?.['Открытый вопрос'] && openQuestion(Object(storeData.topicInfo['Открытый вопрос']))}
			{/* { openQuestions() }
			{ anothers() } */}
			{storeData?.topicRating && marks(Object(storeData.topicRating))}
		</div>
	)
}