import { useEffect, useState } from "react";
import axios from "axios";
import { InfoBox } from "../../cms_core";

export function AccountCreation({ userId, isEditing }) {

    const [errors, setErrors] = useState({});
    const [divisions, setDivisions] = useState([]);
    const [formats, setFormats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [showDivision, setShowDivision] = useState(false);
    const [showFormat, setShowFormat] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [currentDivisionId, setCurrentDivisionId] = useState(0);
    const [currentFormatId, setCurrentFormatId] = useState(0);
    const [programs, setPrograms] = useState([]);
    let roles = ['admin', 'user', 'manager'];

    useEffect(() => {
        (async function () {
            let programsData;
            await axios.post(`/index.php`, {
                module: "loyaltyprogram",
                action: "getAll"
            })
                .then(function (response) {
                    setPrograms(response.data.loyaltyPrograms);
                    programsData = response.data.loyaltyPrograms;
                })
                .catch(function (error) {
                    InfoBox("Error in getAllPrograms: " + error);
                    console.error(error);
                });

            await axios.post(`/index.php`, {
                module: "store",
                action: "getDivisionsAndFormats",
                programId: "all"
            })
                .then(function (response) {
                    setDivisions(response.data.divisions);
                    setFormats(response.data.formats);
                    setLoading(false);
                })
                .catch(function (error) {
                    InfoBox("Error in getDivisionsAndFormats: " + error);
                    console.error(error);
                });
        })();
    }, []);

    useEffect(() => {
        if (!loading) {
            document.getElementById('accountForm').reset();
            setShowCheckboxes(false);
            setShowDivision(false);
            setShowFormat(false);
            if (isEditing) {
                (async function () {
                    await axios.post(`/index.php`, {
                        module: "usermgt",
                        action: "list",
                        UserId: userId
                    })
                        .then(function (response) {
                            setAccounts(response.data);
                            fillAccountForm(response.data[0]);
                        })
                        .catch(function (error) {
                            InfoBox("Error in getAccounts: " + error);
                            console.error(error);
                        });
                })();
            } else {
                setAccounts([]);
            }
        }
    }, [isEditing, loading]);

    function fillAccountForm(selectedAccount) {
        setSelectedAccount(selectedAccount);
        if (document.getElementById('email')) {
            document.getElementById('email').value = selectedAccount.email;
            if (selectedAccount.division_id) {
                setShowCheckboxes(true);
                setShowDivision(true);
                setCurrentDivisionId(divisions.findIndex((division) => division.id === selectedAccount.division_id));
            } else {
                setShowDivision(false);
                setShowCheckboxes(false);
                setCurrentDivisionId(0);
            }
            if (selectedAccount.format_id) {
                setShowCheckboxes(true);
                setShowFormat(true);
                setCurrentFormatId(formats.findIndex((format) => format.id === selectedAccount.format_id));
            } else {
                setShowFormat(false);
                if (!selectedAccount.division_id) {
                    setShowCheckboxes(false);
                }
                setCurrentFormatId(0);
            }
            document.getElementById('role').value = selectedAccount.role;
        }
    }

    useEffect(() => {
        if (!loading) {
            if (document.getElementById('division')) {
                document.getElementById('division').value = currentDivisionId.toString();
            }
        }
    }, [currentDivisionId]);

    useEffect(() => {
        if (!loading) {
            if (document.getElementById('format')) {
                document.getElementById('format').value = currentFormatId.toString();
            }
        }
    }, [currentFormatId]);

    function onSelectedAccountChange(event) {
        const selectedAccount = accounts[event.target.value];
        fillAccountForm(selectedAccount);
    }

    const handleRoleChange = (event) => {
        const selectedValue = event.target.value;
        setShowCheckboxes(selectedValue === 'user');
    };

    function onSubmitClick() {
        const form = document.getElementById('accountForm');
        let fields = ["email", "password"];
        if (validateAccountFields(fields)) {
            const selectedRoleIndex = form.querySelector('#role').selectedIndex
            let selectedDivisionIndex = 0;
            let selectedFormatIndex = 0;
            if (showDivision) {
                selectedDivisionIndex = form.querySelector('#division').selectedIndex;
            }
            if (showFormat) {
                selectedFormatIndex = form.querySelector('#format').selectedIndex;
            }
            if (!isEditing) {
                let newAccount = {
                    email: form.querySelector('#email').value,
                    role: roles[selectedRoleIndex],
                    division: showDivision ? divisions[selectedDivisionIndex].id : "",
                    format: showFormat ? formats[selectedFormatIndex].id : "",
                    password: password
                }
                axios.post(`/index.php`, {
                    module: "usermgt",
                    action: "create",
                    account: newAccount
                })
                    .then(function (response) {
                        document.getElementById('accountForm').reset();
                        window.location.reload();
                        InfoBox("Аккаунт успешно создан");
                    })
                    .catch(function (error) {
                        InfoBox("Ошибка создания магазина: " + error);
                        console.error(error);
                    });
            } else {
                axios.post(`/index.php`, {
                    module: "usermgt",
                    action: "update",
                    UserId: userId,
                    id: selectedAccount.id,
                    email: form.querySelector('#email').value,
                    role: roles[selectedRoleIndex],
                    division: showDivision ? divisions[selectedDivisionIndex].id : "",
                    format: showFormat ? formats[selectedFormatIndex].id : ""
                })
                    .then(function (response) {
                        if (password !== "") {
                            if (password.length >= 8) {
                                document.getElementById('accountForm').reset();
                                axios.post(`/index.php`, {
                                    module: "usermgt",
                                    action: "update_password",
                                    UserId: userId,
                                    id: selectedAccount.id,
                                    password: password
                                }).then(function (response) {
                                    InfoBox("Аккаунт успешно обновлен");
                                    window.location.reload();
                                }).catch(function (error) {
                                    InfoBox("Ошибка создания магазина: " + error);
                                    console.error(error);
                                });
                            } else {
                                const newErrors = {};
                                newErrors["password"] = "Пароль должен содержать не менее 8 символов";
                                setErrors(newErrors);
                            }
                        } else {
                            document.getElementById('accountForm').reset();
                            window.location.reload();
                            InfoBox("Аккаунт успешно обновлен");
                        }
                    })
                    .catch(function (error) {
                        InfoBox("Ошибка создания магазина: " + error);
                        console.error(error);
                    });
            }
        }
    }

    function validateAccountFields(fields) {
        const form = document.getElementById('accountForm');
        let isValid = true;
        const newErrors = {};
        fields.forEach(fieldName => {
            const inputValue = form.querySelector(`#${fieldName}`).value;
            switch (fieldName) {
                case "email":
                    if (!inputValue || inputValue.trim() === "") {
                        newErrors[fieldName] = "Введите почту";
                        isValid = false;
                    }
                    break;
                case "password":
                    if (!isEditing) {
                        if (inputValue.length < 8) {
                            newErrors[fieldName] = "Пароль должен содержать не менее 8 символов";
                            isValid = false;
                        }
                    }
                    break;
            }

        });
        setErrors(newErrors);
        return isValid;
    }

    const buttonStyle = {
        backgroundColor: "#007bff",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer"
    };

    const formStyle = {
        padding: "10px"
    };

    const labelStyle = {
        display: "block",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const inputStyle = {
        display: "flex",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };


    return loading ? (
        <>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </>
    ) : (
        <div className="col border p-3 m-3 bg-white rounded shadow">
            {isEditing ? <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                <select className="form-select" id="program" onChange={onSelectedAccountChange}>
                    {accounts.map((program, index) => {
                        return (
                            <option key={program.id} value={index}>{program.email}</option>
                        );
                    })}
                </select>
            </div> : <></>}
            <form style={formStyle} id="accountForm">
                <div className="form-group">
                    <label style={labelStyle} htmlFor="email">Почта:</label>
                    <input style={inputStyle} type="email" id="email" name="Email" className="form-control" />
                </div>
                <div className="form-group">
                    <label style={labelStyle} htmlFor="role">Роль:</label>
                    <select style={inputStyle} id="role" name="Role" className="form-control"
                        onChange={handleRoleChange}>
                        {roles.map((role, index) => (
                            <option key={index} value={role}>{role}</option>
                        ))}
                    </select>
                </div>
                {showCheckboxes && (
                    <div>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="showDivision">Выбор дивизиона: </label>
                            <input
                                type="checkbox"
                                id="showDivision"
                                checked={showDivision}
                                onChange={() => setShowDivision(!showDivision)}
                            />
                        </div>

                        <div className="form-group">
                            <label style={labelStyle} htmlFor="showFormat">Выбор формата: </label>
                            <input
                                type="checkbox"
                                id="showFormat"
                                checked={showFormat}
                                onChange={() => setShowFormat(!showFormat)}
                            />
                        </div>
                    </div>
                )}
                {showDivision && (
                    <div className="form-group">
                        <label style={labelStyle} htmlFor="division">Дивизион:</label>
                        <select style={inputStyle} id="division" name="Division" className="form-control">
                            {divisions.map((program, index) => (
                                <option key={program.id} value={index}>
                                    {program.name} (Программа лояльности: {programs[programs.findIndex((programItem) => programItem.id === program.loyalty_programs_id)].name})
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {showFormat && (
                    <div className="form-group">
                        <label style={labelStyle} htmlFor="format">Формат:</label>
                        <select style={inputStyle} id="format" name="Format" className="form-control">
                            {formats.map((program, index) => (
                                <option key={program.id} value={index}>
                                    {program.name} (Программа лояльности: {programs[programs.findIndex((programItem) => programItem.id === program.loyalty_programs_id)].name})
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="form-group">
                    <label style={labelStyle} htmlFor="password">Пароль:</label>
                    <input style={inputStyle} type="password" id="password" name="Password"
                        className="form-control" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button style={buttonStyle} type="button" onClick={onSubmitClick}>Сохранить</button>
            </form>
            {Object.keys(errors).map(fieldName => (
                <div key={fieldName} className="error-message">
                    <span style={{ color: "red" }}>
                        {errors[fieldName]}
                    </span>
                </div>
            ))}
        </div>
    );
}