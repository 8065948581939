import { useEffect, useState } from "react";
import Select from 'react-select';
import axios from "axios";
import { InfoBox } from "../../cms_core";
import { locationTasks } from '../../store/locations/locations';
import { useAuth } from "../../hooks/useAuth";
import DisableSelect from "./ui/DisableSelect";
import { GetDisabledIds } from "./model/GetDisabledIds";

const styles = {
    multiValue: (base, state) => ({
        ...base,
        backgroundColor: state.data.isFixed ? 'gray' : base.backgroundColor
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        fontWeight: state.data.isFixed ? 'bold' : base.fontWeight,
        color: state.data.isFixed ? 'white' : base.color,
        paddingRight: state.data.isFixed ? 6 : base.paddingRight
    }),
    multiValueRemove: (base, state) => ({
        ...base,
        display: state.data.isFixed ? 'none' : base.display
    })
};

const orderOptions = (values) => {
    return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};

export function TestCreation({ isEditing }) {
    const [testPrograms, setTestPrograms] = useState([]);
    const [testInfo, setTestInfo] = useState({});
    const [tasks, setTasks] = useState([]);
    const [errors, setErrors] = useState({});
    const [taskErrors, setTaskErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentTaskNumber, setCurrentTaskNumber] = useState(0);
    const statusValues = ["preview", "active"];
    const taskTypes = ['single', 'photo', 'open', 'multiple', 'consecutive', 'unanswered'];
    const taskTopicsQuest = ['', 'Знание программы'];
    const [taskTopics, setTaskTopics] = useState(['Рекламные носители', 'Ассортимент', 'Открытый вопрос', 'Знание программы', 'Введение', 'Дисплеи']);
    const [answers, setAnswers] = useState([{ 'ru': '' }]);
    const [stateRightAnswers, setStateRightAnswers] = useState([{ 'ru': '' }]);
    const [saveQuestion, setSaveQuestion] = useState(false)
    const [selectedImage, setSelectedImage] = useState(['']);
    const [existenceOfTests, setExistenceOfTests] = useState(false)
    const [activeGloblProgram, setActiveGloblProgram] = useState('')

    const [tests, setTests] = useState([]);
    const [fullTest, setFullTest] = useState([]);
    const [relatedTests, setRelatedTests] = useState([]);

    const [selectedShops, setSelectedShops] = useState([]);
    const [filteredShops, setFilteredShops] = useState([]);
    const [selectedTask, setSelectedTask] = useState({
        question: null,
        image: null,
        type: 'single',
        topic: null,
        serial: tasks.length + 1,
        answers: null,
        rightAnswer: null,
        reward: null,
    });

    const [locationValue, setLocationValue] = useState(orderOptions([locationTasks[0]]));

    const { role } = useAuth();

    ///////////////////////////////////// GENERAL //////////////////////////////////////////////////////////////////////////

    /******************************/
    /*    GENERAL    */
    /******************************/


    useEffect(() => {
        (async function () {
            await axios.post(`/index.php`, {
                module: "loyaltyprogram",
                action: "getAll"
            })
                .then(function (response) {
                    setTestPrograms(response.data.loyaltyPrograms);
                    setLoading(false);
                })
                .catch(function (error) {
                    InfoBox("Error in getAllPrograms: " + error);
                    console.error(error);
                });
        })();
    }, []);
    useEffect(() => {
        const languages = locationValue.map(item => item.value);
        const updatedAnswers = answers.map(answer => {
            const updatedAnswer = {};
            languages.forEach(language => {
                if (answer[language] !== undefined) {
                    updatedAnswer[language] = answer[language];
                }
            });
            return updatedAnswer;
        });

        const updatedStateRightAnswers = stateRightAnswers.map(answer => {
            const updatedAnswer = {};
            languages.forEach(language => {
                if (answer[language] !== undefined) {
                    updatedAnswer[language] = answer[language];
                }
            });
            return updatedAnswer;
        });

        setAnswers(updatedAnswers);
        setStateRightAnswers(updatedStateRightAnswers);
    }, [locationValue]);

    useEffect(() => {

        if (!loading) {
            document.getElementById('testForm') && (document.getElementById('testForm').reset());
            document.getElementById('taskForm') && (document.getElementById('taskForm').reset());

            setSelectedImage([''])
            if (isEditing) {
                fetchTestAndTasks(testPrograms[0].id).then(r => { });
            } else {
                setTests([]);
                setTestInfo([]);
                setActiveGloblProgram([])
                setFullTest([]);
                setTasks([]);
                setErrors({});
                if (isValueExists(locationTasks[1].value)) {
                    removeFromSelected(locationTasks[1]);
                }
                relatedState(testPrograms[0].id).then(r => { });


                loyaltiProgramsChange(10, '');
                setSaveQuestion(false);
                onSelectedTaskChange('new_task', 10)
                document.getElementById(`startTime`) && (document.getElementById(`startTime`).value = null)
                document.getElementById(`endTime`) && (document.getElementById(`endTime`).value = null)

            }
        }

    }, [isEditing, loading]);

    useEffect(() => {
        const fetchData = async () => {
            if (fullTest.length > 0) {
                const selectedTest = fullTest[0];
                setTests(globalLoyaltiProgramsChange(fullTest, selectedTest.loyalty_programs_id));
                try {
                    const tasksResponse = await axios.post(`/index.php`, {
                        module: "test",
                        action: "getTasksByTestId",
                        testId: selectedTest.id
                    });
                    if (tasksResponse.data.length != 0) {
                        onSelectedTaskChange(tasksResponse.data[0].id); // вот тут по факту нужно передавать sirial потому что там все по нему считаеться но они в старых тестах ломанные и там действует отдельная логика для ломанных
                        setTasks(tasksResponse.data);
                        setSelectedTask(tasksResponse.data[0]);
                        setAnswers(parsingAllAnswers(tasksResponse.data[0].answers))
                        setStateRightAnswers(parsingAllAnswers(tasksResponse.data[0].rightAnswer))

                    } else {
                        onSelectedTaskChange('new_task');
                    }
                    setCurrentTaskNumber(0);
                } catch (error) {
                    console.error("Ошибка при получении заданий:", error);
                }
            }
        };

        if (isEditing) {
            fetchData();
        }
    }, [fullTest]);

    async function relatedState(testProgram) {
        try {
            const result = await fetchRelatedTests(testProgram);
            setRelatedTests(result)
        } catch (error) {
            console.error("Error fetching shops:", error);
        }
    }

    async function fetchTestAndTasks(id) {
        try {
            const response = await axios.post(`/index.php`, {
                module: "test",
                action: "getAll",
            });

            const testsData = response.data.tests;
            setFullTest(testsData);
            relatedState(id)
            setErrors({});
        } catch (error) {
            console.error("Error fetching tests and tasks:", error);
            // InfoBox("Error fetching tests and tasks: " + error);
        }
    }

    /////////////////// FORMULATION OF QUESTION FIELDS //////////////////////////////////////

    const selectFieldsTasks = (task) => {
        const setValue = (elementId, value) => {
            const element = document.getElementById(elementId);
            if (element) {
                element.value = value != null && value !== undefined ? value : '';
            }
        };
        locationValue.map(async (location, index) => {
            if (task.question[location.value] == '') {
                await setValue('question_task' + index, '');
            } else {
                await setValue('question_task' + index, task.question[location.value]);
            }
        })

        setValue('typeSelect', task.type);
        //setValue('topicSelect', task.topic);
        setValue('reward', task.reward);
        setValue('rightAnswer', task.rightAnswer);
        if (!task.type && !task.topic) {
            //  setValue('topicSelect', 'Рекламные носители');
            setValue('typeSelect', 'single');
            setValue('inputPhoto_1', '');
        }
    };

    useEffect(() => {
        if (tests.length > 0) {
            setExistenceOfTests(true)
        } else {
            if (!isEditing) {
                setExistenceOfTests(true)
            } else {
                setExistenceOfTests(false)
            }
        }
    }, [tests])

    const globalLoyaltiProgramsChange = (arr_fullTest, idProgram) => {

        const filteredArr_fullTests = arr_fullTest.filter(item => item.loyalty_programs_id == idProgram);
        if (filteredArr_fullTests.length != 0) {
            onSelectedTestChange(filteredArr_fullTests[0].id)
        } else {
            onSelectedTestChange('notValible')
        }

        return filteredArr_fullTests;

    }

    /******************************/
    /*     END GENERAL     */
    /******************************/

    ///////////////////////////////////// BLOCK OF TESTS  //////////////////////////////////////////////////////////////////////////

    /******************************/
    /*       BLOCK OF TESTS       */
    /******************************/


    function validateTestFields(fields) {
        const form = document.getElementById('testForm');
        let isValid = true;
        const newErrors = {};

        fields.forEach(fieldName => {
            const inputValue = form.querySelector(`#${fieldName}`).value;

            if (!inputValue || inputValue.trim() === "") {
                switch (fieldName) {
                    case "tests-program":
                        newErrors[fieldName] = "Введите название программы лояльности";
                        InfoBox('Введите название программы лояльности');
                        isValid = false;
                        break;
                    case "startTime":
                        newErrors[fieldName] = "Определите время начала теста";
                        InfoBox('Определите время начала теста');
                        isValid = false;
                        break;
                    case "endTime":
                        newErrors[fieldName] = "Определите время конца теста";
                        InfoBox('Определите время конца теста');
                        isValid = false;
                        break;
                    // case "title":
                    //     newErrors[fieldName] = "Введите заголовок";
                    //     InfoBox('Введите заголовок');
                    //     isValid = false;
                    //     break;
                }
            }
        });
        locationValue.forEach((quest, index) => {
            let input = document.getElementById('title_' + index);
            if (input && input.value.trim() === '') {
                isValid = false;
                newErrors["title"] = "Введите заголовок";
            }
        });

        const startTimeValue = form.querySelector('#startTime').value;
        const endTimeValue = form.querySelector('#endTime').value;
        const startTimeDate = new Date(startTimeValue);
        const endTimeDate = new Date(endTimeValue);
        const yearStart = startTimeDate.getFullYear();
        const yearEnd = endTimeDate.getFullYear();
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const validEndData = yearEnd - currentYear
        if (startTimeDate >= endTimeDate) {
            newErrors["endTime"] = "Время окончания теста должно быть позже времени начала";
            InfoBox('Время окончания теста должно быть позже времени начала');
            isValid = false;
        }
        else if ((validEndData >= 10) || isNaN(yearStart) || isNaN(yearEnd) || (yearStart < 2000)) {
            newErrors["endTime"] = "Не корректно введенна дата";
            InfoBox('Не корректно введенна дата');
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }



    function isValueExists(valueToCheck) {
        return locationValue.some(item => item.value === valueToCheck);
    }

    function addToSelected(valueToAdd, callback) {
        const updatedValue = [...locationValue, valueToAdd];
        setLocationValue(updatedValue);
        if (callback) {
            callback();
        }
        return updatedValue;
    }

    function removeFromSelected(valueToRemove, callback) {
        const updatedValue = locationValue.filter((v) => v !== valueToRemove);
        setLocationValue(updatedValue);
        if (callback) {
            callback();
        }
        return updatedValue;
    }

    function locationInputSelect(inputName, newValue, locationsSetInputs) {
        locationsSetInputs.map((location, index) => {
            const valueElem = inputName + index;
            const inputElem = document.getElementById(valueElem);
            if (inputElem) {
                inputElem.value = newValue[location.value]
            }
        })
    }

    async function onSelectedTestChange(event = 'notValible') {
        if (event != 'notValible') {

            const selectedTest = fullTest.find(test => test.id == event);         
            setTestInfo(selectedTest);
            setActiveGloblProgram(selectedTest.loyalty_programs_id)
            loyaltiProgramsChange(selectedTest.loyalty_programs_id, selectedTest.disableShops);
            let locationsSetInputs = locationValue
            if (selectedTest.location == 'kz') {
                if (!isValueExists(locationTasks[1].value)) {
                    locationsSetInputs = addToSelected(locationTasks[1]);
                }
            } else {
                if (isValueExists(locationTasks[1].value)) {
                    locationsSetInputs = removeFromSelected(locationTasks[1]);
                }
            }

            locationInputSelect("title_", selectedTest.title, locationsSetInputs) //нужно переделатb, отказаться от document!

            // document.getElementById(`title`) && (document.getElementById(`title`).value = selectedTest.title)
            document.getElementById(`startTime`) && (document.getElementById(`startTime`).value = selectedTest.start)
            document.getElementById(`endTime`) && (document.getElementById(`endTime`).value = selectedTest.end)
            try {
                const response = await axios.post(`/index.php`, {
                    module: "test",
                    action: "getTasksByTestId",
                    testId: selectedTest.id
                });
                if (response.data[0]) {
                    const transformedTasks = Object.values(response.data).map(({ serial_number, ...rest }) => ({
                        ...rest,
                        serial: serial_number
                    }));

                    selectFieldsTasks(transformedTasks[0])
                    //  setSelectedImage([response.data[0].image ? ((response.data[0].image.startsWith('/') ? "data:image\/jpeg;base64," : "data:image\/png;base64,") + response.data[0].image) : '' ]);
                    setSelectedImage(prevImages => {
                        const newImages = response.data[0].image.map(item => {
                            return item !== '' ?
                                (item.startsWith('/') ? "data:image\/jpeg;base64," : "data:image\/png;base64,") + item :
                                '';
                        }).filter(item => item != '');
                        return [...newImages, ''];
                    });

                    setTasks(transformedTasks);
                    setSelectedTask(transformedTasks[0]);
                    /////////////////// вот тут нужно написать лучше и потестить мб он норм обнуляется и елс не нужен 
                    if (transformedTasks[0].answers) {

                        setAnswers(parsingAllAnswers(transformedTasks[0].answers))
                    } else {
                        setAnswers(resetAnswers(answers));

                    }
                    if (transformedTasks[0]?.rightAnswer?.ru) {

                        setStateRightAnswers(parsingAllAnswers(transformedTasks[0].rightAnswer))
                    } else {
                        setStateRightAnswers(resetAnswers(stateRightAnswers))

                    }
                    limitingTopics(transformedTasks[0].type)

                    setCurrentTaskNumber(2)
                } else {
                    setTasks([]);
                    setSelectedTask([]);
                    setStateRightAnswers(resetAnswers(stateRightAnswers))

                    setAnswers(resetAnswers(answers));

                    onSelectedTaskChange('new_task');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setTasks([]);
            setSelectedTask([]);
            setStateRightAnswers(resetAnswers(stateRightAnswers))

            setAnswers(resetAnswers(answers));

            onSelectedTaskChange('new_task');
        }
    }

    ////////// RELATED TESTS //////////////////

    async function fetchRelatedTests(id) {
        try {
            const response = await axios.post(`/index.php`, {
                module: "test",
                action: "list",
                loyalty_programs_id: id,
                role: role,
                selectedProgram: null,
                caller: 'admin'
            });
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    /////////////////// BLOCK FOR CHANGING THE LOYALTY PROGRAM //////////////////////////////////////


    async function searchShopsValible(programId) {
        try {
            const response = await axios.post(`/index.php`, {
                module: "store",
                action: "valibleShops",
                programId: programId
            });
            return response.data;

        } catch (error) {
            console.error("Error fetching tests and tasks:", error);
        }
    }

    const loyaltiProgramsChange = async (id, disableShops = '3') => {

        for (let i = 0; i < testPrograms.length; i++) {
            // if (testPrograms[i].name == nameProgram) {
            if (testPrograms[i].id == id) {
                try {
                    relatedState(id);
                    const result = await searchShopsValible(testPrograms[i].id);
                    let disable = '';
                    if (!isEditing) {
                        disable = result.disableShops;
                    }
                    else if (disableShops == '3') {
                        disable = testInfo.disableShops ? testInfo.disableShops : '';
                    } else {
                        disable = disableShops;
                    }
                    setSelectedShops(filterShopsByLoyaltyProgramm(result.valibleShops, disable));
                    inputChangeTests('loyalty_programs_id', id)
                } catch (error) {
                    console.error("Error fetching shops:", error);
                }
            }
        }
    };



    const filterShopsByLoyaltyProgramm = (Shops, disable) => {
        let filteredShops = Shops;
        Shops.forEach(shop => {
            shop.isActive = "1";
        });

        if (disable) {
            const disabledShopsArray = disable.split(',').map(String);
            filteredShops.forEach(shop => {
                shop.isActive = disabledShopsArray.includes(shop.id) ? "0" : "1";
            });
        }
        return filteredShops;
    };

    /////////////////// PLACING OFF STORES //////////////////////////////////////

    const inputChangeTests = (inputName, value) => {
        setTestInfo(prev => ({
            ...prev,
            [inputName]: value
        }))
        setSaveQuestion(true)
    }


    /////////////////// SUBMITTING THE FORM//////////////////////////////////////


    function onSubmitClick() {
        const form = document.getElementById('testForm');
        const fields = ["tests-program", "startTime", "endTime"];
        if (validateTestFields(fields)) {
            const selectedIndex = form.querySelector('#tests-program').selectedIndex;
            const selectedStatusIndex = form.querySelector('#statusSelect').selectedIndex;
            //const selectedLocationIndex = form.querySelector('#locationId').selectedIndex;
            const topic2 = form.querySelector('#topic').value.trim() || null;
            const related = form.querySelector('#relatedTest') ? form.querySelector('#relatedTest').value : null;
            const disableShopsStr = GetDisabledIds(selectedShops);

            const titleInputs = locationValue.map((local, index) => {
                const input = document.getElementById('title_' + index);
                return input.value;
            });
            const newTitle = titleInputs.join('<^>;');

            const newTest = {
                status: statusValues[selectedStatusIndex],
                program: testPrograms[selectedIndex].id,
                location: locationValue[1] ? "kz" : "ru",
                startTime: form.querySelector('#startTime').value,
                endTime: form.querySelector('#endTime').value,
                disableShops: disableShopsStr,
                title: newTitle,
                topic: topic2,
                related: related !== 'Без связанного' ? related : null,
            };
            if (!isEditing) {
                newTest.program = testPrograms[selectedIndex].id;
                newTest.efficiency_rewards = 'test';
            } else {
                if (newTest.related === document.getElementById('tests').value) {
                    InfoBox("Change RELATED TEST to not the same");
                    return;
                }
                newTest.id = testInfo.id;
            }

            return newTest;
        }
        return undefined;
    }

    function onFinishClick() {

        let apiTetsInfo = onSubmitClick();
        if (apiTetsInfo) {
            if (!isEditing) {
                axios.post(`/index.php`, {
                    module: "test",
                    action: "create",
                    testInfo: apiTetsInfo,
                    tasks: tasks
                })
                    .then(function (response) {
                        window.location.reload(true);
                        InfoBox("Тест успешно создан");
                    })
                    .catch(function (error) {
                        InfoBox("Creating test error: " + error);
                        console.error(error);
                    });
            } else {
                axios.post(`/index.php`, {
                    module: "test",
                    action: "edit",
                    testInfo: apiTetsInfo,
                    tasks: tasks
                })
                    .then(function (response) {
                        InfoBox("Тест успешно отредактирован");
                    })
                    .catch(function (error) {
                        InfoBox("Editing test error: " + error);
                        console.error(error);
                    });
            }
        } else {
            // console.log(errors);
            // InfoBox("Error: " + JSON.stringify(errors));
            console.error(errors);
        }
    }

    /////////////////// COPYING FORM//////////////////////////////////////

    const copyTestRequest = async () => {

        const tasks2 = [...tasks].sort((a, b) => a.serial - b.serial);

        const form = document.getElementById('testForm');
        // let newTitle = form.querySelector('#title').value + ' copy';
        const titleInputs = locationValue.map((local, index) => {
            const input = document.getElementById('title_' + index);
            return input.value;
        });
        let newTitle = titleInputs.join('<^>;');
        let Title_ru = document.getElementById('title_0').value;

        if (tests.some((test, index) => test.title.ru === Title_ru)) {

            newTitle += ' copy';
        }

        const selectedIndex = form.querySelector('#tests-program').selectedIndex;

        const topic2 = form.querySelector('#topic').value.trim() || null;
        const related = form.querySelector('#relatedTest') ? form.querySelector('#relatedTest').value : null;
        let disableShopsStr =  GetDisabledIds(selectedShops);
    

        const copyTest = {
            disableShops: disableShopsStr,
            status: statusValues[0],
            program: testPrograms[selectedIndex].id,
            startTime: form.querySelector('#startTime').value,
            endTime: form.querySelector('#endTime').value,
            location: locationValue[1] ? "kz" : "ru",
            title: newTitle,
            topic: topic2,
            related: related
        }

        if (copyTest.related === document.getElementById('tests').value) {
            InfoBox("Change RELATED TEST to not the same");
            return;
        }

        axios.post(`/index.php`, {
            module: "test",
            action: "create",
            testInfo: copyTest,
            tasks: tasks2
        })
            .then(function (response) {
                window.location.reload();
            })
            .catch(function (error) {
                InfoBox("Creating test error: " + error);
                console.error(error);
            });
    }


    const onChange = (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = locationTasks.filter((v) => v.isFixed);
                break;
        }

        setLocationValue(orderOptions(newValue));
    };


    /******************************/
    /* END    BLOCK OF TESTS      */
    /******************************/


    ///////////////////////////////////// BLOCK OF QUESTIONS //////////////////////////////////////////////////////////////////////////


    /******************************/
    /*     BLOCK OF QUESTIONS     */
    /******************************/


    const parsingAllAnswers = (arrForParse) => {
        const answersString = arrForParse;
        const parsedAnswers = {};

        for (const key in answersString) {
            if (answersString.hasOwnProperty(key)) {
                parsedAnswers[key] = (answersString[key] === null) ? '' : answersString[key];
            }
        }

        const ruAnswers = parsedAnswers.ru ? parsedAnswers.ru.split('<^>;') : [];
        const kzAnswers = parsedAnswers.kz ? parsedAnswers.kz.split('<^>;') : [];

        const parsedArray = ruAnswers.map((ru, index) => {
            const answerObject = { ru };
            if (kzAnswers[index]) {
                answerObject.kz = kzAnswers[index];
            }
            return answerObject;
        });
        return parsedArray;
    };
    const saveQuestionFunc = (id, e) => {
        e.preventDefault()
        const fields = ["question_task", "rightAnswers1", "answer1"];

        if (validateTaskFields(fields)) {

            const transformedArrayAnswers = {};

            answers.forEach(item => {
                const keys = Object.keys(item);

                Object.keys(item).forEach(key => {
                    transformedArrayAnswers[key] = transformedArrayAnswers[key] ? transformedArrayAnswers[key] + '<^>;' + item[key] : item[key];
                });

            });


            const transformedArrayRight = {};
            stateRightAnswers.forEach(item => {
                Object.keys(item).forEach(key => {
                    transformedArrayRight[key] = transformedArrayRight[key] ? transformedArrayRight[key] + '<^>;' + item[key] : item[key];
                });
            });

            const transformedArrayQuest = {};
            locationValue.forEach((local, index) => {
                const inputValue = document.getElementById('question_task' + index).value;
                transformedArrayQuest[local.value] = inputValue;
            });


            const serialSearch = selectedTask.serial || 1;
            let imageQuest = [];
            if (selectedImage.length > 1 && selectedImage[0] !== '') {
                imageQuest = selectedImage
                    .filter(item => item !== '')
                    .map(item => item.split(',')[1])
            }

            const taskToUpdate = {
                question: transformedArrayQuest,
                image: imageQuest,
                serial: serialSearch,
                type: document.getElementById('typeSelect')?.value || null,
                topic: document.getElementById('topicSelect')?.value || null,
                answers: document.getElementById('answer1') ? transformedArrayAnswers : null,
                rightAnswer: document.getElementById('rightAnswers1') ? transformedArrayRight : null,
                reward: document.getElementById('reward')?.value || null,
            };

            setTasks((prevTasks) => {
                if (id == null || id === undefined || id > prevTasks.length) {
                    return [...prevTasks, taskToUpdate];
                } else {
                    return prevTasks.map(task => (task.serial === id ? taskToUpdate : task));
                }
            });
            setCurrentTaskNumber(1)
            InfoBox('Вопрос успешно сохранен!');
        } else {
            InfoBox('Проверьте правильность формы');
            setCurrentTaskNumber(0)
        }
    };



    function validateTaskFields(fields) {

        const form = document.getElementById('taskForm');
        let isValid = true;
        const newErrors = {};

        locationValue.forEach((quest, index) => {
            let input = document.getElementById('question_task' + index);
            if (input && input.value.trim() === '') {
                isValid = false;
                newErrors["question_task"] = "Введите текст вопроса";
            }
        });

        if (document.getElementById('answer1')) {
            isValid = answers.every(ans =>
                locationValue.every(local => {
                    let isValidLocal = isValid;
                    if (!ans[local.value] || ans[local.value].trim() === '') {
                        isValidLocal = false;
                        newErrors["answer1"] = "Введите варианты ответов";
                    }
                    return isValidLocal;
                })
            );
        }

        if (document.getElementById('rightAnswers1')) {
            isValid = stateRightAnswers.every(rans =>
                locationValue.every(local => {
                    let isValidLocal = isValid;
                    if (!rans[local.value] || rans[local.value].trim() === '') {
                        isValidLocal = false;
                        newErrors["rightAnswers1"] = "Введите правильный ответ";
                    }
                    return isValidLocal;
                })
            );
        }


        setTaskErrors(newErrors);
        return isValid;
    }

    /////////////////// SELECTION OF QUESTIONS //////////////////////////////////////


    function resetAnswers(answersArr) {

        let originalObject = answersArr[0];

        let resettedObject = {};
        for (let key in originalObject) {
            resettedObject[key] = '';
        }

        return [resettedObject];
    }


    const onSelectedTaskChange = (e, f = 1) => {

        let indexNumberTasks = 0;
        tasks.map((item, i) => {
            if (item.id === e) {
                indexNumberTasks = i;
            }
        });
        setCurrentTaskNumber(tasks.length)
        if (e !== 'new_task') {
            tasks.map(task => {
                if (task.id == e) {
                    if (isEditing) {
                        setSelectedTask(task);

                        setAnswers(parsingAllAnswers(task.answers))


                        setStateRightAnswers(parsingAllAnswers(task.rightAnswer))
                        limitingTopics(task.type)
                    } else {
                        setSelectedTask([]);

                        setAnswers(resetAnswers(answers));
                        limitingTopics("single");
                        setStateRightAnswers(resetAnswers(stateRightAnswers))
                    }

                    setSelectedImage(prevImages => {
                        const newImages = task.image.map(item => {
                            return item !== '' ?
                                (item.startsWith('/') ? "data:image\/jpeg;base64," : "data:image\/png;base64,") + item :
                                '';
                        }).filter(item => item != '');;
                        return [...newImages, ''];
                    });
                    selectFieldsTasks(task)
                } else {

                    let result = e
                    if (task.serial == result) {

                        setSelectedTask(task);
                        if (task.answers) {

                            setAnswers(parsingAllAnswers(task.answers))


                        } else {

                            setAnswers(resetAnswers(answers));

                        }
                        if (task?.rightAnswer?.ru) {

                            setStateRightAnswers(parsingAllAnswers(task.rightAnswer))
                        } else {
                            setStateRightAnswers(resetAnswers(stateRightAnswers))

                        }
                        // setSelectedImage([task.image ? ((task.image.startsWith('/') ? "data:image\/jpeg;base64," : "data:image\/png;base64,") + task.image) : '']);
                        setSelectedImage(prevImages => {
                            const newImages = task.image.map(item => {
                                return item !== '' ?
                                    (item.startsWith('/') ? "data:image\/jpeg;base64," : "data:image\/png;base64,") + item :
                                    '';
                            }).filter(item => item != '');
                            return [...newImages, ''];
                        });
                        limitingTopics(task.type)
                        if (f == 3) {
                            selectFieldsTasks(tasks[e])
                        } else {
                            selectFieldsTasks(task)
                        }
                        return;
                    }
                }
            })
        }
        else {
            setSaveQuestion(false)
            let obj = {
                question: { "ru": null },
                image: null,
                type: 'single',
                topic: null,
                serial: f == 10 ? "1" : tasks.length + 1,
                answers: null,
                rightAnswer: null,
                reward: null,
            }

            setSelectedTask(obj);

            setAnswers(resetAnswers(answers));
            limitingTopics("single");
            setCurrentTaskNumber(0)
            setStateRightAnswers(resetAnswers(stateRightAnswers))

            setSelectedImage(['']);
            selectFieldsTasks(obj)
        }
    }

    /////////////////// PHOTO PROCESSING //////////////////////////////////////

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                // resolve(fileReader.result.replace(/data:image\/png;base64,/, ''));
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handlePhotoCheck = (e, index) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            convertBase64(file)
                .then((base64String) => {
                    setSelectedImage(prevImages => {
                        const newImages = [...prevImages];
                        newImages.splice(index, 1, base64String);
                        if (index + 1 === prevImages.length) {
                            newImages.push('');
                        }
                        return newImages;
                    });
                    if (document.getElementById(`question_task0`).value.length > 0) {
                        setSaveQuestion(true)
                    } else {
                        setSaveQuestion(false)
                    }
                })
                .catch((error) => {
                    console.error('Ошибка при конвертации в base64:', error);
                });
        } else {
            InfoBox('Выбранный файл не является изображением.');
        }
    };


    const toggeRemovePhotos = (index, e) => {
        e.preventDefault()
        setSelectedImage(
            prevImages => {
                const newArrImage = [...prevImages];
                newArrImage.splice(index, 1)
                return newArrImage;
            }
        )
        const inputElement = document.getElementById(`inputPhoto_${index}`);
        if (inputElement) {
            inputElement.value = '';
        }

    }

    /////////////////// VALUE INPUTS //////////////////////////////////////

    const inputChange = (id, inputName, value) => {

        setSelectedTask(prev => ({
            ...prev,
            [inputName]: value
        }))
        if (inputName == 'type') {
            limitingTopics(value)
            if (value !== 'multiple' && stateRightAnswers.length > 1) {
                setStateRightAnswers(prev => prev.slice(0, 1))
            }
        }
        setSaveQuestion(true)
    }

    const limitingTopics = (value) => {
        switch (value.trim()) {
            case 'single':
                setTaskTopics(['Рекламные носители', 'Ассортимент', 'Знание программы']);
                break;
            case 'photo':
                setTaskTopics(['Рекламные носители', 'Ассортимент', 'Дисплеи']);
                break;
            case 'open':
                setTaskTopics(['Открытый вопрос']);
                break;
            case 'multiple':
                setTaskTopics(['Рекламные носители', 'Ассортимент', 'Знание программы']);
                break;
            case 'consecutive':
                setTaskTopics(['Рекламные носители', 'Ассортимент', 'Дисплеи']);
                break;
            case 'unanswered':
                setTaskTopics(['Введение']);
                break;
            default:
        }
    }


    /////////////////// PROCESSING ANSWER OPTIONS AND CORRECT OPTIONS //////////////////////////////////////

    const addField = (setterArr, setterFunction) => {
        if (setterArr.length < 10) {
            const emptyObject = Object.fromEntries(Object.keys(setterArr[0]).map(key => [key, '']));
            setterFunction(prevState => [...prevState, emptyObject]);
            setSaveQuestion(true);
        } else {
            InfoBox('Лимит - 10 вопросов');
        }
    };

    const removeField = (index, setterAnswers, setterFunction) => {
        if (setterAnswers.length > 1) {
            const updatedAnswers = [...setterAnswers];
            updatedAnswers.splice(index, 1);
            setterFunction(updatedAnswers);
            setSaveQuestion(true);
        } else {
            if (setterAnswers.length === 1 && setterAnswers[0].ru.trim() === '') {
                InfoBox('Ошибка удаления');
            } else {
                const obj = setterAnswers[0];
                for (let key in obj) {
                    obj[key] = '';
                }
                // setterFunction([{'ru':''}])
                setterFunction([obj])
            }
        }
    };

    const handleInputChange = (index, value, setterAnswers, setterFunction, location) => {

        const updatedAnswers = [...setterAnswers];
        const existingAnswer = updatedAnswers[index];
        if (existingAnswer) {
            updatedAnswers[index] = { ...existingAnswer, [location]: value };
        } else {
            updatedAnswers[index] = { [location]: value };
        }
        setterFunction(updatedAnswers);
        setSaveQuestion(true);

    };



    /////////////////// QUESTION DELETION BLOCK //////////////////////////////////////

    const removeTask = (indexToRemove) => {
        setTasks((prevTasks) => {
            return prevTasks.reduce((updatedTasks, task) => {
                const currentSerial = parseInt(task.serial, 10);
                const currentIndexToRemove = parseInt(indexToRemove, 10);

                if (!isNaN(currentSerial) && !isNaN(currentIndexToRemove) && currentSerial === currentIndexToRemove) {

                } else if (!isNaN(currentSerial) && currentSerial > currentIndexToRemove) {

                    updatedTasks.push({ ...task, serial: (currentSerial - 1).toString() });
                } else if (!isNaN(currentSerial)) {
                    updatedTasks.push(task);
                }
                return updatedTasks;
            }, []);
        });
    };


    function onCancelClick(id) {
        const currentID = parseInt(id, 10);

        if (id < tasks.length) {

            removeTask(id);
            if (id == 1) {
                handleTaskNumberClick(currentID, 2);
            } else {
                handleTaskNumberClick(currentID - 1);
            }

        } else if ((id == tasks.length) && (tasks.length > 1)) {

            removeTask(id);
            handleTaskNumberClick(currentID - 1);
        } else if (tasks.length == 1) {

            removeTask(id);
            onSelectedTaskChange('new_task', 10)
        } else {
            InfoBox('Несуществующий вопрос')
            console.log('Недопустимый id:', id);
        }

        setSaveQuestion(true);
    }


    function handleTaskNumberClick(index, flag = 1) {
        if (index >= 0 && index <= tasks.length) {

            setCurrentTaskNumber(index);

            if ((index == 1) && (flag == 2)) {
                onSelectedTaskChange(index, 3)
            } else {
                onSelectedTaskChange(index)
            }

        } else {
            console.log('нет данных');
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    /******************************/
    /* END   BLOCK OF QUESTIONS   */
    /******************************/

    ///////////////////////////////////// STYLE //////////////////////////////////////////////////////////////////////////

    /******************************/
    /*          STYLE             */
    /******************************/


    const inputStyle = {
        display: "flex",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const labelStyle = {
        display: "block",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const buttonStyle = {
        backgroundColor: "#007bff",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        margin: "0 5px",
        marginTop: "15px",
        cursor: "pointer"
    };

    const formStyle = {
        padding: "10px",
        width: '50%'
    };


    /******************************/
    /* END         STYLE          */
    /******************************/


    ///////////////////////////////////// JSX //////////////////////////////////////////////////////////////////////////

    return (
        loading ? (
            <>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </>
        ) : (<div>

            <div className="col border p-3 m-3 bg-white rounded shadow">
                {isEditing ?
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5%' }}>
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="name">Программа лояльности:</label>
                            <select style={inputStyle} id="tests-program_global" name="Tests-Program_global" className="form-control" onChange={(e) => setTests(globalLoyaltiProgramsChange(fullTest, e.target.value))}>
                                {testPrograms.map((program, index) => {
                                    if (program.id == activeGloblProgram) {
                                        return <option key={"tests-programs_global" + program.id} selected value={program.id}>{program.name}</option>
                                    }
                                    else {
                                        return <option key={"tests-programs_global" + program.id} value={program.id}>{program.name}</option>
                                    }
                                }
                                )}
                            </select>
                        </div>
                        <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <label style={labelStyle} htmlFor="name">Тесты </label>
                            <select className="form-select" id="tests" onChange={(e) => onSelectedTestChange(e.target.value)}>
                                {tests.map((test, index) => {
                                    return (
                                        <option key={test.id} value={test.id}>{test.title.ru}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <hr></hr>
                    </div> : <></>}
                {!existenceOfTests ? <>тестов нет</> : <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <form style={formStyle} id="testForm">
                            <div className="form-group">
                                <label style={labelStyle} htmlFor="name">Программа лояльности:</label>
                                <select style={inputStyle} id="tests-program" name="Tests-Program" className="form-control" onChange={(e) => {
                                    loyaltiProgramsChange(e.target.value);
                                }}>
                                    {testPrograms.map((program, index) => {
                                        if (program.id == testInfo.loyalty_programs_id) {
                                            return <option key={"tests-programs" + program.id} selected value={program.id}>{program.name}</option>
                                        }
                                        else {
                                            return <option key={"tests-programs" + program.id} value={program.id}>{program.name}</option>
                                        }
                                    }
                                    )}
                                </select>
                            </div>

                            <div className="form-group">
                                <label style={labelStyle} htmlFor="status">Статус:</label>
                                <select style={inputStyle} id="statusSelect" name="StatusSelect" className="form-control" onChange={(e) => inputChangeTests('status', e.target.value)} defaultValue={isEditing ? testInfo.status : ''}>
                                    {statusValues.map((option, index) => {
                                        if (option == testInfo.status) {
                                            return <option key={index} selected value={option}>{option}</option>
                                        }
                                        else {
                                            return <option key={index} value={option}>{option}</option>
                                        }
                                    })}
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Выберите Язык Теста</label>
                                <Select
                                    value={locationValue}
                                    isMulti
                                    styles={styles}
                                    isClearable={locationValue.some((v) => !v.isFixed)}
                                    name="colors"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={onChange}
                                    options={locationTasks}
                                />
                            </div>

                            <div className="form-group">
                                <label style={labelStyle} htmlFor="date">Время начала теста:</label>
                                <input style={inputStyle} type="datetime-local" id="startTime" name="StartTime" defaultValue={isEditing ? testInfo.start : testInfo.startTime ? testInfo.startTime : null} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label style={labelStyle} htmlFor="date">Время конца теста:</label>
                                <input style={inputStyle} type="datetime-local" pattern="\d{2}\.\d{2}\.\d{4}" id="endTime" name="EndTime" defaultValue={isEditing ? testInfo.end : testInfo.endTime ? testInfo.endTime : null} className="form-control" />
                            </div>
                            <div className="form-group" onClick={(e) => e.stopPropagation()}>
                                <label style={labelStyle} htmlFor="disableShops">
                                    DisableShops
                                </label>
                                <DisableSelect selectedArray={selectedShops} setSelectedArray={setSelectedShops} filteredArray={filteredShops} setFilteredArray={setFilteredShops} />
                            </div>

                            {locationValue.map((location, index) => {
                                return <div key={index} className="form-group">
                                    <label style={labelStyle} htmlFor="title">Заголовок <b>{location.value}</b>:</label>
                                    <input style={inputStyle} type="text" id={'title_' + index} name="title" defaultValue={isEditing && testInfo.title && testInfo.title[location.value] ? testInfo.title[location.value] : ''} className="form-control" />
                                </div>
                            })}
                            <div className="form-group">
                                <label style={labelStyle} htmlFor="topic">Топик:</label>
                                <select style={inputStyle} id="topic" name="Topic" className="form-control" defaultValue={isEditing ? testInfo.topic : ''} onChange={(e) => inputChangeTests('topic', e.target.value)}>
                                    {taskTopicsQuest.map((option, index) => {
                                        if (option == testInfo.topic) return <option key={index} selected value={option}>{option}</option>
                                        else return <option key={index} value={option}>{option}</option>
                                    })}
                                </select>
                            </div>
                            {relatedTests.length !== 0 ?
                                <div className="form-group">
                                    <label style={labelStyle} htmlFor="name">Связанный тест:</label>
                                    <select className="form-select" id="relatedTest">
                                    <option selected>Без связанного</option>
                                        {relatedTests.map((test, index) => {
                                            return test.id !== testInfo.id && (          
                                                <option key={test.id} selected={test.id == testInfo.related} value={test.id}>
                                                    {test.title}
                                                </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                :
                                <></>
                            }

                            <button
                                style={buttonStyle}
                                type="button"
                                onClick={async () => onFinishClick()}
                            >
                                Сохранить
                            </button>

                            {isEditing &&
                                <button className="p-2 bg-white rounded shadow" type="button" onClick={copyTestRequest}>Скопировать тест</button>}

                            {Object.keys(errors).map(fieldName => (
                                <div key={fieldName} className="error-message">
                                    <span style={{ color: "red" }}>
                                        {errors[fieldName]}
                                    </span>
                                </div>
                            ))}

                        </form>
                        {
                            <form style={formStyle} id={`taskForm`}>
                                <div className="form-group">
                                    <label style={labelStyle} htmlFor="question_picker">Вопрос</label>
                                    <select style={inputStyle} id="question_picker" name="question_picker"
                                        className="form-control"
                                        onChange={e => onSelectedTaskChange(e.target.value, 2)}>
                                        {tasks.map((task, index) => {

                                            return <option
                                                key={task.serial}
                                                selected={task.serial == selectedTask.serial || (index == 0 && !selectedTask.serial)}
                                                value={task.serial}>
                                                {index + 1}: {task.question.ru}
                                            </option>
                                        }
                                        )}
                                        <option value={'new_task'}>{'Создать новый вопрос'}</option>
                                    </select>
                                </div>
                                <label style={labelStyle}>Фото:</label>
                                {selectedImage.map((image, index) => {
                                    return <div key={index} className="form-group">
                                        <div className="flex flex-row gap-2 items-center justify-between">
                                            <button className="bg-[blue] text-[white] rounded-3xl p-1" onClick={(event) => (event.preventDefault(), document.getElementById(`inputPhoto_${index}`).click())}>
                                                {image !== '' ? 'Change Photo' : 'Select Photo'}
                                            </button>
                                            <input id={"inputPhoto_" + index} name="questionPhoto"
                                                type="file" accept=".jpg, .jpeg, .png"
                                                className="text-transparent hidden"
                                                onChange={(e) => handlePhotoCheck(e, index)} />
                                            <img id="imgPreview" alt='photo not selected' src={tasks ? selectedImage[index] : ''}
                                                style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            {image !== '' && <button style={buttonStyle} onClick={(e) => toggeRemovePhotos(index, e)}>Remove</button>}
                                        </div>

                                    </div>
                                })}
                                {locationValue.map((location, indexloc) => {
                                    return <div key={indexloc} className="form-group">
                                        <label style={labelStyle} htmlFor="question">Текст вопроса <b>{location.value}</b></label>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            id={`question_task` + indexloc}
                                            name={"question_" + indexloc}
                                            onChange={(e) => inputChange(selectedTask?.id, 'question', e.target.value)}
                                            className="form-control"
                                            defaultValue={isEditing && selectedTask.question && selectedTask.question[location.value] ? selectedTask.question[location.value] : ''}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                })}
                                <div className="form-group">
                                    <label style={labelStyle} htmlFor="type">Тип вопроса:</label>
                                    <select style={inputStyle} id="typeSelect" name="type" className="form-control"
                                        onChange={(e) => inputChange(selectedTask?.id, 'type', e.target.value)}>
                                        {taskTypes.map((option, index) => {
                                            if (option == selectedTask?.type) {
                                                return <option key={index} value={option} selected>{option}</option>
                                            } else {
                                                return <option key={index} value={option}>{option}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label style={labelStyle} htmlFor="topic">
                                        Топик вопроса:
                                    </label>
                                    <select
                                        style={inputStyle}
                                        id="topicSelect"
                                        name="topic"
                                        className="form-control"
                                        disabled={taskTopics.length == 1}
                                        onChange={(e) => inputChange(selectedTask?.id, 'topic', e.target.value)}
                                    >
                                        {taskTopics.map((option, index) => {
                                            if (option == selectedTask?.topic) {

                                                return <option key={index} value={option} selected>{option}</option>
                                            } else {
                                                return <option key={index} value={option}>{option}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                                {selectedTask?.type !== "open" && selectedTask?.type !== "photo" && selectedTask?.type !== "unanswered" && (
                                    <div className={`${locationValue.length > 1 ? 'flex flex-row gap-1 items-center justify-center' : ''} w-full`}>
                                        {locationValue.map((location, indexloc) => {
                                            return <div key={indexloc} className={`form-group ${locationValue.length > 1 ? 'w-1/2' : 'w-full'}`}>
                                                <label style={labelStyle} htmlFor="answers">Варианты ответа <b>{location.value}</b>:</label>

                                                {answers.map((answer, index) => (

                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                                                        key={index}>
                                                        <span style={{
                                                            marginRight: '5%',
                                                            flexShrink: 0
                                                        }}>№ {index + 1}:&nbsp;</span>
                                                        <input
                                                            style={{ flex: 1, marginRight: '5%', boxSizing: 'border-box' }}
                                                            id={`answer${index + 1}`}
                                                            type="text"
                                                            name="Answers"
                                                            className="form-control"
                                                            value={answer[location.value]}
                                                            onChange={(e) => handleInputChange(index, e.target.value, answers, setAnswers, location.value)}
                                                        />
                                                        {locationValue.length === indexloc + 1 ?
                                                            <button className="bg-[red] cursor-pointer rounded-lg p-2" type="button"
                                                                onClick={() => removeField(index, answers, setAnswers)}>Удалить
                                                                поле
                                                            </button> : <></>}

                                                    </div>
                                                ))}
                                            </div>
                                        })}
                                    </div>

                                )}
                                <div className="form-group" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <button style={buttonStyle} type="button"
                                        onClick={() => addField(answers, setAnswers)}>Добавить поле
                                    </button>
                                </div>

                                {selectedTask?.topic === "Знание программы" && (selectedTask?.type === "single" || selectedTask?.type === "multiple") && (
                                    <div className={`${locationValue.length > 1 ? 'flex flex-row gap-1 items-center justify-center' : ''} w-full`}>
                                        {locationValue.map((location, indexloc) => {
                                            return <div key={indexloc} className={`form-group ${locationValue.length > 1 ? 'w-1/2' : 'w-full'}`}>
                                                <label style={labelStyle} htmlFor="answers">Правильные Варианты ответа <b>{location.value}</b>:</label>

                                                {stateRightAnswers.map((rightAnswers, index) => (
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                                                        key={index}>
                                                        <span style={{
                                                            marginRight: '5%',
                                                            flexShrink: 0
                                                        }}>№ {index + 1}:&nbsp;</span>
                                                        <input
                                                            style={{ flex: 1, marginRight: '5%', boxSizing: 'border-box' }}
                                                            id={`rightAnswers${index + 1}`}
                                                            type="text"
                                                            name="RightAnswers"
                                                            className="form-control"
                                                            value={rightAnswers[location.value]}
                                                            onChange={(e) => handleInputChange(index, e.target.value, stateRightAnswers, setStateRightAnswers, location.value)}
                                                        />
                                                        {locationValue.length === indexloc + 1 ?
                                                            <button className="bg-[red] cursor-pointer rounded-lg p-2" type="button"
                                                                onClick={() => removeField(index, stateRightAnswers, setStateRightAnswers)}>Удалить
                                                                поле
                                                            </button> : <></>}

                                                    </div>
                                                ))}
                                            </div>
                                        })}
                                    </div>

                                )}
                                {selectedTask?.topic === "Знание программы" && selectedTask?.type === "multiple" && (
                                    <div className="form-group" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <button style={buttonStyle} type="button"
                                            onClick={() => addField(stateRightAnswers, setStateRightAnswers)}>Добавить поле
                                        </button>
                                    </div>
                                )}
                                {selectedTask?.topic === "Знание программы" && selectedTask?.type !== "unanswered" && selectedTask?.type !== "consecutive" && (
                                    <input
                                        style={inputStyle}
                                        type="text"
                                        id="reward"
                                        value={100}
                                        placeholder="Баллы за правильный ответ"
                                        name="reward"
                                        onChange={(e) => inputChange(selectedTask?.id, 'reward', e.target.value)}
                                        defaultValue={selectedTask?.reward}
                                        className="form-control"
                                    />
                                )}


                                <div style={{ display: 'flex', justifyContent: 'start', gap: '10px', marginTop: '10px' }}>
                                    {
                                        saveQuestion && (
                                            <button style={buttonStyle} type="button"
                                                onClick={(e) => saveQuestionFunc(selectedTask?.serial, e)}> Сохранить
                                                вопрос</button>

                                        )
                                    } {currentTaskNumber == 0 || tasks.length == 0 ? "" :

                                        <button style={{ ...buttonStyle, backgroundColor: 'red' }} type="button"
                                            onClick={() => onCancelClick(selectedTask?.serial)}>
                                            Удалить вопрос
                                        </button>

                                    }
                                </div>
                                {Object.keys(taskErrors).map(fieldName => (
                                    <div key={fieldName} className="error-message">
                                        <span style={{ color: "red" }}>
                                            {taskErrors[fieldName]}
                                        </span>
                                    </div>
                                ))}
                            </form>
                        }
                    </div>
                </>}
            </div>
        </div >)
    );
}

