import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice';
import liveReportSlice from "./slices/liveReportSlice";
import mainHeaderSlice from "./slices/mainHeaderSlice"

export const store = configureStore({
    reducer: {
        user: userReducer,
        mainHeaderSlice:mainHeaderSlice,
        liveReportSlice: liveReportSlice,

    }
});

export const RootState = () => {
    return store.getState();
};

export const AppDispatch = () => {
    return store.dispatch;
};