import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks/redux'
import { useAuth } from '../../hooks/useAuth';
import CloseTab from '../ui/CloseTab';
import PhotoСontainer from '../liveReportModal/components/PhotoСontainer';
import InfoWidget from '../liveReportModal/components/InfoWidget';
import Comments from '../liveReportModal/components/Comments';
import ButtonSendData from '../liveReportModal/components/ButtonSendData';
import CriteriaWidget from '../liveReportModal/components/CriteriaWidget';

const ManagerRatingModal = ({ setFullScreenOutput }) => {
  const dispatch = useAppDispatch();
  const currentTest = useAppSelector(state => state.mainHeaderSlice.currentTest);
  const { role } = useAuth();
  const modalsDataManagerRating  = useAppSelector(state => state.liveReportSlice.modalsDataManagerRating);
  const  published  = useAppSelector(state => state.liveReportSlice.published);
  return (
    <div className=" flex justify-center  h-[100%] w-[100%] px-4 py-4 gap-4">
      <CloseTab />
      <div className='flex flex-col w-[43%] h-full'>
        <div className="flex w-full h-full  rounded flex-col content-center relative">
          <PhotoСontainer
            task_id={modalsDataManagerRating.task_id}
            store_id={modalsDataManagerRating.store_id}
            photoTags={modalsDataManagerRating.photoTags}
            published={published}
            image={modalsDataManagerRating.photo}
            storeCode={modalsDataManagerRating.code}
            storeCity={modalsDataManagerRating.city}
            setFullScreenOutput={setFullScreenOutput} />
        </div>
      </div>
      <div className="flex w-1/2 flex-col h-full items-start">
        <h4 className="font-bold h-[8%] text-[26px] text-blue-500">Инфо</h4>
        <h5 className="font-bold h-[5%] text-[18px]">Вопрос</h5>
        <p className="h-[6%] text-[16px] overflow-hidden leading-tight overflow-ellipsis">{modalsDataManagerRating.question}</p>
        <InfoWidget
          storeName={modalsDataManagerRating.name}
          storeCode={modalsDataManagerRating.code}
          storeCity={modalsDataManagerRating.city}
          finishTime={modalsDataManagerRating.finishTime}
        />
        <div className=' flex h-[34%] flex-col gap-3 w-full'>
          <CriteriaWidget
            role={role}
            published={published}
            criteria={modalsDataManagerRating.criteriaInfo}
            currentTest={currentTest.id}
            store_id={modalsDataManagerRating.store_id}
          />
        </div>
        <div className='flex  h-[30%] flex-col w-full gap-2'>
          <Comments
            role={role}
            published={published}
            task_id={modalsDataManagerRating.task_id}
            store_id={modalsDataManagerRating.store_id}
            comment={modalsDataManagerRating.comment} />
           {role === 'admin' &&  <span className='flex justify-end'>
          <ButtonSendData />
          </span>}
        </div>
      </div>
    </div>
  )
}

export default ManagerRatingModal
