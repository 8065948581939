import React from 'react'
import { useAppDispatch } from '../../../store/hooks/redux';
import { setChangeCommentStore } from '../../../store/slices/liveReportSlice';

const Comments = ({ role, published, task_id, store_id, comment }) => {
    const dispatch = useAppDispatch();
    return (
        <>
            <span className='flex font-bold text-[18px]'>Комментарий</span>
            {role === 'admin' ?
                <textarea
                    type='text'
                    id='commentStore'
                    style={{ resize: 'none' }}
                    readOnly={published == 1}
                    defaultValue={comment || null} 
                    onChange={(e) => {
                        if (role !== 'admin' || published == 1) {
                            e.preventDefault();
                            return;
                        }
                        dispatch(setChangeCommentStore({ task_id: task_id, store_id: store_id, text_value: e.target.value }));
                    }}
                    placeholder={'Комментарий с сообщением ошибки'}
                    className={`border border-gray-300 rounded-lg px-2 py-2  focus:outline-none focus:border-blue-500 h-full w-full`}
                />
                : <span className='rounded-lg px-2 py-2 h-full w-full'>{comment || 'Комменатрий не был назначен администратором'}</span>}
        </>
    )
}

export default Comments
