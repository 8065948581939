class Sort {

    sortManagerInfo(field, array, direction) {
        const sortedArray = [...array].sort((a, b) => {
            let valueA;
            let valueB;
            if (field.id && Number.isFinite(Number(field.id))) {
                const elementA = a['topicInfo'].find(item => item.topic_title === field.field);
                const elementB = b['topicInfo'].find(item => item.topic_title === field.field);
                valueA = Number(elementA.points);
                valueB = Number(elementB.points);
            } else {
                valueA = !Number.isFinite(Number(a[field.field])) ? a[field.field].toUpperCase() : Number(a[field.field]);
                valueB = !Number.isFinite(Number(b[field.field])) ? b[field.field].toUpperCase() : Number(b[field.field]);
            }
            return this.sortingMethod(valueA, valueB, direction);
        })
        return sortedArray
    }

    sortLiveReportMoreDetails(field, array, direction) {
        function toArray(value) {
            if (typeof value === 'string') {
                const array = value.split('<^>;').map(item => item.trim());
                return array.map(item => {
                    const number = Number(item);
                    return isNaN(number) ? item : number;
                });
            } else if (typeof value === 'number') {
                return [value];
            } else {
                throw new Error("Unsupported input type");
            }
        }

        function isInclude(value, array) {
            return array.some(item => item.toString() === value.toString());
        }
    
        const sortedArray = [...array].sort((a, b) => {
            if (field.id && field.id === 'answers') {
                let valueA = toArray(a['answer']);
                let valueB = toArray(b['answer']);
                const answerField = field.field.toString();
    
                if (direction === 'asc') {
                    if (isInclude(answerField, valueA)) {
                        return -1;
                    }
                    if (isInclude(answerField, valueB)) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (isInclude(answerField, valueA)) {
                        return 1;
                    }
                    if (isInclude(answerField, valueB)) {
                        return -1;
                    }
                    return 0;
                }
            } else {
                const valueA = !Number.isFinite(Number(a[field.field])) ? a[field.field].toUpperCase() : Number(a[field.field]);
                const valueB = !Number.isFinite(Number(b[field.field])) ? b[field.field].toUpperCase() : Number(b[field.field]);
                return this.sortingMethod(valueA, valueB, direction);
            }
        });
        return sortedArray;
    }

    sortProgressPage (field, array, direction) {

        function parseDate(dateString) {
            if (!dateString) return null; 
            const parts = dateString.split('.');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
      const  sortedTable = array.sort((a, b) => {
            let valueA;
            let valueB;
            if (field === "answersCount") {
                 valueA = parseInt(a[field].split('/')[0]);
                 valueB = parseInt(b[field].split('/')[0]);

            } else if (field === "finishTime") {
                 valueA = parseDate(a[field]);
                 valueB = parseDate(b[field]);
            }
            else if (field === 'percent') {
                 valueA = parseFloat(a[field]);
                 valueB = parseFloat(b[field]);
            }  else {
                 valueA = !Number.isFinite(Number(a[field])) ? a[field].toUpperCase() : Number(a[field]);
                 valueB = !Number.isFinite(Number(b[field])) ? b[field].toUpperCase() : Number(b[field]);
            }
            return this.sortingMethod(valueA, valueB, direction);
        });
        return sortedTable;
    }

    sortingMethod(valueA, valueB, direction){
        if (direction === 'asc') {
            if (valueA < valueB) {
                return -1
            }
            if (valueA > valueB) {
                return 1
            }
            return 0;
        } else {
            if (valueA < valueB) {
                return 1
            }

            if (valueA > valueB) {
                return -1
            }
            return 0;
        }
    }

}

export default Sort;