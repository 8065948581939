import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { InfoBox } from "../cms_core";
import { useAppSelector } from '../store/hooks/redux';
import { useAuth } from "../hooks/useAuth";
import { TestCreation } from "../components/AdminPanelComponent/TestCreation";
import { ProgramCreation } from "../components/AdminPanelComponent/ProgramCreation";
import { StoreCreation } from "../components/AdminPanelComponent/StoreCreation";
import { AccountCreation } from "../components/AdminPanelComponent/AccountCreation";

export default function AdminPanelPage() {

    const { user_id } = useAuth();
    const loyaltyPrograms = useAppSelector(state => state.mainHeaderSlice.loyaltyPrograms);
    const [choice, setChoice] = useState("new_test");
    const [isEditing, setIsEditing] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [programName, setProgramName] = useState('');
    const [currentProgramId, setCurrentProgramId] = useState(0);

    const handleChoiceChange = (newChoice) => {
        setChoice(newChoice);
    };

    function getPanel() {
        switch (choice) {
            case "new_test":
                return (
                    <div>
                        <TestCreation isEditing={isEditing} />
                    </div>
                );
            case "store":
                return (
                    <div>
                        <StoreCreation userId={user_id} isEditing={isEditing} />
                    </div>
                );
            case "account":
                return (
                    <div>
                        <AccountCreation userId={user_id} isEditing={isEditing} />
                    </div>
                );
            case "program":
                return (
                    <div>
                        <ProgramCreation userId={user_id} isEditing={isEditing} />
                    </div>
                );
        }
    }

    useEffect(() => {
        setProgramName(loyaltyPrograms[0].name);
        setCurrentProgramId(loyaltyPrograms[0].id);
    }, [isEditing]);

    useEffect(() => {
        if (isEditing && modalVisible) {
            if (document.getElementById('question')) {
                document.getElementById('question').value = programName;
            }
        }
    }, [modalVisible]);

    function handleProgramClick() {
        setChoice("program");
        //setModalVisible(true);
    }

    function onSelectedProgramChange(event) {
        const selectedProgram = loyaltyPrograms[event.target.value];
        setCurrentProgramId(selectedProgram.id);
        document.getElementById('question').value = selectedProgram.name;
    }

    function onCancelClick() {
        document.getElementById('programForm').reset();
        setModalVisible(false);
        setChoice('test');
    }

    function onNextClick() {
        if (programName !== '') {
            if (!isEditing) {
                axios.post(`/index.php`, {
                    module: "loyaltyprogram",
                    action: "add",
                    name: programName
                })
                    .then(function (response) {
                        setModalVisible(false);
                        document.getElementById('programForm').reset();
                        window.location.reload();
                        InfoBox("Новая программа лояльности успешно создана!");
                    })
                    .catch(function (error) {
                        InfoBox("Ошибка создания программы лояльности : " + error);
                        console.error(error);
                    });
            } else {
                axios.post(`/index.php`, {
                    module: "loyaltyprogram",
                    action: "update",
                    name: programName,
                    id: currentProgramId
                })
                    .then(function (response) {
                        setModalVisible(false);
                        document.getElementById('programForm').reset();
                        window.location.reload();
                        InfoBox("Программа лояльности успешно изменена!");
                    })
                    .catch(function (error) {
                        InfoBox("Ошибка создания программы лояльности : " + error);
                        console.error(error);
                    });
            }
        } else {
            InfoBox("Введите название!");
        }
    }

    const listStyle = {
        padding: "10px",
        width: "20%"
    };

    const selectedButtonStyle = {
        backgroundColor: "#007bff",
        color: "#ffffff",
        padding: "4px 8px",
        border: "none",
        borderRadius: "2px",
        marginBottom: "10px",
    };

    const buttonStyle = {
        textDecoration: "none",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        padding: "0",
        marginBottom: "10px",
    };

    const formStyle = {
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
        marginTop: "10px"
    };

    const cancelButtonStyle = {
        backgroundColor: "white",
        color: "black",
        marginRight: "10px"
    };

    const nextButtonStyle = {
        backgroundColor: "white",
        color: "black",
        marginRight: "10px"
    };

    const modalFooterStyle = {
        display: "flex",
        justifyContent: "space-between"
    };

    const inputStyle = {
        display: "flex",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const labelStyle = {
        display: "block",
        width: "auto",
        marginBottom: "10px",
        minWidth: "100%",
        maxWidth: "100%"
    };

    const iconStyle = {
        fontSize: "24px",
        marginRight: "5px",
    };

    return (
        <div>
            <Modal show={modalVisible} onHide={() => onCancelClick()}>
                <Modal.Header closeButton>
                    {isEditing ? <h5>Редактирование программы лояльности</h5> : <h5>Создание программы лояльности</h5>}
                </Modal.Header>
                <Modal.Body>
                    {isEditing ? <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                        <select className="form-select" id="program" onChange={onSelectedProgramChange}>
                            {loyaltyPrograms.map((program, index) => {
                                return (
                                    <option key={program.id} value={index}>{program.name}</option>
                                );
                            })}
                        </select>
                    </div> : <></>}
                    <form style={formStyle} id="programForm">
                        <div className="form-group">
                            <label style={labelStyle} htmlFor="question">Введите название:</label>
                            <input style={inputStyle} type="text" id="question" name="Question" className="form-control"
                                onChange={(e) => setProgramName(e.target.value)} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyle}>
                    <div>
                        <button style={cancelButtonStyle} type="button" onClick={onCancelClick}>Отмена</button>
                        <button style={nextButtonStyle} type="button" onClick={onNextClick}>Далее</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div style={listStyle}>

                    <div className="separator">
                        <input
                            className="tgl tgl-flip"
                            id="cb5"
                            type="checkbox"
                            checked={isEditing}
                            onChange={(e) => setIsEditing(e.target.checked)}
                        />
                        <label
                            className="tgl-btn"
                            data-tg-off="Создание"
                            data-tg-on="Редактирование"
                            htmlFor="cb5"
                        ></label>
                    </div>

                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <button
                                type="button"
                                onClick={() => handleChoiceChange("new_test")}
                                style={choice === "new_test" ? selectedButtonStyle : buttonStyle}
                            >
                                Тесты
                            </button>
                        </li>



                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={() => handleChoiceChange("store")}
                                style={choice === "store" ? selectedButtonStyle : buttonStyle}
                            >
                                Магазины
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={() => handleChoiceChange("account")}
                                style={choice === "account" ? selectedButtonStyle : buttonStyle}
                            >
                                Аккаунты
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={handleProgramClick}
                                style={choice === "program" ? selectedButtonStyle : buttonStyle}
                            >
                                Программы лояльности
                            </button>
                        </li>
                    </ul>
                </div>

                <div style={{ width: "80%" }}>{getPanel()}</div>
            </div>
        </div>
    );
}
