import { useState, useEffect } from "react";
import axios from "axios";
import { InfoBox } from "../cms_core";
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';

export default function MyTasksPage() {
	const [isLoading, setIsLoading] = useState(true);
	const [infoTask, setInfoTask] = useState([]);
	const { user_id, name } = useAuth();

	useEffect(() => {
		(async function () {
			await axios.post(`/index.php`, {
				module: "progress",
				action: "myTaskPage",
				id: user_id
			})
				.then(function (response) {
					setInfoTask(response.data);
					setIsLoading(false)
				})
				.catch(function (error) {
					InfoBox("Error: " + error);
					console.error(error);
				});
		})();
	}, [])

	if (isLoading) {
		return (
			<>
				<svg className="spinner" viewBox="0 0 50 50">
					<circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
				</svg>
			</>
		)
	}

	return (
		<div className="flex flex-col items-center container">
			<div className="d-flex flex-wrap flex-md-nowrap items-start pt-3 pb-2 mb-3 m-3 !w-full">
				<h6 className="font-bold text-3xl">{name}</h6>
			</div>
			<div className="flex items-center !w-full">
				<table className="border-separate border-spacing-y-2 border-0 !w-full">
					<thead>
						<tr className="p-2">
							<th scope="col" className="text-left p-4">Задание №</th>
							<th scope="col" className="text-left p-4">Дата выполнения</th>
							<th scope="col" className="text-left p-4">Оценка времени</th>
							<th scope="col" className="text-left p-4">Рекламные носители</th>
							<th scope="col" className="text-left p-4">Дисплеи</th>
							<th scope="col" className="text-left p-4">Знание программы</th>
							<th scope="col" className="text-left p-4">Итоговая оценка</th>
							<th scope="col" className="text-left p-4"></th>
						</tr>
					</thead>
					<tbody>
						{infoTask.length > 0 && infoTask.map((task, index) => {
							return <tr key={task.test_id} id={task.test_id} className="bg-[#F8F8F8]">
								<td className="text-left p-4 rounded-l-xl">{index + 1}</td>
								<td className="text-left p-4">{task.finish_time}</td>
								<td className="text-left p-4">{task.topic.efficiency_score}</td>
								<td className="text-left p-4">
									{task.topic.gradeTopic.reduce((acc, topic) => {
										return topic.topic_title === "Рекламные носители" ? topic.points : acc;
									}, "-")}
								</td>
								<td className="text-left p-4">
									{task.topic.gradeTopic.reduce((acc, topic) => {
										return topic.topic_title === "Дисплеи" ? topic.points : acc;
									}, "-")}
								</td>
								<td className="text-left p-4">
									{task.topic.gradeTopic.reduce((acc, topic) => {
										return topic.topic_title === "Знание программы" ? topic.points : acc;
									}, "-")}
								</td>
								<td className="text-left p-4">{task.topic.grade}</td>
								<td className="text-left p-4 rounded-r-xl" key={task.test_id}>
									{task.published == 1 ? (
										<Link to={`/ratingStore/${task.test_id}/${user_id}`} className="border-0 text-blue-500 font-bold">
											Подробнее
										</Link>
									) : (
										<span className="border-0 text-gray-500 font-bold cursor-not-allowed">
											Подробнее
										</span>
									)}
								</td>
							</tr>
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}