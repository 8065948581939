import React, { memo, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks/redux';
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { GoDash } from "react-icons/go";
import { onNextQuestionCheck, setSearchValue, reportDatas } from '../store/slices/liveReportSlice';
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import axios from "axios";
import { InfoBox } from "../cms_core";
import { Link, useNavigate } from "react-router-dom";
import AnswersSingAndMultipl from '../components/LiveReportComponent/AnswersChart';
import { PictureGallery } from '../components/LiveReportComponent/PictureGallery';
import { useAuth } from '../hooks/useAuth';
import Sort from '../store/sorting/sortСlass';


const LiveReportMoreDetails = memo(() => {

    const navigate = useNavigate();
    const { user_id } = useAuth();
    const dispatch = useAppDispatch();
    const { qustionNumber, task_id, pageType, reportData, searchValue, currentTypeSort, selectedDay, orderBy, currentTopic } = useAppSelector(state => state.liveReportSlice);
    const { currentTest, selectedCity, selectedRegion, selectedFormat } = useAppSelector(state => state.mainHeaderSlice);
    const [loading, setLoading] = useState(true);
    const [chartCounts, setChartCounts] = useState({})
    const [answersInfo, setAnswersInfo] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc");
    var ChartDatasSet = [
        {
            label: '',
            data: [0],
        }
    ];
    const handleSorts = new Sort();


    useEffect(() => {
        if (reportData.length != 0 && reportData) {
            setLoading(true)
            onMoreInfoCLick();
        } else {
            navigate('/liveReport', { replace: true });
        }
    }, [selectedCity, selectedRegion, selectedFormat])


    function onMoreInfoCLick() {
        axios.post(`/index.php`, {
            module: "livereport",
            action: "getAnswersStatistic",
            taskId: task_id,
            userId: user_id,
            selectedCity: selectedCity,
            selectedRegion: selectedRegion.id,
            selectedFormat: selectedFormat,
            searchValue: searchValue,
            currentTypeSort: currentTypeSort,
            selectedDay: selectedDay,
            orderBy: orderBy
        })
            .then(function (response) {
                setAnswersInfo(response.data);
                setChartCounts(answerCount(response.data))
                setLoading(false)
                fetchData()
            })
            .catch(function (error) {
                InfoBox("Error: " + error);
                console.error(error);
            });
    }

    function onSwitchQuestion(indexNextQuestion) {
        if ((reportData.length !== indexNextQuestion) && (indexNextQuestion >= 0)) {
            setLoading(true)
            dispatch(onNextQuestionCheck(indexNextQuestion));
            setAnswersInfo([]);
            axios.post(`/index.php`, {
                module: "livereport",
                action: "getAnswersStatistic",
                taskId: reportData[indexNextQuestion].task_id,
                userId: user_id,
                selectedRegion: selectedRegion.id,
                selectedFormat: selectedFormat,
                selectedCity: selectedCity,
                searchValue: searchValue,
                currentTypeSort: currentTypeSort,
                selectedDay: selectedDay,
                orderBy: orderBy
            })
                .then(function (response) {
                    setAnswersInfo(response.data);
                    setChartCounts(answerCount(response.data))
                    setLoading(false)
                    fetchData()
                })
                .catch(function (error) {
                    InfoBox("Error: " + error);
                    console.error(error);
                });
        } else {
            InfoBox('Такого вопроса нет!')
        }
    }

    const fetchData = () => {
        axios.post(`/index.php`, {
          module: "livereport",
          action: "getLiveReport",
          testId: currentTest.id,
          userId: user_id,
          selectedCity: selectedCity,
          selectedRegion: selectedRegion.id,
          selectedFormat: selectedFormat,
          searchValue: searchValue,
          currentTopic: currentTopic,
          currentTypeSort: currentTypeSort,
          selectedDay: selectedDay,
          orderBy: orderBy
        })
          .then(function (response) {
            dispatch(reportDatas(response.data.dataPage));
          })
          .catch(function (error) {
            InfoBox("Error: " + error);
            console.error(error);
          });
      }

    function answerCount(response) {
        const answerCount = {};

        const answersArray = Object.values(response.answers);
        answersArray.forEach(answer => {    
            answerCount[answer.trim()] = 0;
        });
        response.rows.forEach(row => {
            const rowAnswers = row.answer.split('<^>;');
            rowAnswers.forEach(answer => {
                const trimmedAnswer = answer.trim();
                if (answerCount.hasOwnProperty(trimmedAnswer)) {
                    answerCount[trimmedAnswer]++;
                }
            });
        });
        const entries = Object.entries(answerCount);

        entries.sort((a, b) => b[1] - a[1]);
        const sortedAnswerCount = Object.fromEntries(entries);
        return sortedAnswerCount;
    }

    const handleSort = (field) =>{
        const  resultSortArray =  handleSorts.sortLiveReportMoreDetails(field, answersInfo.rows, sortDirection);
        setSortDirection(prev => prev === 'asc' ? 'desp' : 'asc');
        setAnswersInfo(prev => ({
            ...prev,
            rows:resultSortArray
        }));

    }



    function AnswerRow({ index }) {
        return pageType === 'open' ? (
            <td>{answersInfo.rows[index].answer}</td>
        ) :
        Object.keys(chartCounts).map((item, key) => {
                const answer = answersInfo.rows[index].answer.trim();
                const containsAnswer = answer.includes('<^>;') ? answer.split('<^>;').map(ans => ans.trim()) : [answer];
                for (let i = 0; i < containsAnswer.length; i++) {
                  if (containsAnswer[i] === item.trim()) {
                    return (
                      <td key={key}>
                        <IoCheckmarkCircleOutline color={"#0969DF"} className='w-full h-[25px]' />
                      </td>
                    );
                  }
                }
                return <td key={key}><GoDash className='w-full h-[25px]' /></td>;
              })
    }


    return answersInfo.length === 0 || loading || reportData.length === 0 ?
        (<>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </>
        ) : (
            <div className="custom-container">
                {/* <div
                    style={{ position: 'sticky', top: 0, zIndex: 100, background: 'white', borderBottom: '1px solid #ccc', padding: '10px' }}
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                    <div className="btn-group me-2">
                        <Link to="/liveReport" type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" >К списку вопросов
                        </Link>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <BsArrowLeftCircle className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" onClick={() => onSwitchQuestion(qustionNumber - 1)}/>
                            <button type="button" >Предыдущий вопрос</button>
                        </div>
                        <div className="btn-group me-2">
                            <BsArrowRightCircle className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" onClick={() => onSwitchQuestion(qustionNumber + 1)}/>
                            <button >Следующий вопрос</button>
                        </div>
                    </div>
                </div> */}
                <div className="sticky top-0 z-50 p-3 bg-white text-[12px] sm:text-[16px]  flex flex-col lg:flex-row maxWrap:justify-center lg:justify-between items-center maxWrap:flex-wrap maxWrap:gap-1 lg:gap-0 mb-3">
                    <div className=" maxWrap:w-full lg:w-auto">
                        <Link to="/liveReport" className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded maxWrap:w-full lg:w-auto text-center">К списку вопросов</Link>
                    </div>
                    <div className="flex justify-between items-center maxWrap:gap-0 lg:gap-4 maxWrap:w-full lg:w-auto">
                        <div className=''>
                            <button className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold maxWrap:py-1 maxWrap:px-2 lg:py-2 lg:px-4 rounded w-full md:w-auto" onClick={() => onSwitchQuestion(qustionNumber - 1)}>
                                <BsArrowLeftCircle />
                                <span className="ml-2">Предыдущий вопрос</span>
                            </button>
                        </div>
                        <div className=''>
                            <button className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold maxWrap:py-1 maxWrap:px-2 lg:py-2 lg:px-4 rounded w-full md:w-auto" onClick={() => onSwitchQuestion(qustionNumber + 1)}>
                                <span className="mr-2">Следующий вопрос</span>
                                <BsArrowRightCircle />
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <h5>{reportData[qustionNumber].question} ({reportData[qustionNumber].topic})</h5>
                </div>
                {pageType == 'photo' ? <>
                    <PictureGallery photosArray={answersInfo} />
                </> :
                    <>  {pageType !== 'open' && <AnswersSingAndMultipl counts={chartCounts} initialChartDatasets={ChartDatasSet} />}

                        <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                            <>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='text-center cursor-pointer hover:border-b hover:border-[#1a73e8]' onClick={()=>handleSort({field : 'code'})}>Код магазина</th>
                                            <th scope="col" className='text-center cursor-pointer hover:border-b hover:border-[#1a73e8] ' onClick={()=>handleSort({field : 'name'})}>Наименование магазина</th>
                                            {
                                                pageType === 'open' ?
                                                    <th scope="col">Ответ</th>
                                                    :
                                                    Object.keys(chartCounts).map((item, index) => <th key={index} className='text-center cursor-pointer hover:border-b hover:border-[#1a73e8] ' onClick={()=>handleSort({id:'answers', field : item})}>{item}</th>)
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(answersInfo.rows).map((item, index) =>
                                                <tr key={index}>
                                                    <td className='text-center'>{answersInfo.rows[index].code}</td>
                                                    <td className='text-center'>{answersInfo.rows[index].name}</td>
                                                    <AnswerRow index={index} />
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </>
                        </div>
                    </>
                }
            </div>

        )
})

export default LiveReportMoreDetails

