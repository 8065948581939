import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import ProgressPage from "../pages/ProgressPage";
import LoginPage from "../pages/LoginPage";
import AdminPanelPage from "../pages/AdminPanel";
import LifeReportPage from "../pages/LiveReportPage";
import LiveReportMoreDetails from "../pages/LiveReportMoreDetails";
import Page_404 from "../pages/Page_404";
import ManagerAssessment from "../pages/ManagerAssessment";
import RatingStorePage from '../pages/RatingStorePage';
import RaitingPage from "../pages/RaitingPage";
import MyTasksPage from "../pages/MyTasksPage";

const RoutesConfig = ({ isAuth }) => {
    const { role } = useAuth();

    const storeRoutes = (
        <Routes>
            <Route path="/" element={<MyTasksPage />} />
            <Route path="/ratingStore/:test/:id" element={<RatingStorePage />} />
            <Route path="/rating" element={<RaitingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Page_404 />} />
        </Routes>
    );
    const superUserRoutes = (
        <Routes>
        <Route path="/" element={<ProgressPage />} />
        <Route path="/liveReport" element={<LifeReportPage />} />
        <Route path="/liveReport/MoreDetails" element={<LiveReportMoreDetails />} />
        <Route path="/ratingStore/:test/:id" element={<RatingStorePage />} />
        <Route path="/managerRating" element={<ManagerAssessment />} />
        <Route path="/ratingStore/:test/:id" element={<RatingStorePage />} />
        <Route path="/rating" element={<RaitingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Page_404 />} />
    </Routes>
    )

    const adminRoutes = (
        <Routes>
            <Route path="/" element={<ProgressPage />} />
            <Route path="/liveReport" element={<LifeReportPage />} />
            <Route path="/liveReport/MoreDetails" element={<LiveReportMoreDetails />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/managerRating" element={<ManagerAssessment />} />
            <Route path="/ratingStore/:test/:id" element={<RatingStorePage />} />
            <Route path="/adminPanel" element={role === 'admin' ? <AdminPanelPage /> : <Navigate to="/" replace />} />
            <Route path="*" element={<Page_404 />} />
        </Routes>
    );

    return !isAuth ? (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
        </Routes>
    ) : (
        role === 'store' ? storeRoutes :  
        role === 'superuser' ? superUserRoutes 
        : adminRoutes
    );
};

export default RoutesConfig;