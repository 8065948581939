import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

const initialState = {
  value: 0,
  published: 0,
  reportData: [],
  qustionNumber: 0,
  modalVisible: false,
  modalPhotos: [],
  currentModalPhoto: 0,
  pageType: '',
  task_id: 0,
  searchValue: '',
  currentTopic: '',
  displayInSelection: [],
  chooseSortType: [{ lable: 'По коду', value: 'storeCode' }, { lable: 'По дате', value: 'finishTime' }],
  currentTypeSort: 'finishTime',
  orderBy: 'desp',
  selectedDay: '',
  paginationOfTopics: [],
  criteria: [],
  photoTags: [],
  changingСriteria: [],
  changeCommentStore: {},
  changeDrawing: [],
  changeTracking: { tracking: false },
  calledModalIcon: '',
  modalsDataManagerRating: {},
  totalCompleted: 0,
  correctAnswers:0,
  incorrectAnswers:0
};

const usersSlice = createSlice({
  name: 'liveReportSlice',
  initialState,
  reducers: {

    setModalVisible(state, action) { // expand modal window
      state.modalVisible = !state.modalVisible
      if (state.modalVisible == false) {
        state.changingСriteria = [];
        state.changeCommentStore = {};
        state.changeDrawing = [];
        state.changeTracking = { tracking: false }
      }
      if (action.payload) { //  We pass the parameter only to open the modal when closing it, call the reducer without action
        if (action.payload.call === 'managerRating') {
          state.calledModalIcon = 'managerRating'
          state.modalsDataManagerRating = {
            task_id: action.payload.task_id,
            store_id: action.payload.store_id,
            question: action.payload.question,
            city: action.payload.city,
            code: action.payload.code,
            name: action.payload.name,
            photo: action.payload.photo,
            criteriaInfo: action.payload.criteriaInfo,
            photoTags: action.payload.photoTags,
            comment: action.payload.comment,
            finishTime: action.payload.finishTime,
            topic: action.payload.topic,
          }
        } else {
          state.calledModalIcon = 'liveReport'
          state.qustionNumber = action.payload.numQuests;
          state.currentModalPhoto = action.payload.photoNumber;
          state.task_id = state.reportData[action.payload.numQuests].task_id;
          state.criteria = state.reportData[action.payload.numQuests].photos[action.payload.photoNumber].criteriaInfo;
          state.photoTags = state.reportData[action.payload.numQuests].photos[action.payload.photoNumber].storePhotoTags;
          state.modalPhotos = state.reportData[action.payload.numQuests].photos;
        }
      }
    },
    reportDatas(state, action) { // basic data in liveReport
      state.reportData = action.payload;
    },
    setQustionNumber(state, action) { // Click to display more details of the question
      state.qustionNumber = action.payload;
      state.pageType = state.reportData[action.payload].type;
      state.task_id = state.reportData[action.payload].task_id;
    },
    onNextQuestionCheck: (state, action) => {// checking for the ability to switch to the next question in more detail
      const { reportData } = state
      const nextQuestionIndex = action.payload;
      if (reportData[action.payload]) {
        return {
          ...state,
          qustionNumber: nextQuestionIndex,
          pageType: reportData[nextQuestionIndex].type,
          task_id: reportData[nextQuestionIndex].task_id,
        };
      }
    },
    setSearchValue(state, action) {// search value
      state.searchValue = action.payload
    },
    setCurrentTopic(state, action) {// selecting the displayed topic
      state.currentTopic = action.payload
    },
    setCurrentTypeSort(state, action) {  // choice of sorting by Dota or code
      state.currentTypeSort = state.chooseSortType[action.payload].value
    },
    setSelectedDay(state, action) { //choosing a filter for several days
      state.displayInSelection = action.payload;
      const selectedValuesString = action.payload.map(option => option.value).join(',');
      state.selectedDay = selectedValuesString || '';
    },
    setOrderBy(state, action) { //selection from most to least
      state.orderBy = action.payload;
    },
    setPaginationOfTopics(state, action) { //displaying a live report depending on topics
      state.paginationOfTopics = action.payload
      if (state.currentTopic === '')
        state.currentTopic = action.payload[0];
    },
    setMagazineCount (state, action) { //Заполнение данных об ответах магазина
      state.storeCount = action.payload.storeCounts;
      state.correctAnswers = action.payload.correct;
      state.incorrectAnswers = action.payload.incorrect;
    },
    // resetСhangesModalData(state){
    //   state.changingСriteria = [];
    //   state.changeCommentStore = {};
    //   state.changeDrawing = [];
    // },
    setSwitchingCriteria(state, action) { //switching criteria
      state.currentModalPhoto = action.payload;
      state.criteria = state.modalPhotos[action.payload].criteriaInfo;
      state.photoTags = state.modalPhotos[action.payload].storePhotoTags;
      document.getElementById(`commentStore`) && (document.getElementById(`commentStore`).value = state.modalPhotos[action.payload].commentStore || null);
      // resetСhangesModalData()

      state.changingСriteria = [];
      state.changeCommentStore = {};
      state.changeDrawing = [];
      state.changeTracking = { tracking: false }
    },

    setChangingСriteria(state, action) {
      if (!action.payload) return
      const { id, rating, store_id } = action.payload;

      if (state.calledModalIcon === 'managerRating') {
        state.modalsDataManagerRating.criteriaInfo[state.modalsDataManagerRating.criteriaInfo.findIndex(item => item.id === id)].rating = rating;
      } else {
        state.criteria[state.criteria.findIndex(item => item.id === id)].rating = rating;
      }
      if (!state.changingСriteria || !state.changingСriteria.dataByCriteria) {
        state.changingСriteria = {
          infoByCriteria: { store_id: store_id, topic: state.calledModalIcon === 'managerRating' ? state.modalsDataManagerRating.topic : state.currentTopic },
          dataByCriteria: [{ id, rating }]
        };
        return;
      }

      const existingIndex = state.changingСriteria.dataByCriteria.findIndex(item => item.id === id);

      if (existingIndex !== -1) {
        state.changingСriteria.dataByCriteria[existingIndex].rating = rating;
      } else {
        state.changingСriteria.dataByCriteria.push({ id, rating });
      }
    },

    setSaveChangeModalDatas(state) { // changing the original array to display
      if (state.calledModalIcon === 'liveReport') {
        state.reportData[state.qustionNumber].photos[state.currentModalPhoto].criteriaInfo = state.criteria;
        state.modalPhotos[state.currentModalPhoto].criteriaInfo = state.criteria;
        if (state.changeCommentStore.text_value !== undefined) {
          state.reportData[state.qustionNumber].photos[state.currentModalPhoto].commentStore = state.changeCommentStore.text_value;
          state.modalPhotos[state.currentModalPhoto].commentStore = state.changeCommentStore.text_value;
        }
        if (state.changeTracking.tracking !== false) {
          state.reportData[state.qustionNumber].photos[state.currentModalPhoto].storePhotoTags = state.changeDrawing;
          state.modalPhotos[state.currentModalPhoto].storePhotoTags = state.changeDrawing;
        }
      }
      state.changingСriteria = [];
      state.changeCommentStore = {};
      state.changeDrawing = [];
    },
    //TO DO : swich the props!
    setChangeCommentStore(state, action) {
      if (Object.keys(state.changeCommentStore).length === 0) {
        state.changeCommentStore = { task_id: action.payload.task_id, store_id: action.payload.store_id, text_value: action.payload.text_value.trim() }
      } else {
        state.changeCommentStore.text_value = action.payload.text_value.trim()
      }
    },
    setChangesDrawing(state, action) {
      state.changeTracking = { tracking: true, task_id: action.payload.task_id, store_id: action.payload.store_id }
      state.changeDrawing = action.payload.changeDrawing
    },
    setPublished(state, action) {
      state.published = action.payload
    },
    setCurrentModalPhoto(state, action) {
      state.currentModalPhoto = action.payload
    },
  },
});

export const {
    reportDatas,
    setQustionNumber,
    setModalVisible,
    onNextQuestionCheck,
    setSearchValue,
    setCurrentTopic,
    setCurrentTypeSort,
    setSelectedDay,
    setOrderBy,
    setPaginationOfTopics,
  setSwitchingCriteria,
  setChangingСriteria,
  setSaveChangeModalDatas,
  setChangeCommentStore,
  setChangesDrawing,
  resetСhangesModalData,
  setPublished,
  setCurrentModalPhoto,
    setMagazineCount
} = usersSlice.actions;
export default usersSlice.reducer;
