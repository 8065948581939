import "./App.css";
import { useEffect, useState } from "react";
import { useAuth } from "./hooks/useAuth";
import axios from "axios";
import { InfoBox } from "./cms_core";
import { useAppDispatch, useAppSelector } from './store/hooks/redux';
import MainModals from "./modal/MainModals";
import { setDataWhenLoading } from "./store/slices/mainHeaderSlice";
import RoutesConfig from "./routes/RoutesConfig";
import MainHeader from "./components/Header/MainHeader";

const App = () => {
    const isAuth = !!localStorage.user;
    const { user_id } = useAuth();
    const { role } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useAppDispatch();
    const modalVisible = useAppSelector(state => state.liveReportSlice.modalVisible);

    useEffect(() => {
        (async function () {
            if (isAuth) {
                let testsData;
                let loyaltyProgramsData;
                loyaltyProgramsData = await axios.post(`/index.php`, {
                    module: "loyaltyprogram",
                    action: "list",
                    idUser: user_id
                })
                    .then(response => response.data)
                    .catch(function (error) {
                        InfoBox("Error in loyaltyprogram: " + error);
                        console.error(error);
                    });
                testsData = await axios.post(`/index.php`, {
                    module: "test",
                    action: "list",
                    loyalty_programs_id: loyaltyProgramsData.program[loyaltyProgramsData.id].id,
                    role: role
                })
                    .then(response => {
                        return response.data
                    })
                    .catch(function (error) {
                        InfoBox("Error in test: " + error);
                        console.error(error);
                    });
                if (testsData && loyaltyProgramsData.program) {
                    dispatch(setDataWhenLoading({ testsData: testsData, loyaltyProgramsData: loyaltyProgramsData }));
                    setLoading(false);
                } else {
                    setError(true);
                }
            } else {
                setLoading(false)
            }
        })();
    }, [isAuth]);

    return loading ? (
        <>
            <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </>
    ) : error ? (
        <div className="container col-xl-10 col-xxl-8 px-4 py-5">
            <img
                alt="amg"
                src={require('./assets/icon.png')}
                height={75}
                width={75}
                className={'d-block mx-auto mb-4'}
            />
            <div className="row align-items-center g-lg-5 py-5 text-center">
                <div className="col-md-10 mx-auto col-lg-5">
                    <p>Произошла ошибка при получении данных. Пожалуйста, проверьте Ваше соединение с интернетом и повторите попытку.</p>
                </div>
            </div>
        </div>
    ) : (
        <>
            {isAuth && <MainHeader />}
            <RoutesConfig  isAuth = {isAuth}/>
            {modalVisible && <MainModals />}
        </>
    )
}

export default App