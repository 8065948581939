import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { callback, color } from "chart.js/helpers";
ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    ChartDataLabels
)

export function BarChart({ datasets = [
    {
        label: 'Да',
        data: [3],
        backgroundColor: 'orange',
        borderColor: 'black',
        borderRadius: 5,
        borderWidth: 0
    },
    {
        label: 'Нет',
        data: [1],
        backgroundColor: 'yellow',
        borderColor: 'black',
        borderRadius: 5,
        borderWidth: 0
    },
] }) {
    const [numbers, setNumbers] = useState([]);
    const [customDatasets, setCustomDatasets] = useState([])

    useEffect(() => {
        setNumbers(() => numberLabels())
        setCustomDatasets(() => customizeBars(datasets))
    }, [])

    const numberLabels = () => {
        const result = datasets.map((item, index) =>  item.label)
        return result;
    }

    // customizes style of all bars
    const customizeBars = (datasets) => {
        let labels = []
        let datas = []
        let backgroundColors = []

        for (let i = 0; i < datasets.length; i++) {
            labels.push(datasets[i].label)
            datas.push(datasets[i].data)
            backgroundColors.push(datasets[i].backgroundColor)
        }

        const newDatasets = [{
            label: labels,
            data: datas,
            backgroundColor: backgroundColors,
            borderWidth: 1,
            minBarThickness: 10,
            maxBarThickness: 200,
            borderRadius: 15,
            borderSkipped: false
        }]
    
        return newDatasets;
    }

    ///////////////////////// CONFIGS ////////////////////////////////////

    const data = {
        datasets: customDatasets,
        labels: numbers
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 30
            }
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                border: {
                    width: 0
                },
                grid: {
                    display: false,
                    borderWidth: 0,
                },
                // just stub for correct view
                ticks: {
                    display: true,
                    color: 'transparent',
                    font: {
                        size: 2
                    },
                },
            }
        },
        plugins: {
            legend: {
                display: false
            },
            // customizes up labels 
            datalabels: {
                color: 'rgb(59 130 246)',
                anchor: 'end',
                align: 'top',
                offset: 4,
                padding: 4,
                clamp: true,
                font: {
                    size: 16
                },
                formatter: (value, context) => {
                    return value.toLocaleString();
                  }
            },
            tooltip: {
                enabled: false
              }
        }
    };

    return (
        <div className="!max-h-[300px] !min-h-[300px]">
            <Bar className="!w-full" data={data} options={options} redraw={true} height="300"></Bar>
        </div>
    )
}
